import { ADDRESS, PHONE, EMAIL, WEBHOOK_URL, NOTIFICATION_NAME_EMAIL, ADMIN_NOTE, PREFERENCE, GDS } from './shared';

const PLUGIN_META = {
  messageType: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Message Type',
    label: 'MESSAGE TYPE',
    rule: 'optional',
    objRef: 'pluginMeta.quest',
    objRefOutput: 'pluginMeta.quest',
  }
}

export const NEW_LAB = {
  name: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Lab Name',
    label: 'LAB',
    rule: 'required',
  },
  ...ADDRESS,
  associatedUsersId: {
    value: [],
    error: undefined,
    label: 'ADD USER',
    placeHolder: 'Enter or select User',
    rule: 'required',
  },
  userId: {
    value: '',
    error: undefined,
    label: 'LAB DIRECTOR',
    placeHolder: 'Enter Full Name',
    rule: 'required',
    objRef: 'poc.user.id',
    objRefOutput: 'poc',
  },
  phone: {
    ...PHONE,
    label: 'PHONE NUMBER',
    objRef: 'poc',
    objRefOutput: 'poc',
  },
  email: {
    ...EMAIL,
    label: 'EMAIL',
    objRef: 'poc',
    objRefOutput: 'poc',
  },
  ...GDS,
  ...WEBHOOK_URL,
  ...NOTIFICATION_NAME_EMAIL,
  ...ADMIN_NOTE,
  ...PREFERENCE,
  ...PLUGIN_META
};

export const NEW_TEST = {
  name: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Test Name',
    label: 'Test Name',
    rule: 'required',
  },
  description: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Test Description',
    label: 'Test Description',
    rule: 'required',
  },
  testcode: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Test Code',
    label: 'Test Code',
    rule: 'required',
  },
  testPrice: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Test Code Price',
    label: 'Test Code Price',
    rule: 'required',
  },
  results: {
    value: [],
    error: undefined,
    placeHolder: 'Select Results',
    label: 'Link Results',
    rule: 'optional',
  },
  reflex: {
    values: [{ label: 'Reflex', value: true }],
    error: undefined,
    placeHolder: '',
    label: '',
    rule: 'required',
  },
};

export const LINK_TESTS = {
  tests: {
    value: [],
    error: undefined,
    placeHolder: 'Select Tests',
    label: 'Link Tests',
    rule: 'optional',
  }
};
