import React from 'react';
import { Container } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Image, Header, Divider, Form } from 'semantic-ui-react';
import ReactCodeInput from 'react-code-input';
import OrdrsLogo from '../../../assets/images/ordrs-logo.svg';
import { FormInput } from '../../../components/formElements';
import { ListErrors } from '../../../components/shared';

class ConfirmPassword extends React.Component {
  constructor(props) {
    super(props);
    const { forgotRequest } = this.props.authStore;
    this.props.authStore.reset('CONFIRM_PASS_FRM');
    if (!forgotRequest.email) {
      this.props.history.push('/auth/forgot-password');
    }
  }

  login = async () => {
    const response = await this.props.authStore.forgotPasswordSubmit();
    if (response === true) {
      this.props.history.push('/auth/login');
    }
  }
  render() {
    const { CONFIRM_PASS_FRM, formChange, status } = this.props.authStore;
    return (
      <section className="signup-wrap inverted">
        <Container>
          <div className="signup-section">
            <Image src={OrdrsLogo} centered size="tiny" />
            <Divider hidden />
            <Header as="h2" inverted>Create new password</Header>
            <Header as="h3" inverted className="mt-10">Enter and confirm your new password.</Header>
            <br />
            <Form inverted onSubmit={this.login} className="text-left">
              <Form.Field className="otp-wrap">
                <label>ENTER VERIFICATION CODE HERE:</label>
                <ReactCodeInput
                  name="code"
                  fields={6}
                  type="number"
                  className="otp-field"
                  fielddata={CONFIRM_PASS_FRM.fields.code}
                  onChange={(e) => formChange(e, { name: 'code', value: e }, 'CONFIRM_PASS_FRM')}
                />
              </Form.Field>
              {['password', 'retypePassword'].map(field => (
                <FormInput
                  key={field}
                  name={field}
                  type={field !== 'code' ? 'password' : 'text'}
                  fielddata={CONFIRM_PASS_FRM.fields[field]}
                  changed={(e, result) => formChange(e, result, 'CONFIRM_PASS_FRM')}
                />
              ))}
              <Divider hidden />
              {typeof status !== 'boolean' && <ListErrors errors={[status]} />}
              <Form.Button
                loading={status === true}
                primary
                fluid
                size="large"
                content="Set a new Password"
              />
            </Form>
          </div>
        </Container>
      </section>
    );
  }
}

export default inject('authStore')(observer(ConfirmPassword));
