import { toJS } from 'mobx';
import Validator from 'validatorjs';
import moment from 'moment';
import { pickBy, mapValues, cloneDeep, replace, map, mapKeys, isArray, toArray, reduce, includes, set } from 'lodash';
import Utility from './Utility';

class FormHandler {
  emptyDataSet = { data: [] };

  constructor() {
    Validator.register('optional', () => true);

    Validator.register('order_age', (value) => {
      const pMeta = { ...document.o_p_meta };
      const yrsOld = moment().diff(value, 'years');
      return !pMeta.id || (pMeta && pMeta.minAge <= yrsOld && pMeta.maxAge >= yrsOld);
    }, 'Value not matching with selected Panel criteria.');

    Validator.register('order_gender', (value) => {
      const pMeta = { ...document.o_p_meta };
      return !pMeta.id || (pMeta && (pMeta.gender === 'ANY' || pMeta.gender === value) && value !== '');
    }, 'Value not matching with selected Panel criteria.');

    Validator.register('alphaNumeric', (value) => {
      const regex = /^[0-9a-zA-Z ,-_]*$/;
      return regex.test(value);
    }, 'Invalid value, please verify and enter again.');

    Validator.register('numeric', (value) => {
      const regex = /^[0-9]*$/;
      return regex.test(value);
    }, 'Invalid value, please verify and enter again.');

    Validator.register('float', (value) => {
      const regex = /^[0-9.]*$/;
      return regex.test(value);
    }, 'Invalid value, please verify and enter again.');

    Validator.register('url', (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
      return regex.test(value);
    }, 'Invalid value, please verify and enter again.');

    Validator.register('isValidMarkerRef', (value) => {
      return /^[A-Za-z0-9_-]*$/.test(value);
    }, 'Only aplhabet, numbers, _ and - allowed.');

    Validator.register('isValidJson', (value) => {
      return this.isValidJsonObj(value);
    }, 'Invalid json.');
    
  }

  isValidJsonObj = (param) => {
    try {
      const obj = JSON.parse(param);
      return true;
    } catch (e) {
      return false;
    }
  }

  prepareForm =
    (fields, isDirty = false, isFieldValid = true, isValid = false, metaData) => ({
      fields: { ...fields },
      refMetadata: metaData ? { ...metaData } : JSON.parse(JSON.stringify({ ...fields })),
      meta: {
        isValid,
        error: '',
        isDirty,
        isFieldValid,
      },
      response: {},
    });

  pullValues = (e, data) => ({
    name: typeof data === 'undefined' ? e.target.name : data.name,
    value: typeof data === 'undefined' ? e.target.value : data.value,
  });

  pullValuesForPassword = e => ({ name: 'password', value: e.password });

  pullValuesForCangePassword = e => ({ name: 'newPasswd', value: e.newPasswd });

  onChange = (form, element, type, isDirty = true, checked = undefined) => {
    const currentForm = form;
    let customErrMsg = {};
    if (element && element.name) {
      if (type === 'checkbox' || (Array.isArray(toJS(currentForm.fields[element.name].value)) && type !== 'dropdown')) {
        const index = currentForm.fields[element.name].value.indexOf(element.value);
        if (index === -1) {
          currentForm.fields[element.name].value.push(element.value);
        } else {
          currentForm.fields[element.name].value.splice(index, 1);
        }
      } else if (checked) {
        currentForm.fields[element.name].value = checked.value;
      } else {
        currentForm.fields[element.name].value = element.value;
      }
      customErrMsg = (currentForm.fields[element.name] &&
        currentForm.fields[element.name].customErrors) ?
        currentForm.fields[element.name].customErrors : {};
    }
    const fieldsToValidate = pickBy(currentForm.fields, f => !Array.isArray(f)); // hack to ignore array (form inside form)
    const validation = new Validator(
      mapValues(fieldsToValidate, f => f.value),
      mapValues(fieldsToValidate, f => f.rule),
      customErrMsg,
    );
    currentForm.meta.isValid = validation.passes();
    if (element && element.name) {
      currentForm.fields[element.name].error = validation.errors.first(element.name);
      if (currentForm.fields[element.name].error === false) {
        currentForm.meta.isFieldValid = true;
      } else {
        currentForm.meta.isFieldValid = false;
      }
      currentForm.fields[element.name].error = validation.errors.first(element.name) ?
        replace(
          validation.errors.first(element.name),
          element.name,
          currentForm.fields[element.name].label,
        ) : undefined;
    }
    currentForm.meta.isDirty = isDirty;
    return currentForm;
  }

  getRefFromObjRef = (objRef, data) => {
    let tempRef = false;
    objRef.split('.').map((k) => {
      tempRef = !tempRef ? data[k] : tempRef[k];
      return tempRef;
    });
    return tempRef;
  }

  validateForm = (form, isMultiForm = false, showErrors = false, isBusinessPlanRequired = true) => {
    const currentForm = form;
    let validation;
    if (!isMultiForm) {
      validation = new Validator(
        mapValues(currentForm.fields, f => f.value),
        mapValues(currentForm.fields, f => f.rule),
        {
          required: 'required',
          required_if: 'required',
        },
      );
    } else {
      const formData = this.ExtractFormValues(toJS(currentForm.fields));
      let formRules = this.ExtractFormRules(toJS(currentForm.fields));
      if (isBusinessPlanRequired) {
        formRules = { ...formRules, businessPlan: 'required' };
      } else {
        formRules = { ...formRules };
      }
      validation = new Validator(
        formData,
        formRules,
        {
          required: 'required',
        },
      );
    }
    currentForm.meta.isValid = validation.passes();
    if (validation.errorCount && showErrors) {
      const { errors } = validation.errors;
      map(errors, (error, key) => {
        const [err] = error;
        if (includes(key, '.')) {
          const field = key.split('.');
          if (field[0] === 'businessPlan') {
            currentForm.fields[field[0]].error = err;
          } else {
            currentForm.fields[field[0]][field[1]][field[2]].error = err;
          }
        } else {
          currentForm.fields[key].error = err;
        }
      });
    }
    return currentForm;
  }

  onArrayFieldChange =
    (form, element, formName = null, formIndex = -1, type, checked = undefined) => {
      const currentForm = form;
      let currentFormRelative;
      let fieldName = element.name;
      let customErrMsg = {};
      if (formIndex > -1 && formName) {
        currentFormRelative = cloneDeep(currentForm.fields[formName][formIndex]);
        fieldName = `${formName}.${formIndex}.${element.name}`;
      } else if (formName) {
        currentFormRelative = currentForm.fields[formName];
        fieldName = `${formName}.${element.name}`;
      } else {
        currentFormRelative = currentForm.fields;
      }
      if (element.name) {
        if (type === 'checkbox' || (Array.isArray(toJS(currentFormRelative[element.name].value)) && type !== 'dropdown')) {
          currentFormRelative[element.name].value = !currentFormRelative[element.name].value;
        } else if (checked) {
          currentFormRelative[element.name].value = checked.value;
        } else {
          currentFormRelative[element.name].value = element.value;
        }
        customErrMsg = (currentFormRelative[element.name] &&
          currentFormRelative[element.name].customErrors) ?
          currentFormRelative[element.name].customErrors : {};
      }
      if (formIndex > -1 && formName) {
        currentForm.fields[formName][formIndex] = currentFormRelative;
      }
      const formData = this.ExtractFormValues(toJS(currentForm.fields));
      const formRules = this.ExtractFormRules(toJS(currentForm.fields), formIndex);
      const validation = new Validator(
        formData,
        formRules,
        customErrMsg,
      );
      currentForm.meta.isValid = validation.passes();
      if (element && element.name) {
        currentFormRelative[element.name].error = validation.errors.first(fieldName);
      }
      return currentForm;
    }

  ExtractValues = fields => mapValues(fields, f => f.value);

  ExtractFormValues = fields => mapValues(fields, f =>
    (isArray(f) ? toArray(mapValues(f, d => mapValues(d, s => s.value))) :
      f.value));

  // ExtractFormRules = (fields, index = 0) => reduce(mapValues(fields, (f, key) =>
  //   (isArray(f) ? mapKeys(mapValues(f[index], k => k.rule), (s, v) => `${key}.*.${v}`) :
  //     mapKeys(v => `${key}.${v.rule}`))), (a, b) => Object.assign(a, b));

  ExtractFormRules = fields => reduce(mapValues(fields, (f, key) =>
    (isArray(f) ? mapKeys(mapValues(f[0], k => k.rule), (s, v) => `${key}.*.${v}`)
      : { [key]: f.rule })), (a, b) => Object.assign(a, b));

  // resetFormData = (form, targetedFields) => {
  //   const currentForm = form;
  //   const fieldsToReset = (targetedFields && targetedFields.length && targetedFields)
  //   || Object.keys(currentForm.fields);
  //   fieldsToReset.map((field) => {
  //     if (Array.isArray(currentForm.fields[field]) || Array.isArray(toJS(currentForm.fields[field].value))) {
  //       currentForm.fields[field].value = [];
  //       if (currentForm.fields[field].objType === 'FileObjectType') {
  //         currentForm.fields[field].fileId = [];
  //         currentForm.fields[field].fileData = [];
  //         currentForm.fields[field].preSignedUrl = [];
  //       }
  //     } else {
  //       currentForm.fields[field].value = '';
  //       if (currentForm.fields[field].objType === 'FileObjectType') {
  //         currentForm.fields[field].fileId = '';
  //         currentForm.fields[field].fileData = '';
  //         currentForm.fields[field].preSignedUrl = '';
  //       }
  //     }
  //     currentForm.fields[field].error = undefined;
  //     currentForm.response = {};
  //     return true;
  //   });
  //   currentForm.meta.isValid = false;
  //   currentForm.meta.error = '';
  //   return currentForm;
  // }

  resetFormData = (form, targetedFields) => {
    const currentForm = form;
    const fieldsToReset = (targetedFields && targetedFields.length && targetedFields)
      || Object.keys(currentForm.fields);
    fieldsToReset.map((field) => {
      if (Array.isArray(toJS(currentForm.fields[field].value))) {
        currentForm.fields[field].value = [];
        if (currentForm.fields[field].objType === 'fileUpload') {
          currentForm.fields[field].id = [];
          currentForm.fields[field].fileName = [];
          currentForm.fields[field].fileSize = [];
          currentForm.fields[field].fileType = [];
          currentForm.fields[field].base64String = [];
        }
      } else if (Array.isArray(toJS(currentForm.fields[field]))) {
        const arr = toJS(currentForm.fields[field]);
        arr.map((item, index) => {
          const fieldKeys = Object.keys(currentForm.fields[field][index]);
          fieldKeys.map((f) => {
            if (Array.isArray(toJS(currentForm.fields[field][index][f].value))) {
              currentForm.fields[field][index][f].value = currentForm.fields[field][index][f].defaultValue || [];
              if (currentForm.fields[field][index][f].objType === 'fileUpload') {
                currentForm.fields[field][index][f].id = [];
                currentForm.fields[field][index][f].fileName = [];
                currentForm.fields[field][index][f].fileSize = [];
                currentForm.fields[field][index][f].fileType = [];
                currentForm.fields[field][index][f].base64String = [];
              }
            } else {
              currentForm.fields[field][index][f].value = currentForm.fields[field][index][f].defaultValue || '';
              if (currentForm.fields[field][index][f].objType === 'fileUpload') {
                currentForm.fields[field][index][f].id = '';
                currentForm.fields[field][index][f].fileName = '';
                currentForm.fields[field][index][f].fileSize = '';
                currentForm.fields[field][index][f].fileType = '';
                currentForm.fields[field][index][f].base64String = '';
              }
            }
            return true;
          });
          return true;
        });
        currentForm.fields[field].splice(1);
      } else {
        currentForm.fields[field].value = currentForm.fields[field].defaultValue || '';
        if (currentForm.fields[field].objType === 'fileUpload') {
          currentForm.fields[field].id = '';
          currentForm.fields[field].fileName = '';
          currentForm.fields[field].fileSize = '';
          currentForm.fields[field].fileType = '';
          currentForm.fields[field].base64String = '';
        }
      }
      currentForm.fields[field].error = undefined;
      currentForm.response = {};
      return true;
    });
    currentForm.meta.isValid = false;
    currentForm.meta.error = '';
    return currentForm;
  }

  setIsDirty = (form, status) => {
    const currentForm = form;
    currentForm.meta.isDirty = status;
  }
  setFormError = (form, key, error) => {
    const currentForm = form;
    currentForm.fields[key].error = error;
    if (error) {
      currentForm.meta.isValid = false;
      currentForm.meta.isFieldValid = false;
    }
  }
  resetFormToEmpty = metaData => this.prepareForm(metaData || this.emptyDataSet);

  evaluateObjectRef = (objRef, inputData, key, value, action, model) => {
    let tempRef = inputData;
    const actionMap = model === 'model' ? action : 'set';
    if (objRef === true && model) {
      set(tempRef, `${key}.${actionMap}`, value);
    } else {
      set(tempRef, model ? `${objRef}.${actionMap}.${key}` : `${objRef}.${key}`, value);
    }
    return tempRef;
  }

  evalFileUploadObj = (fileData) => {
    const fileObj = toJS(fileData);
    let fileObjOutput;
    if (Array.isArray(fileObj.value)) {
      fileObjOutput = map(fileObj.value, (file, index) => ({
        id: fileObj.id[index] || Utility.generateUUID(),
        url: file,
        fileName: fileObj.fileName[index],
      }));
    } else {
      fileObjOutput = fileObj.value ? {
        id: fileObj.id || Utility.generateUUID(),
        url: fileObj.value,
        fileName: fileObj.fileName,
      } : null;
    }
    return fileObj.toJson ? JSON.stringify(fileObjOutput) : fileObjOutput;
  }

  evaluateFormData = (fields, action) => {
    let inputData = {};
    map(fields, (ele, key) => {
      try {
        if (!fields[key].skipField) {
          const records = toJS(fields[key]);
          let reference = false;
          if (fields[key] && Array.isArray(records)) {
            if (fields[key] && fields[key].length > 0) {
              const arrObj = [];
              records.forEach((field) => {
                let arrayFieldsKey = {};
                let arrayFields = {};
                map(field, (eleV, keyRef1) => {
                  if (!eleV.skipField) {
                    let reference2 = false;
                    let reference2Val = field[keyRef1].value;
                    if (eleV.objRefOutput && !reference) {
                      reference = eleV.objRefOutput;
                    }
                    if (eleV.objRefOutput2 && !reference2) {
                      reference2 = eleV.objRefOutput2;
                    }
                    else if (field[keyRef1].objType && field[keyRef1].objType === 'fileUpload') {
                      reference2Val = this.evalFileUploadObj(field[keyRef1]);
                    }
                    if (reference2) {
                      arrayFields =
                        this.evaluateObjectRef(reference2, arrayFields, [field[keyRef1].keyAlias || keyRef1], reference2Val);
                    } else {
                      arrayFields = { ...arrayFields, [field[keyRef1].keyAlias || keyRef1]: reference2Val };
                    }
                    arrayFieldsKey = { ...arrayFieldsKey, ...arrayFields };
                  }
                });
                arrObj.push(arrayFieldsKey);
                if (reference) {
                  inputData = this.evaluateObjectRef(reference, inputData, [key], arrObj, action);
                } else {
                  inputData = { ...inputData, [key]: arrObj };
                }
              });
            }
          } else {
            if (fields[key].objRefOutput && !reference) {
              reference = fields[key].objRefOutput;
            }
            let objValue = (fields[key].value === '' || (fields[key].value === undefined && fields[key].refSelector === undefined)) && fields[key].defaultValue ? fields[key].defaultValue :
              fields[key].value;
            if (fields[key] && fields[key].formatAs) {
              objValue = fields[key].formatAs(objValue);
            }
            if (fields[key].objType && fields[key].objType === 'DATE') {
              objValue = this.evalDateObj(fields[key].value);
            } else if (fields[key].objType && fields[key].objType === 'fileUpload') {
              objValue = this.evalFileUploadObj(fields[key]);
            }
            if (reference) {
              inputData = this.evaluateObjectRef(reference, inputData, [fields[key].keyAlias || key], objValue, action, fields[key].modelMap);
            } else if (fields[key].refSelector !== undefined
              && fields[fields[key].refSelector].value !== undefined) {
              let val = '';
              if (fields[fields[key].refSelector].value) {
                if (fields[key].value !== '' && fields[key].value !== undefined && fields[key].value !== null) {
                  val = objValue;
                } else {
                  val = fields[key].defaultValue;
                }
              }
              inputData = { ...inputData, [fields[key].keyAlias || key]: val };
            } else {
              inputData = { ...inputData, [fields[key].keyAlias || key]: objValue };
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
    return inputData;
  }

  resetMoreFieldsObj = (formFields) => {
    const fields = formFields;
    Object.keys(fields).forEach((key) => {
      if (fields[key] && Array.isArray(toJS(fields[key]))) {
        fields[key] = this.resetMoreFieldsObj(fields[key]);
      } else if (fields[key].objType === 'fileUpload') {
        fields[key] = {
          ...fields[key],
          ...{
            value: '', id: '', fileSize: '', fileType: '', fileName: '', base64String: '', showLoader: false, confirmModal: false, error: undefined,
          },
        };
      } else {
        fields[key].value = fields[key].hasOwnProperty('default') ? fields[key].default : '';
      }
    });
    return fields;
  }

  // addMoreRecordToSubSection = (form, key, count = 1, defaultBlank = false) => {
  //   const currentForm = form;
  //   currentForm.fields[key] = currentForm.fields[key] && currentForm.fields[key][0] ?
  //     this.addMoreFields(currentForm.fields[key], count, currentForm.refMetadata[key]) : (
  //       defaultBlank ? currentForm.refMetadata[key] : []
  //     );
  //   currentForm.meta = { ...currentForm.meta, isValid: false };
  //   return currentForm;
  // }

  addMoreRecordToSubSection = (form, key, count = 1, defaultBlank = false) => {
    const currentForm = form;
    currentForm.fields[key] = currentForm.fields[key] && currentForm.fields[key][0]
      ? this.addMoreFields(currentForm.fields[key], count) : (
        defaultBlank ? currentForm.refMetadata[key] : []
      );
    currentForm.meta = { ...currentForm.meta, isValid: false };
    return currentForm;
  }

  // addMoreFields = (fields, count = 1, refMetadata) => {
  //   const arrayData = [...toJS(fields)];
  //   for (let i = count; i > 0; i -= 1) {
  //     arrayData.push({ ...toJS(fields)[0] });
  //   }
  //   return arrayData;
  // }

  addMoreFields = (fields, count = 1) => {
    const arrayData = [...toJS(fields)];
    for (let i = count; i > 0; i -= 1) {
      arrayData.push(this.resetMoreFieldsObj(JSON.parse(JSON.stringify({ ...fields[0] }))));
    }
    return arrayData;
  }

  setDataForLevel = (refFields, data, keepAtLeastOne) => {
    const fields = { ...refFields };
    Object.keys(fields).map((key) => {
      try {
        if (fields[key] && Array.isArray(toJS(fields[key]))) {
          const tempRef = toJS(fields[key])[0].objRef ?
            this.getRefFromObjRef(fields[key][0].objRef, data) : false;
          if ((data && data[key] && data[key].length > 0) ||
            (tempRef && tempRef[key] && tempRef[key].length > 0)) {
            const addRec = ((data[key] && data[key].length) ||
              (tempRef[key] && tempRef[key].length)) - toJS(fields[key]).length;
            fields[key] = this.addMoreFields(fields[key], addRec);
            (data[key] || tempRef[key]).forEach((record, index) => {
              const info = JSON.parse(JSON.stringify({
                ...this.setDataForLevel(
                  fields[key][index],
                  (data[key] && data[key][index]) || (tempRef[key] && tempRef[key][index]),
                  keepAtLeastOne,
                )
              }));
              fields[key][index] = info;
            });
          } else if (!keepAtLeastOne) {
            fields[key] = [];
          }
        } else if (fields[key].objRef) {
          const tempRef = this.getRefFromObjRef(fields[key].objRef, data);
          if (fields[key].objType === 'fileUpload') {
            const refKey = fields[key].keyAlias || key;
            if (tempRef[refKey] && Array.isArray(toJS(tempRef[refKey]))
              && fields[key] && Array.isArray(toJS(fields[key].value))) {
              if (tempRef[refKey].length > 0) {
                tempRef[refKey].map((item) => {
                  fields[key].value.push(item.url);
                  fields[key].id.push(item.id);
                  fields[key].fileName.push(item.fileName);
                  return false;
                });
              } else {
                fields[key].value = [];
                fields[key].id = [];
                fields[key].fileName = [];
              }
            } else {
              const refKey = fields[key].keyAlias || key;
              fields[key].value = Array.isArray(toJS(tempRef[refKey]))
                ? tempRef[refKey][0].url : tempRef[refKey].url;
              fields[key].id = Array.isArray(toJS(tempRef[refKey]))
                ? tempRef[refKey][0].id : tempRef[refKey].id;
              fields[key].fileName = Array.isArray(toJS(tempRef[refKey]))
                ? tempRef[refKey][0].fileName : tempRef[refKey].fileName;
            }
          } else if (fields[key].objType === 'DATE') {
            fields[key].value = tempRef[key] ? moment(tempRef[key]).format('MM/DD/YYYY') : '';
          } else {
            const refKey = fields[key].keyAlias || key;
            const fieldRef = key.split('_');
            fields[key].value = fields[key].find ?
              tempRef.find(o => o[fields[key].find].toLowerCase() === fieldRef[0])[fieldRef[1]] :
              (typeof tempRef === 'string' ? tempRef : tempRef[refKey]);
          }
        } else if (key === 'value') {
          fields[key] = data && typeof data === 'string' ? data : data[key];
        } else if (fields[key].objType === 'fileUpload') {
          const refKey = fields[key].keyAlias || key;
          if (data[refKey] && Array.isArray(toJS(data[refKey]))
            && fields[key] && Array.isArray(toJS(fields[key].value))) {
            if (data[refKey].length > 0) {
              data[refKey].map((item) => {
                fields[key].value.push(item.url);
                fields[key].id.push(item.id);
                fields[key].fileName.push(item.fileName);
                return false;
              });
            } else {
              fields[key].value = [];
              fields[key].id = [];
              fields[key].fileName = [];
            }
          } else {
            const refKey = fields[key].keyAlias || key;
            fields[key].value = data && typeof data === 'string' ? data : Array.isArray(toJS(data[refKey])) ? data[refKey][0].url : data[refKey].url;
            fields[key].fileName = data && typeof data === 'string' ? data : Array.isArray(toJS(data[refKey])) ? data[refKey][0].fileName : data[refKey].fileName;
            fields[key].id = data && typeof data === 'string' ? data : Array.isArray(toJS(data[refKey])) ? data[refKey][0].id : data[refKey].id;
          }
        } else if (fields[key].objType === 'DATE') {
          fields[key].value = data && typeof data === 'string' ? moment(data).format('MM/DD/YYYY') : data[key] ? moment(data[key]).format('MM/DD/YYYY') : '';
        } else {
          fields[key].value = data && typeof data === 'object' ? (data[key] !== null && data[key] !== '' && data[key] !== undefined) ? data[key] : fields[key].value : data;
        }
        if (fields[key].refSelector) {
          fields[key].refSelectorValue = fields[key].value !== '';
          if (fields[key].value !== undefined) {
            fields[fields[key].refSelector].value = (fields[key].value !== null && fields[key].value !== '');
          }
        }
      } catch (e) {
        // do nothing
      }
      return fields;
    });
    return fields;
  }

  setFormData = (form, dataSrc, ref, keepAtLeastOne = true) => {
    let currentForm = form;
    const data = ref ? this.getRefFromObjRef(ref, dataSrc) : dataSrc;
    currentForm = this.resetFormToEmpty(currentForm.refMetadata);
    currentForm.fields = this.setDataForLevel(currentForm.fields, data, keepAtLeastOne);
    return currentForm;
  };
}
export default new FormHandler();
