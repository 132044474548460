import React from 'react';
import { observer } from 'mobx-react';
import { intersection, has, snakeCase, lowerCase, startCase } from 'lodash';
import { Form } from 'semantic-ui-react';
import { FormInput, MaskedInput, AutoComplete, FormDropDown } from '../index';
import { US_STATES } from '../../../constants/common';

const addressFormat = (place) => {
  let result = {};
  const formatAddress = address => {
    const formattedAddress = { ...address };
    if (address.state) {
      formattedAddress.state = snakeCase(address.state).toUpperCase();
    }
    return formattedAddress;
  }
  const addressMap = {
    residentalStreet: ['street_number', 'route', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3'],
    city: ['locality'],
    state: ['administrative_area_level_1'],
    zipCode: ['postal_code'],
  };
  if (place.address_components) {
    Object.keys(addressMap).map(aK => place.address_components.map((c) => {
      if (intersection(addressMap[aK], c.types).length > 0) {
        const addressEle = {};
        addressEle[aK] = addressMap[aK].length > 2 && result[aK] ? `${result[aK]} ${c.long_name}` : c.long_name;
        result = has(result, aK) ? addressEle : { ...result, ...addressEle };
      }
      return formatAddress(result);
    }));
  }
  return formatAddress(result);
};


class Address extends React.Component {
  update = (place) => {
    const data = addressFormat(place);
    this.props.changed(false, { name: 'addressLine1', value: data.residentalStreet });
    ['city', 'state', 'zip'].forEach(ele => {
      this.props.changed(false, { name: ele, value: ele === 'zip' ? data.zipCode : data[ele] });
    })
  }
  render() {
    const { fielddata, changed, maskchanged } = this.props;
    return (
      <>
        <AutoComplete
          name="addressLine1"
          fielddata={fielddata.addressLine1}
          onplaceselected={(place) => this.update(place)}
          componentRestrictions={{ country: 'us' }}
          changed={changed}
        />
        <Form.Group widths={3}>
          <FormInput
            key="city"
            name="city"
            fielddata={fielddata.city}
            changed={changed}
          />
          <FormDropDown
            name="state"
            fluid
            fielddata={fielddata.state}
            options={US_STATES.map(s => ({ key: s, value: s.toUpperCase(), text: startCase(lowerCase(s)) }))}
            search
            selection
            onChange={changed}
          />
          <MaskedInput
            key="zip"
            name="zip"
            format="#####"
            fielddata={fielddata.zip}
            changed={maskchanged}
          />
        </Form.Group>
      </>
    );
  }
}

export default observer(Address);
