export const MARKER_READING_CONFIG = {
  MAX_LIMIT: 100
};

export const MARKER_RANGE_TYPE = {
  SMALLER: '<', SMALLER_EQ: '<=', EQUAL: '=', LARGER_EQ: '>=', LARGER: '>', EQUAL_STRING: 'EQUAL', CATCH_ALL: 'CATCH-ALL'
};

export const MARKER_RANGE_TYPE_REVERSE = {
  '<': 'SMALLER', '<=': 'SMALLER_EQ', '=': 'EQUAL', '>=': 'LARGER_EQ', '>' : 'LARGER', 'EQUAL': 'EQUAL_STRING', 'CATCH-ALL': 'CATCH_ALL'
};

export const RANGE_TYPE = {
  NORMAL: 'NORMAL', ABNORMAL: 'ABNORMAL', CRITICAL: 'CRITICAL'
};

export const UNITS = [
  '%', 'µg/dL', 'μmol/L', 'g/L', 'IU/L', 'mg/dL', 'mg/L', 'mmol/L', 'ng/dL', 'ng/mL', 'u/L', 'other'
];

export const SPECIMENLIST = [
  { key: 'BLOOD', value: 'BLOOD' },
  { key: 'DRIED_BLOOD_SPOT', value: 'DRIED BLOOD SPOT' },
  { key: 'SALIVA', value: 'SALIVA' },
  { key: 'SERUM', value: 'SERUM' },
  { key: 'SPUTUM', value: 'SPUTUM' },
  { key: 'SWAB', value: 'SWAB' },
  { key: 'URINE', value: 'URINE' },
  { key: 'STOOL', value: 'STOOL' },
];

export const MARKER = {
  name: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Name',
    label: 'Name',
    rule: 'required',
  },
  specimen: {
    value: '',
    error: undefined,
    placeHolder: 'Choose Specimen',
    values: SPECIMENLIST.map(g => ({ key: g.key, value: g.key, text: g.value })),
    label: 'Specimen',
    rule: 'required',
  },
  units: {
    value: '',
    error: undefined,
    placeHolder: 'Choose Unit',
    values: UNITS.map(g => ({ key: g, value: g, text: g })),
    label: 'Units',
    rule: 'required',
  },
  unitsFreeTxt: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Unit',
    label: 'Units',
    rule: 'required',
  },
  markerRef: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Refrerence',
    label: 'Marker Ref',
    rule: 'optional|isValidMarkerRef',
  },
  markerDesc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Description',
    label: 'Marker Desc',
    rule: 'optional',
  },
  resultCode: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Result Code',
    label: 'Result Code',
    rule: 'optional',
  },
  reflex: {
    values: [{ label: 'Reflex', value: true }],
    error: undefined,
    placeHolder: '',
    label: '',
    rule: 'optional',
  },
  ranges: [{
    type: {
      value: '',
      error: undefined,
      values: Object.keys(MARKER_RANGE_TYPE).map(r => ({ key: r, value: r, text: MARKER_RANGE_TYPE[r] })),
      placeHolder: 'select',
      label: '',
      rule: 'optional',
    },
    rangeValue: {
      value: '',
      error: undefined,
      placeHolder: '5',
      label: 'value',
      rule: 'optional',
    },
    name: {
      value: '',
      error: undefined,
      placeHolder: 'Enter Label',
      label: 'LABEL',
      rule: 'required',
      customErrors: {
        required: 'Label is required',
      },
    },
    isReportable: {
      value: false,
      error: undefined,
      placeHolder: 'Enter Label',
      label: 'LABEL',
      rule: 'optional',
    },
    isCritical: {
      value: false,
      error: undefined,
      placeHolder: 'Enter Label',
      label: 'LABEL',
      rule: 'optional',
    },
    isThreshold: {
      value: false,
      error: undefined,
      placeHolder: 'Enter Label',
      label: 'LABEL',
      rule: 'optional',
    },
    rangeType: {
      value: '',
      error: undefined,
      values: Object.keys(RANGE_TYPE).map(r => ({ key: r, value: r, text: RANGE_TYPE[r], className: `range-${RANGE_TYPE[r].toLowerCase()}` })),
      placeHolder: 'select',
      label: '',
      rule: 'optional',
    },
  }]
};

// export const RESULT = {
//   ...MARKER,
//   ...{ name: { ...MARKER.name, placeHolder: 'Enter Name' } },
//   ...{ markerDesc: { ...MARKER.markerDesc, placeHolder: 'Enter Description', label: 'Result Desc' } },
//   ...{ markerRef: { ...MARKER.markerRef, placeHolder: 'Enter Refrerence', label: 'Result Ref' } },
// };
