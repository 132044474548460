import gql from 'graphql-tag';

export const markers = `
markers {
  name
  markerRef
  markerDesc
  resultCode
  specimen
  units
  ranges {
    name
    type
    value
    rangeValue:value
    isThreshold
    isCritical
    isReportable
    rangeType
  }
}
`

export const results = `
markers {
  id
  name
  reflex
  markerRef
  markerDesc
  resultCode
  specimen
  units
  ranges {
    id
    name
    type
    value
    rangeValue:value
    isThreshold
    isCritical
    isReportable
    rangeType
  }
}
`;

export const tests = `
tests {
  id
  name
  testCode
  testPrice
  testDesc
  reflex
  results {
    id
    name
  }
}
`;

export const address = `
  address{
    addressLine1
    city
    state
    zip
  }
`;

export const brands = `
  brands{
    id
    gdsAnalyticsURL
  }
`;

export const labs = `
  labs{
    id
    name
    gdsAnalyticsURL
  }
`;

export const commonPropsLabIndividual = `
  id
  name
  webhookUrl
  gdsAnalyticsURL
  poc {
    id
    user {
      id
      firstName
      lastName
    }
    email
    phone
  }
  notification {
    notificationName
    notificationEmail
    webhookTemplate
    preference
  }
  adminNote
`;

export const commonPropsLab = `
  id
  name
  webhookUrl
  poc {
    id
    user {
      id
      firstName
      lastName
    }
    email
    phone
  }
  notification {
    notificationName
    notificationEmail
    webhookTemplate
    preference
  }
`;

export const commonCreateUpdateLab = {
  a1: `
    $name: String!,
    $address: AddressInputType,
    $poc: PocInputType!,
    $webhookUrl: String,
    $associatedUsersId: [ID!],
    $notification: NotificationInputType,
    $adminNote: String,
    $pluginMeta: String,
    $gdsAnalyticsURL: String,
  `,
  a2: `
    name: $name,
    address: $address,
    poc: $poc,
    webhookUrl: $webhookUrl,
    associatedUsersId: $associatedUsersId,
    notification: $notification,
    adminNote: $adminNote,
    pluginMeta: $pluginMeta,
    gdsAnalyticsURL: $gdsAnalyticsURL,
  `,
  p1: `
    $name: String!,
    $address: AddressInputType,
    $poc: PocInputType!,
    $webhookUrl: String,
    $associatedUsersId: [ID!],
    $notification: NotificationInputType,
    $adminNote: String,
  `,
  p2: `
    name: $name,
    address: $address,
    poc: $poc,
    webhookUrl: $webhookUrl,
    associatedUsersId: $associatedUsersId,
    notification: $notification,
    adminNote: $adminNote,
  `
};

export const commonPropsBrandIndividual = `
  id
  name
  webhookUrl
  poc {
    id
    user {
      id
      firstName
      lastName
    }
    email
    phone
  }
  notification {
    notificationName
    notificationEmail
    webhookTemplate
    preference
  }
  meta
  portal
  referralUrl
  previewPassword
  adminNote
  authType
`;

export const commonPropsBrand = `
  id
  name
  webhookUrl
  poc {
    id
    user {
      id
      firstName
      lastName
    }
    email
    phone
  }
  notification {
    notificationName
    notificationEmail
    webhookTemplate
    preference
  }
  authType
`;

export const commonCreateUpdateBrand = {
  p1: `
    $name: String!,
    $address: AddressInputType,
    $poc: PocInputType!,
    $webhookUrl: String,
    $associatedUsersId: [ID!]!,
    $providerUsersId: [ID!],
    $notification: NotificationInputType,
    $adminNote: String,
    $meta: String,
    $portal: String,
    $referralUrl: String,
    $previewPassword: String,
    $authType: AuthTypeEnum,
  `,
  p2: `
    name: $name,
    address: $address,
    poc: $poc,
    webhookUrl: $webhookUrl,
    associatedUsersId: $associatedUsersId
    providerUsersId: $providerUsersId
    notification: $notification
    adminNote: $adminNote
    meta: $meta
    portal: $portal
    referralUrl: $referralUrl
    previewPassword: $previewPassword
    authType: $authType
  `,
  a1: `
    $name: String!,
    $address: AddressInputType,
    $poc: PocInputType!,
    $webhookUrl: String,
    $associatedUsersId: [ID!]!,
    $providerUsersId: [ID!],
    $notification: NotificationInputType,
    $adminNote: String,
    $meta: String,
    $portal: String,
    $referralUrl: String,
    $previewPassword: String,
    $dropShipment: String,
    $dropShipmentMeta: String,
    $pluginMeta: String,
    $sftpResultProcess: BrandSFTPProcess,
    $sftpUsername: String,
    $sftpPPK: String,
    $sftpBrandPrefix: String,
    $sftpInputPath: String,
    $sftpOutputPath: String,
    $sftpUrl: String,
    $sftpPort: String
    $gdsAnalyticsURL: String
    $authType: AuthTypeEnum
    $billingType: String
    $monthlyFee: Float
    $perOrderFee: Float
    $includedOrders: Int
    $portalFee: Float
    $batchFee: Float
    $batchSize: Int
    $batchReBilledAt: String
    $batchLastBilledDate: String
    $currentBatchSize: Int
    $fulfillmentPerOrderFee: Float
    $fulfillmentMonhtlyFee: Float
  `,
  a2: `
    name: $name,
    address: $address,
    poc: $poc,
    webhookUrl: $webhookUrl,
    associatedUsersId: $associatedUsersId,
    providerUsersId: $providerUsersId
    notification: $notification,
    adminNote: $adminNote,
    meta: $meta,
    portal: $portal,
    referralUrl: $referralUrl,
    previewPassword: $previewPassword,
    dropShipment: $dropShipment,
    dropShipmentMeta: $dropShipmentMeta,
    pluginMeta: $pluginMeta,
    sftpResultProcess: $sftpResultProcess,
    sftpUsername: $sftpUsername,
    sftpPPK: $sftpPPK,
    sftpOutputPath: $sftpOutputPath,
    sftpInputPath: $sftpInputPath,
    sftpBrandPrefix: $sftpBrandPrefix,
    sftpUrl: $sftpUrl,
    sftpPort: $sftpPort
    gdsAnalyticsURL: $gdsAnalyticsURL
    authType: $authType
    billingType: $billingType
    monthlyFee: $monthlyFee
    perOrderFee: $perOrderFee
    includedOrders: $includedOrders
    portalFee: $portalFee
    batchFee: $batchFee
    batchSize: $batchSize
    batchReBilledAt: $batchReBilledAt
    batchLastBilledDate: $batchLastBilledDate
    currentBatchSize: $currentBatchSize
    fulfillmentPerOrderFee: $fulfillmentPerOrderFee
    fulfillmentMonhtlyFee: $fulfillmentMonhtlyFee
  `
};

export const getUploadSignedUrl = gql`
  mutation getUploadSignedUrl($fileName: String!, $contentType: String!, $entity: EntityEnum, $entityId: ID) {
    getUploadSignedUrl(fileName: $fileName, contentType: $contentType, entity: $entity, entityId: $entityId) {
      id
      signedUrl
    }
  }
`;

export const getUploadImage = gql`
  mutation getUploadSignedUrl($fileName: String!, $contentType: String!, $entity: EntityEnum, $entityId: ID) {
    getUploadSignedUrl(fileName: $fileName, contentType: $contentType, entity: $entity, entityId: $entityId) {
      id
      signedUrl
      url
    }
  }
`;

export const getFileUploadUrlForPanelMeta = gql`
  mutation getFileUploadUrlForPanelMeta($fileName: String!, $contentType: String!, $panelId: ID) {
    getFileUploadUrlForPanelMeta(fileName: $fileName, contentType: $contentType, panelId: $panelId) {
      fileId
      uploadUrl
      url
    }
  }
`;

export const getFileUploadUrlForBrandMeta = gql`
  mutation getFileUploadUrlForBrandMeta($fileName: String!, $contentType: String!, $brandId: ID) {
    getFileUploadUrlForBrandMeta(fileName: $fileName, contentType: $contentType, brandId: $brandId) {
      fileId
      uploadUrl
      url
    }
  }
`;

export const getPanelDownloadLink = gql`
  query getPanelDownloadLink($id: ID!) {
    getPanelDownloadLink(id: $id) {
      id
      signedUrl
    }
  }
`;

export const getOrderDownloadLink = gql`
  query getOrderDownloadLink($id: ID!) {
    getOrderDownloadLink(id: $id) {
      id
      signedUrl
    }
  }
`;

export const getDownloadLink = gql`
  query getDownloadLink($orderId: String!,$fileId: ID!) {
    getDownloadLink(orderId: $orderId, fileId: $fileId) {
      fileId
      signedUrl
    }
  }
`;

export const getDownloadLinkByFileNameKey = gql`
  query _getDownloadLinkByFileNameKey($key: String!,$fileName: String!, $contentType: String) {
    getDownloadLinkByFileNameKey(key: $key, fileName: $fileName, contentType: $contentType) {
      signedUrl
    }
  }
`;

export const getLabOrderRequisitionPdfDownloadLink = gql`
  query getLabOrderRequisitionPdfDownloadLink($orderId: String!){
    getLabOrderRequisitionPdfDownloadLink(orderId: $orderId){
      downloadUrl
    }
  }
`;

export const downloadUrl = gql`
  query getDownloadSignedUrl($id: ID!) {
    getDownloadSignedUrl(id: $id) {
      id
      signedUrl
    }
  }
`;

export const removeFile = gql`
mutation removeFileFromOrder($id: ID!, $attachmentId: ID!) {
    removeFileFromOrder(id: $id, attachmentId: $attachmentId) {
      id
    }
  }
`;

export const removeFileFromPanel = gql`
mutation removeFileFromPanel($panelId: ID, $panelPrefix: String, $attachmentId: ID!) {
    removeFileFromPanel(panelId: $panelId, panelPrefix: $panelPrefix, attachmentId: $attachmentId) {
      id
    }
  }
`;

export const deleteAttachment = gql`
mutation deleteAttachment($id: ID!) {
  deleteAttachment(id: $id) {
      fileId
    }
  }
`;

export const getFileUploadUrlForOrder = gql`
mutation getFileUploadUrlForOrder($fileName: String!, $contentType: String!, $orderId: String!) {
  getFileUploadUrlForOrder(fileName: $fileName, contentType: $contentType, orderId: $orderId) {
      fileId
      uploadUrl
    }
  }
`;

export const getFileUploadUrlForPanel = gql`
mutation getFileUploadUrlForPanel($fileName: String!, $contentType: String!, $panelId: ID) {
  getFileUploadUrlForPanel(fileName: $fileName, contentType: $contentType, panelId: $panelId) {
      fileId
      uploadUrl
    }
  }
`;

