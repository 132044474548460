import { USER, ADDRESS, GENDER, PHONE, EMAIL, PASSWORD, LICENSE, USER_TYPE, USER_ROLE } from './shared';
import { US_STATES } from '../../constants/common';
import { lowerCase, startCase } from 'lodash';

export const NEW_USER = {
  ...USER,
  ...ADDRESS,
  gender: { ...GENDER },
  phone: { ...PHONE },
  email: { ...EMAIL },
  ...PASSWORD,
  role: { ...USER_ROLE },
  userType: { ...USER_TYPE },
  licenses: { ...LICENSE },
  name: {
    value: '',
    error: undefined,
    label: 'Name',
    placeHolder: 'Enter Name',
    rule: 'required_if:role,API',
  },
  whitelistIp: {
    value: '',
    error: undefined,
    label: 'Whitelisted IPs',
    placeHolder: 'Enter whitelisted IPs',
    rule: 'optional',
  },
  npi: {
    value: '',
    error: undefined,
    label: 'NPI',
    placeHolder: 'Enter NPI',
    rule: 'required_if:role,MD|min:10|max:10',
  },
  externalId: {
    value: '',
    error: undefined,
    label: 'EXTERNAL ID',
    placeHolder: 'Enter External ID',
    rule: 'optional',
  },
  mdType: {
    value: 'ORDRS',
    error: undefined,
    placeHolder: 'MD Type',
    values: ['ORDRS', 'PROVIDER'].map(g => ({ key: g, value: g, text: g })),
    label: 'MD Type',
    rule: 'optional',
  },
  new_license_state: {
    value: '',
    error: undefined,
    label: 'State',
    placeHolder: 'Enter State',
    values: US_STATES.map(s => (
      { key: s, value: s.toUpperCase(), text: startCase(lowerCase(s)) }
      )),
    rule: 'required'
  },
  approvalPrice: {
    value: '',
    error: undefined,
    label: 'APPROVAL PRICE',
    placeHolder: 'Enter Approval Price',
    rule: 'optional',
  },
  releasePrice: {
    value: '',
    error: undefined,
    label: 'RELEASE PRICE',
    placeHolder: 'Enter Release Price',
    rule: 'optional',
  },
  outreachPrice: {
    value: '',
    error: undefined,
    label: 'OUTREACH PRICE',
    placeHolder: 'Enter Outreach Price',
    rule: 'optional',
  },
  expeditedReleasePrice: {
    value: '',
    error: undefined,
    label: 'EXPEDITED RELEASE PRICE',
    placeHolder: 'Enter Expedited Release Price',
    rule: 'optional',
  },
  provisionalApprovalPrice: {
    value: '',
    error: undefined,
    label: 'PROVISIONAL APPROVAL PRICE',
    placeHolder: 'Enter Provisional Approval Price',
    rule: 'optional',
  },
  approvalPrice: {
    value: '',
    error: undefined,
    label: 'APPROVAL PRICE',
    placeHolder: 'Enter Approval Price',
    rule: 'optional',
  },
  releasePrice: {
    value: '',
    error: undefined,
    label: 'RELEASE PRICE',
    placeHolder: 'Enter Release Price',
    rule: 'optional',
  },
  outreachPrice: {
    value: '',
    error: undefined,
    label: 'OUTREACH PRICE',
    placeHolder: 'Enter Outreach Price',
    rule: 'optional',
  },
  expeditedReleasePrice: {
    value: '',
    error: undefined,
    label: 'EXPEDITED RELEASE PRICE',
    placeHolder: 'Enter Expedited Release Price',
    rule: 'optional',
  },
  provisionalApprovalPrice: {
    value: '',
    error: undefined,
    label: 'PROVISIONAL APPROVAL PRICE',
    placeHolder: 'Enter Provisional Approval Price',
    rule: 'optional',
  },
  approvalPrice: {
    value: '',
    error: undefined,
    label: 'APPROVAL PRICE',
    placeHolder: 'Enter Approval Price',
    rule: 'optional',
  },
  releasePrice: {
    value: '',
    error: undefined,
    label: 'RELEASE PRICE',
    placeHolder: 'Enter Release Price',
    rule: 'optional',
  },
  outreachPrice: {
    value: '',
    error: undefined,
    label: 'OUTREACH PRICE',
    placeHolder: 'Enter Outreach Price',
    rule: 'optional',
  },
  expeditedReleasePrice: {
    value: '',
    error: undefined,
    label: 'EXPEDITED RELEASE PRICE',
    placeHolder: 'Enter Expedited Release Price',
    rule: 'optional',
  },
  provisionalApprovalPrice: {
    value: '',
    error: undefined,
    label: 'PROVISIONAL APPROVAL PRICE',
    placeHolder: 'Enter Provisional Approval Price',
    rule: 'optional',
  },
};

export const NEW_LICENSE = {
  state: {
    value: '',
    error: undefined,
    label: 'State',
    placeHolder: 'Enter State',
    values: US_STATES.map(s => (
      { key: s, value: s.toUpperCase(), text: startCase(lowerCase(s)) }
      )),
    rule: 'required'
  },
  license: {
    value: '',
    error: undefined,
    placeHolder: 'Enter License',
    label: 'License',
    rule: 'required',
  }
};

