import { lowerCase, startCase } from 'lodash';
import { US_STATES } from '../../../constants/common';

export const GDS = {
  gdsAnalyticsURL: {
    value: '',
    error: undefined,
    placeHolder: 'Enter GDS URL',
    label: 'GDS URL',
    rule: 'optional',
  },
}
export const ADDRESS = {
  addressLine1: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Street Address',
    label: 'ADDRESS',
    rule: 'optional',
    objRef: 'address',
    objRefOutput: 'address',
  },
  city: {
    value: '',
    error: undefined,
    label: 'CITY',
    placeHolder: 'City',
    rule: 'optional',
    objRef: 'address',
    objRefOutput: 'address',
  },
  state: {
    value: '',
    error: undefined,
    label: 'STATE',
    placeHolder: 'State',
    rule: 'optional',
    objRef: 'address',
    objRefOutput: 'address',
  },
  zip: {
    value: '',
    error: undefined,
    label: 'ZIP',
    placeHolder: 'Zip',
    rule: 'optional',
    objRef: 'address',
    objRefOutput: 'address',
  }
};

export const PHONE = {
  value: '',
  error: undefined,
  placeHolder: '(713) 555-4321',
  label: 'PHONE',
  rule: 'optional',
};

export const WEBHOOK_URL = {
  webhookUrl: {
    value: '',
    error: undefined,
    label: 'WEBHOOK URL',
    rule: 'optional',
  },
};

export const EMAIL = {
  value: '',
  error: undefined,
  label: 'EMAIL',
  formatAs: (val) => val ? val.toLowerCase() : '',
  placeHolder: 'Enter e-mail address',
  rule: 'required_if:role,USER|required_if:role,ADMIN|required_if:role,MD|email',
};


export const NOTIFICATION_NAME_EMAIL = {
  notificationName: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Notification Name',
    label: 'NOTIFICATION NAME',
    rule: 'optional',
    objRef: 'notification',
    objRefOutput: 'notification'
  },
  notificationEmail: {
    ...EMAIL,
    label: 'NOTIFICATION EMAIL',
    placeHolder: 'Enter Notification Email',
    objRef: 'notification',
    objRefOutput: 'notification'
  },
  webhookTemplate: {
    value: '',
    error: undefined,
    defaultValue: 'LEGACY',
    placeHolder: 'Select Template',
    label: 'Webhook Template',
    values: [
      { key: 'LEGACY', value: 'LEGACY', text: 'LEGACY' },
      { key: 'SIMPLE', value: 'SIMPLE', text: 'SIMPLE' },
    ],
    rule: 'optional',
    objRef: 'notification',
    objRefOutput: 'notification'
  },
}

export const ADMIN_NOTE = {
  adminNote: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Admin Notes',
    label: 'ADMIN NOTE',
    rule: 'optional',
  },
}

export const PREFERENCE = {
  preference: [{
    action: {
      value: '',
      error: undefined,
      label: 'ACTION',
      rule: 'optional',
    },
    email: {
      value: false,
      error: undefined,
      label: 'EMAIL',
      rule: 'optional',
    },
    webhook: {
      value: false,
      error: undefined,
      label: 'WEBHOOK',
      rule: 'optional',
    }
  }]
}

export const GENDER = {
  value: 'ANY',
  error: undefined,
  placeHolder: 'Choose Gender',
  values: ['ANY', 'MALE', 'FEMALE'].map(g => ({ key: g, value: g, text: g })),
  label: 'GENDER',
  rule: 'optional',
};

export const GENDER_ORDER = {
  value: '',
  error: undefined,
  placeHolder: 'Choose Gender',
  values: ['MALE', 'FEMALE'].map(g => ({ key: g, value: g, text: g })),
  label: 'GENDER',
  rule: 'optional',
};

export const USER_TYPE = {
  value: 'LAB',
  error: undefined,
  label: 'TYPE',
  placeHolder: 'Select User Type',
  values: ['LAB', 'BRAND'].map(g => ({ key: g, value: g, text: g })),
  rule: 'optional',
};

export const USER_ROLE = {
  value: '',
  error: undefined,
  label: 'ROLE',
  placeHolder: 'Select User Role',
  values: ['ADMIN', 'MD', 'USER', 'API', 'PORTAL_USER'].map(g => ({ key: g, value: g, text: g })),
  rule: 'required',
};

export const PASSWORD = {
  password: {
    value: '',
    error: undefined,
    label: 'PASSWORD',
    placeHolder: 'Enter Password',
    rule: 'required_if:role,USER|required_if:role,ADMIN|required_if:role,MD|min:8|max:15',
  },
  retypePassword: {
    value: '',
    error: undefined,
    skipField: true,
    placeHolder: 'Retype your Password',
    label: 'RETYPE PASSWORD',
    rule: 'required_if:role,USER|required_if:role,ADMIN|required_if:role,MD|same:password', //|same:password
  }
};

export const LICENSE = {
  value: [],
  error: undefined,
  label: 'LICENSE',
  placeHolder: 'Select Licenses',
  values: US_STATES.map(s => ({ key: s, value: s.toUpperCase(), text: startCase(lowerCase(s)) })),
  rule: 'optional'
};

export const USER = {
  firstName: {
    value: '',
    error: undefined,
    placeHolder: 'Enter name',
    label: 'FIRST NAME',
    rule: 'required_if:role,USER|required_if:role,ADMIN|required_if:role,MD',
  },
  lastName: {
    value: '',
    error: undefined,
    placeHolder: 'Enter name',
    label: 'LAST NAME',
    rule: 'required_if:role,USER|required_if:role,ADMIN|required_if:role,MD',
  },
  dob: {
    value: '',
    error: undefined,
    placeHolder: 'MM/DD/YYYY',
    label: 'DATE OF BIRTH',
    rule: 'optional',
  }
};

export const NOTIFICATION_ACTION_LIST = {
  ADMIN: {
    PROVISIONAL_APPROVAL: { email: false, webhook: false },
    MD_APPROVED: { email: false, webhook: false },
    LAB_ACCEPTED: { email: false, webhook: false },
    LAB_DECLINED: { email: false, webhook: false },
    LAB_RELEASED: { email: false, webhook: false },
    MD_DECLINED: { email: false, webhook: false },
    MD_RELEASED: { email: false, webhook: false },
    EXPEDITED_RELEASE: { email: false, webhook: false },
    CANCELED: { email: false, webhook: false },
    OUTREACH_PENDING: { email: false, webhook: false },
    OUTREACH_SUCCESSFUL: { email: false, webhook: false },
    OUTREACH_UNSUCCESSFUL: { email: false, webhook: false },
    FLAGGED: { email: false, webhook: false },
    UNFLAGGED: { email: false, webhook: false },
  },
  MD: {
    PROVISIONAL_APPROVAL: { email: false, webhook: false },
    MD_APPROVED: { email: false, webhook: false },
    LAB_ACCEPTED: { email: false, webhook: false },
    LAB_DECLINED: { email: false, webhook: false },
    LAB_RELEASED: { email: false, webhook: false },
    MD_DECLINED: { email: false, webhook: false },
    MD_RELEASED: { email: false, webhook: false },
    EXPEDITED_RELEASE: { email: false, webhook: false },
    CANCELED: { email: false, webhook: false },
    OUTREACH_PENDING: { email: false, webhook: false },
    OUTREACH_SUCCESSFUL: { email: false, webhook: false },
    OUTREACH_UNSUCCESSFUL: { email: false, webhook: false },
    FLAGGED: { email: false, webhook: false },
    UNFLAGGED: { email: false, webhook: false },
  },
  BRAND: {
    PROVISIONAL_APPROVAL: { email: false, webhook: false },
    MD_APPROVED: { email: false, webhook: false },
    LAB_ACCEPTED: { email: false, webhook: false },
    LAB_DECLINED: { email: false, webhook: false },
    LAB_RELEASED: { email: false, webhook: false },
    MD_DECLINED: { email: false, webhook: false },
    MD_RELEASED: { email: false, webhook: false },
    EXPEDITED_RELEASE: { email: false, webhook: false },
    CANCELED: { email: false, webhook: false },
    OUTREACH_PENDING: { email: false, webhook: false },
    OUTREACH_SUCCESSFUL: { email: false, webhook: false },
    OUTREACH_UNSUCCESSFUL: { email: false, webhook: false },
    FLAGGED: { email: false, webhook: false },
    UNFLAGGED: { email: false, webhook: false },
    PARTIAL_RESULTS: { email: false, webhook: false },
  },
  LAB: {
    PROVISIONAL_APPROVAL: { email: false, webhook: false },
    MD_APPROVED: { email: false, webhook: false },
    LAB_ACCEPTED: { email: false, webhook: false },
    LAB_DECLINED: { email: false, webhook: false },
    LAB_RELEASED: { email: false, webhook: false },
    MD_DECLINED: { email: false, webhook: false },
    MD_RELEASED: { email: false, webhook: false },
    EXPEDITED_RELEASE: { email: false, webhook: false },
    CANCELED: { email: false, webhook: false },
    FLAGGED: { email: false, webhook: false },
    UNFLAGGED: { email: false, webhook: false },
    PARTIAL_RESULTS: { email: false, webhook: false },
  }
};