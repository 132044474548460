import gql from 'graphql-tag';
import { address, commonPropsLab, commonPropsLabIndividual, commonCreateUpdateLab, markers, results, tests } from './common';

export const allLabs = gql`
  query allLabs($first: Int, $skip: Int, $orderBy: LabOrderBy, $filter: LabFilter) {
    labs(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      ${commonPropsLab}
      ${markers}
      panelCount
    }
    labsMeta(filter: $filter) {
      count
    }
  }
`;

export const labs = gql`
  query labs($first: Int, $skip: Int, $orderBy: LabOrderBy, $filter: LabFilter) {
    labs(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      ${commonPropsLab}
      panelCount
    }
  }
`;

export const createLab =  (isAdmin = false) => {
  return gql`
  mutation createLab(${isAdmin ? commonCreateUpdateLab.a1: commonCreateUpdateLab.p1}) {
    createLab(${isAdmin ? commonCreateUpdateLab.a2: commonCreateUpdateLab.p2}) {
      id
    }
  }`;
}

// export const lab = gql`
//   query lab($id: ID!) {
//     lab(id: $id) {
//       ${commonPropsLabIndividual}
//       associatedUsers {
//         id
//         firstName
//         lastName
//       }
//       ${address}
//     }
//   }
// `;


export const updateGDSAnalyticsSetting = () => {
  return gql`
  mutation updateGDSAnalyticsSetting($email: String!, $showGDSAnalytics: Boolean!) {
    updateGDSAnalyticsSetting(email: $email, showGDSAnalytics: $showGDSAnalytics) {
			id
      showGDSAnalytics
		}
  }
`;
}

export const getGDSAnalyticsFlag = () => {
  return gql`
   query getGDSAnalyticsUserSetting($email: String!) {
    getGDSAnalyticsUserSetting(email: $email) {
      showGDSAnalytics
    }
  }
`;
}

export const lab =  (isAdmin = false) => {
  return gql`
  query lab($id: ID!) {
    lab(id: $id) {
      ${commonPropsLabIndividual}
      associatedUsers {
        id
        firstName
        lastName
      }
      ${address}
      ${isAdmin ? `pluginMeta` : ``}
      ${results}
      ${tests}
    }
  }`;
}


export const updateLab =  (isAdmin = false) => {
  return gql`
    mutation updateLab($id: ID!, ${isAdmin ? commonCreateUpdateLab.a1 : commonCreateUpdateLab.p1}) {
      updateLab(id: $id, ${isAdmin ? commonCreateUpdateLab.a2 : commonCreateUpdateLab.p2}) {
      id
    }
  }
`;
}

export const deleteLab = gql`
  mutation deleteLab($id: ID!) {
    deleteLab(id: $id) {
			id
		}
  }
`;

export const getLabConnectorPlugins = gql`
  query getLabConnectorPlugins {
    getLabConnectorPlugins
  }
`;

export const createLabTest = gql`
  mutation createLabTest($name: String!, $description: String, $testcode: String, $testPrice: Float, $labId: ID, $reflex: Boolean, $results: [ID!]) {
    createLabTest(name: $name, testDesc: $description, testCode: $testcode,testPrice: $testPrice, labId: $labId, reflex: $reflex, labResults: $results) {
			id
      name
      testDesc
      testCode
      testPrice
      results {
        id
        name
      }
      reflex
		}
  }
`;

export const bulkUploadLabTests = gql`
  mutation uploadBulkTests($labId: ID!, $testsData: [uploadTestData]!) {
    uploadBulkTests(labId: $labId, testsData: $testsData) {
			error
      success
      message
		}
  }
`;

export const updateLabTest = gql`
  mutation updateLabTest($labTestId: ID!, $name: String!, $description: String, $testcode: String, $testPrice: Float,$labId: ID, $reflex: Boolean, $results: [ID!]) {
    updateLabTest(id: $labTestId, name: $name, testDesc: $description, testCode: $testcode, testPrice: $testPrice, labId: $labId, reflex: $reflex, labResults: $results) {
			id
      name
      testDesc
      testCode
      testPrice
      results {
        id
        name
      }
      reflex
		}
  }
`;

export const downloadLabTests = gql`
  query downloadLabTests($ids: [ID]) {
    downloadLabTests(ids: $ids) {
			id
      name
      testDesc
      testCode
      testPrice
      results
      reflex
		}
  }
`;

export const deleteManyLabTests = gql`
  mutation deleteManyLabTests($ids: [ID]) {
    deleteManyLabTests(ids: $ids) {
			id
		}
  }
`;
