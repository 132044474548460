import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

const SortCol = props => (
  <Table.HeaderCell
    onClick={() => props.setOrder(props.field, props.listMeta.newDir, props.currentTab, props.filters)}
    textAlign={props.align ? props.align : 'right' }
    style={{ cursor: 'pointer' }}
  >
    {props.label}
    {props.listMeta && props.listMeta.by === props.field && <Icon name={`caret ${props.listMeta.dir}`} />}
  </Table.HeaderCell>
);

export default SortCol;
