import axios from 'axios';
import request from 'superagent';

export class Api {
  upload = (url, file) => (
    new Promise((resolve, reject) => {
      const options = {
        headers: { 'Content-Type': file.type }
      };
      axios.put(url, file, options).then((data, err) => {
        if (err) reject(err);
        resolve(data);
      });
    })
  )

  uploadOnS3 = (url, file, type = 'text/plain') => (
    new Promise((resolve, reject) => {
      request
        .put(`${url}`)
        .set('Content-Type', type) // File upload (Binary Mode)
        .set('x-amz-acl', 'bucket-owner-full-control')
        .send(file)
        .end((err, data) => {
          if (err) {
            reject(err);
          }
          resolve(data);
        });
    })
  )
}

export default new Api();
