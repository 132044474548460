import React from 'react';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { Checkbox } from 'semantic-ui-react';

const FormCheckbox = observer((props) => {
  const { value } = props.fielddata;
  return (
    <div className={`${props.containerclassname || ''} ${get(props, 'customLabel') ? 'field' : ''}`}>
      {get(props, 'customLabel') ? <label>{get(props, 'customLabel')}</label> : null}
      <Checkbox
        checked={value === true}
        value={true}
        {...props}
        label={false}
        onChange={props.changed}
      />
    </div>
  );
});

export default FormCheckbox;
