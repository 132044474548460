import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { get } from 'lodash';
import mime from 'mime-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Icon, Modal, Header, Button, Dimmer, Loader } from 'semantic-ui-react';
import { FieldError } from '../../shared';

class ImageCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: '',
      crop: {
        unit: '%',
        width: 30,
        height: props.freeSelection ? 30 : false,
        aspect: props.freeSelection ? false : (props.aspect || 16 / 9),
      },
      minWidth: 20,
      pixelCrop: '',
      imageType: '',
      open: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.crop.aspect && props.freeSelection) {
      return {
        crop: {
          unit: '%',
          width: 30,
          height: props.freeSelection ? 30 : false,
          aspect: props.freeSelection ? false : (props.aspect || 16 / 9),
        }
      };
    } else if (!state.crop.aspect && !props.freeSelection) {
      return {
        crop: {
          unit: '%',
          width: 30,
          height: props.freeSelection ? 30 : false,
          aspect: props.freeSelection ? false : (props.aspect || 16 / 9),
        }
      };
    }
    return null;
  }

  onChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      this.props.handelReset();
      const { files } = (e.dataTransfer) ? e.dataTransfer : e.target;
      const fileType = get(files, '[0].type');
      const fileExt = fileType === '' ? get(files, '[0].name').split('.')[1] : mime.extension(fileType);
      this.setState({ imageType: fileType });
      this.props.setData('fileName', files[0].name);
      this.props.setData('fileSize', files[0].size);
      this.props.setData('fileType', fileType);
      this.props.setData('isDirty', true);
      if (this.props.verifySize) {
        this.props.verifySize(files[0].size, this.props.name);
      }
      this.props.verifyExtension(fileExt, this.props.name);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src: reader.result, open: true })
      }
      );
      reader.readAsDataURL(files[0]);
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
      );
      this.setState({ croppedImageUrl });
      this.props.setData('base64String', croppedImageUrl);
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return canvas.toDataURL(this.state.imageType);
  }

  handleCloseModal = () => {
    this.setState({
      open: false,
      src: '',
      crop: {
        unit: '%',
        width: 30,
        height: this.props.freeSelection ? 30 : false,
        aspect: this.props.freeSelection ? false : (this.props.aspect || 16 / 9),
      },
      minWidth: 20,
    });
  };

  modalUpload = (name, field) => {
    this.props.modalUploadAction(name, field);
    this.handleCloseModal();
  };

  render() {
    const { field, cropInModal, disabled, size, hideLoader, isProfile, isReadonly } = this.props;
    const { crop, src, open } = this.state;
    return (
      <>
        {src && !field.error ? cropInModal
          ? (
            <Modal closeOnRootNodeClick={false} closeIcon size="large" open={open} onClose={this.handleCloseModal} centered={false} closeOnDimmerClick={false}>
              <Modal.Content>
                <Header as="h3">Crop image for {field.label}</Header>
                <ReactCrop
                  {...this.state}
                  src={src}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                  crop={crop}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button primary content="Upload" onClick={() => this.modalUpload(this.props.name, field)} />
              </Modal.Actions>
            </Modal>
          )
          : (
            <ReactCrop
              {...this.state}
              src={src}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crop={crop}
            />
          )
          : (
            <>
              <div className="file-uploader">
                {!hideLoader &&
                  <Dimmer active={field.showLoader}>
                    <Loader size={size} />
                  </Dimmer>}
                {isProfile ?
                  <div className="link-text">Update photo</div> :
                  <div className="file-uploader-inner">
                    <Icon name="upload" /> Choose a file&nbsp;<span>or drag it here</span>
                  </div>
                }
                <input disabled={disabled || isReadonly} type="file" onChange={this.onChange} accept=".jpg, .jpeg, .png" />
              </div>
              {field.error
                && <FieldError error={field.error} />
              }
            </>
          )
        }
      </>
    );
  }
}

export default observer(ImageCropper);