import React from 'react';
import { SketchPicker } from 'react-color';
import { observer } from 'mobx-react';
import {  Button } from 'semantic-ui-react';
import { get } from 'lodash';
import { FieldError } from '../../shared';
import FormInput from './FormInput';

class FormColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
  };

  componentDidMount() {
    this.setState({ background: this.props.fieldData.value || '#fff' });
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChangeComplete = (color, e) => {
    const { name } = this.props;
    this.setState({ background: color.hex });
    this.props.changed(e, { name, value: color.hex });
  };

  render() {
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    const styles = {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${this.state.background}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
    };
    const { name, fieldData, changed, onblur } = this.props;
    return (
      <div className="field position-relative">
        <FormInput
          name={name}
          key={name}
          type="text"
          format={get(fieldData, 'format')}
          fielddata={fieldData}
          onblur={onblur}
          changed={changed}
          label={get(this.props, 'label') || false}
          isColorPicker={true}
          {...this.props}
        />
        {fieldData.value && fieldData.value !== ''
          ? (
            <Button type="button" className="btn-color-picker" style={styles.swatch} onClick={this.handleClick}>
              <div style={styles.color} />
            </Button>
          )
          : (
            <span>
              <Button type="button" onClick={this.handleClick} className="btn-color-picker">
                <i aria-hidden="true" className="eyedropper icon m-0" />
              </Button>
            </span>
          )
        }
        {this.state.displayColorPicker ?
          <>
            <div style={ cover } onClick={ this.handleClose }/>
            <SketchPicker
              color={this.state.background}
              onChange={this.handleChangeComplete}
            />
          </> : null }
        {
        this.props.fielddata && this.props.fielddata.error
        && <FieldError error={this.props.fielddata.error} />
        }
      </div>
    )
  }
}

export default (observer(FormColorPicker));