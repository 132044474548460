import { makeObservable, computed, observable, action } from "mobx";
import { get } from "lodash";
import DataStore from "../shared/DataStore";
import {
  // MobxApollo,
  // FormHandler as FormHandle,
  Utility as Utils,
} from "../../../../utils";
// import { ClaimClient as claimClient, getData } from "../../../../api/gqlApi";
import { NEW_ORDER } from "../../../constants/order";
import {
  dropShipments,
  dropShipment,
  dropShipmentSync,
  dropShipmentCancel,
  dropShipmentDelete,
  dropShipmentSendShip,
  dropShipmentSyncShop,
  dropShipmentSyncShip
} from "../../queries/shipment";

export class ShipmentStore extends DataStore {
  constructor() {
    super(NEW_ORDER, "dropShipment", {
      all: dropShipments,
      getOne: dropShipment,
    });
    makeObservable(this, {
      loading: computed,
      count: computed,
      dataPages: computed,
      progress: observable.ref,
      confirmBulkCancel: observable.ref,
      currentPage: observable.ref,
      updateSelectedFulfillIds: action,
      cancelBulkConfirm: action,
      prevNextPaginate: action,
      selectedFulfillIds: observable.ref,
      selectedFulfillOrderIds: observable.ref,      
    });
  }

  progress = false;
  selectedFulfillIds = [];
  selectedFulfillOrderIds = [];
  listAction = false;
  confirmBulkCancel = false;
  currentPage = 0;


  initLoad(params) {
    super.initLoad(params);
  };

  resetServerState() {
    super.resetServerState();
    this.currentPage = 0;
  }

  prevNextPaginate(gotoPage, metaInfo) {
    if (this.currentPage == 0 && gotoPage === -1) return;

    if (this.currentPage === (this.pages.length - 1) && gotoPage === 1) {
      this.initLoad({ ...this.params.filters, metaInfo });
      this.currentPage = this.currentPage + 1;
      return;
    }

    this.currentPage = this.currentPage + gotoPage;
  }

  initSearch = (value, against, forcedFilters) => {
    let updatedFilters = { [against[0]]: value };
    this.initLoad({ filters: forcedFilters || updatedFilters });
  };

  updateSelectedFulfillIds = (value, orderId) => {
    const selected = [...this.selectedFulfillIds];
    const selectedOrders = [...this.selectedFulfillOrderIds];

    this.selectedFulfillIds = selected.includes(value)
      ? selected.filter((s) => s !== value)
      : [...selected, value];

    this.selectedFulfillOrderIds = selectedOrders.includes(orderId)
      ? selectedOrders.filter((s) => s !== orderId)
      : [...selectedOrders, orderId];

    this.listAction = this.selectedFulfillIds.length > 0;
  };

  cancelBulkConfirm = () => {
    this.confirmBulkCancel = false;
    this.selectedFulfillIds = [];
    this.selectedFulfillOrderIds = []
    this.listAction = false;
  }

  cancelBulkConfirmModel = () => {
    this.confirmBulkCancel = true;
  }

  cancelBulk  = async () => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: dropShipmentCancel,
        variables:  {ids: this.selectedFulfillIds},
      });

      this.progress = false;
      Utils.toast("Order(s) Cancelled Successfully", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
    this.cancelBulkConfirm();
    this.initLoad(this.params);
  }

  download = () => {
    const data = this.entityDetails;
    const params = {
      fields: [
        { label: "Name", value: (row) => {
          const shopOrderDetails = JSON.parse(row.shopOrderDetails);
          return shopOrderDetails && shopOrderDetails.customer ?
            `${shopOrderDetails.customer.first_name} ${shopOrderDetails.customer.last_name}` : '-';
        }},
        { label: "brandId", value: "brandId" },
        { label: "Phone", value: (row) => {
          const shopOrderDetails = JSON.parse(row.shopOrderDetails);
          return shopOrderDetails && shopOrderDetails.phone ? shopOrderDetails.phone : '-';
        }},
        { label: "Email Address", value: (row) => {
          const shopOrderDetails = JSON.parse(row.shopOrderDetails);
          return shopOrderDetails && shopOrderDetails.contact_email ? shopOrderDetails.contact_email : '-';
        }},
        { label: "Tracking Number", value: (row) => {
          const shipDetails = JSON.parse(row.shipDetails);
          return shipDetails ? shipDetails.Orders.Order.Shipments.Shipment.trackingnumber._text : '-';
        }},
        { label: "3PL/PO NUMBER", value: (row) => {
          const shipDetails = JSON.parse(row.shipDetails);
          return shipDetails ? shipDetails.Orders.Order.orderNum._text : '-';
        }},
        // { label: "createdAt", value: (row) => `${moment(data.createdAt).format('MM/DD/YY h:mm A')}` },
      ],
      fileName: `fulfillment_details`,
      data: [data],
    };
    Utils.downloadCSV(params);
  };

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get dataPages() {
    return super.dataPages();
  }

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

  syncShipment = async (id) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: dropShipmentSync,
        variables: { id },
      });

      this.progress = false;
      Utils.toast("Order Synced Successfully", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  cancelShipment = async (id) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: dropShipmentCancel,
        variables:  {ids: [id]},
      });

      this.progress = false;
      Utils.toast("Order Cancelled Successfully", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  deleteShipment = async (id) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: dropShipmentDelete,
        variables: { id },
      });

      this.progress = false;
      Utils.toast("Order Deleted Successfully", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  sendShipment = async (id) => {
    this.progress = true;
    try {
      const response = await this.client.mutate({
        mutation: dropShipmentSendShip,
        variables: { id },
      });

      const dropShipmentSendShipResponse = JSON.parse(response.data.dropShipmentSendShip);

      get(dropShipmentSendShipResponse, 'errorFlag', false) ?
      Utils.toast(dropShipmentSendShipResponse.message, "error")
      : Utils.toast(dropShipmentSendShipResponse.message || "Order Send Successfully", "success");
      this.progress = false;
    } catch (e) {
      this.progress = false;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  dropShipmentSyncShop = async (plugin) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: dropShipmentSyncShop,
        variables: { plugin },
      });

      this.progress = false;
      Utils.toast("Shop Synced Successfully", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  dropShipmentSyncShip = async (plugin) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: dropShipmentSyncShip,
        variables: { plugin },
      });

      this.progress = false;
      Utils.toast("Ship Synced Successfully", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }
}

export default new ShipmentStore();
