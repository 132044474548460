import { makeAutoObservable } from 'mobx';
import { PRIVATE_NAV } from '../../../../constants/NavigationMeta';

export class NavStore {
  constructor() {
    makeAutoObservable(this);
  }

  time = null;
  redirectURL = undefined;

  get myRoutes() {
    return PRIVATE_NAV;
  }

  get sidebarItems() {
    return this.myRoutes.filter(r => r.noNav !== true);
  }
}

export default new NavStore();
