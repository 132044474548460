import React from 'react';
import { Table } from 'semantic-ui-react';

const showMsg = (msg, checked) => checked === -1 ? 'Loading...' : (msg || 'No record!');

const NoRecord = ({ cols, msg, checked, isMobile }) => (
  isMobile ? (
    <div className="listing-table-grid no-records">
      {showMsg(msg, checked)}
    </div>
  ) : (<Table.Row>
    <Table.Cell colSpan={cols} textAlign="center" className="no-records">{showMsg(msg, checked)}</Table.Cell>
  </Table.Row>)
);

export default NoRecord;

// -1: loading, 0: no data, 1: has data
export const checkEmpty = (data, loading) => loading === true ? -1 : (
  Array.isArray(data) && data.length === 0 ? 0 : 1
);
