import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { startCase, get } from 'lodash';

const processData = (by, data) => {
  let result = '';
  try {
    switch (by) {
      case 'address': result = data ? (
        <>
          {get(data, 'addressLine1')}<br />
          {get(data, 'city')}
          {get(data, 'state') ? <>, {startCase(get(data, 'state').toLowerCase())}&nbsp;</> : ''}
          {get(data, 'zip')}
        </>
      ) : 'N/A'; break;
      case 'license': result = data ? (
        <div className="three-column-list">
          {data.map(l => <div>{`${startCase(l.name.toLowerCase())} (${l.approveCount}-${l.reviewCount})`}</div>)}
        </div>
      ) : 'N/A'; break;
      default: result = data;
    }
  } catch (e) {
    console.log(e);
  }
  return result;
}

const FieldDisplay = props => (
  <Grid.Column>
    <Header as={props.as || 'h2'}>
      <Header.Subheader className="uppercase">{props.title}</Header.Subheader>
      {props.type ? processData(props.type, props.children) : props.children}
    </Header>
  </Grid.Column>
);

const FieldDisplayWOColumn = props => (
  <Header as={props.as || 'h2'} className={props.className}>
    <Header.Subheader className="uppercase">{props.title}</Header.Subheader>
    {props.type ? processData(props.type, props.children) : props.children}
  </Header>
);

const DetailsUnit = {
  FieldDisplay, FieldDisplayWOColumn
};

export default DetailsUnit;