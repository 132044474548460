import { makeObservable, observable, computed, action, toJS } from 'mobx';
import DataStore from '../shared/DataStore';
import { NEW_LAB } from '../../../constants/lab';
import { allLabs, labs, createLab, updateLab, lab, deleteLab, getLabConnectorPlugins } from '../../queries/labs';
import { MobxApollo } from '../../../../utils';
export class LabsStore extends DataStore {
  constructor() {
    super(NEW_LAB, 'lab', {
      all: allLabs, create: createLab, update: updateLab, getOne: lab,
      delete: deleteLab
    });

    makeObservable(this, {
      labList: observable.ref,
      count: computed,
      loading: computed,
      data: computed,
      labDetails: computed,
      initLoad: action,
      getLabList: action,
      getLabConnectorPlugins: action,
    });
  }

  labList = [];
  labConnectorPlugins = [];

  initLoad(params) {
    super.initLoad(params);
  };

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

  get plugins() {
    return (this.labConnectorPlugins && toJS(this.labConnectorPlugins)) || [];
  }

  get labDetails () {
    return this.entityDetails
  }

  getLabList = () => {
    MobxApollo.graphql({
      client: this.client,
      query: labs,
      fetchPolicy: 'network-only',
      variables: {
        first: this.params.perPage,
        skip: 0,
        orderBy: 'name_ASC',
        filter: [],
      },
      onError: (err) => {
        console.error('onError=>', err);
      },
      onFetch: (data) => {
        if (data) {
          this.labList = data['labs'];
        }
      },
    });
  };

  getLabConnectorPlugins = () => {
    MobxApollo.graphql({
      client: this.client,
      query: getLabConnectorPlugins,
      fetchPolicy: 'network-only',
      onError: (err) => {
        console.error('onError=>', err);
      },
      onFetch: (data) => {
        if (data) {
          this.labConnectorPlugins = data['getLabConnectorPlugins'];
        }
      },
    });
  };
}

export default new LabsStore();
