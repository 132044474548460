import * as Loglevel from 'loglevel';
import { DEPLOY_ENV } from '../../../constants/common';

// read https://www.npmjs.com/package/loglevel for details
class Logger {
  levelMapping = { production: 'silent' }
  constructor () {
    const level = this.levelMapping[DEPLOY_ENV]  || 'trace';
    Loglevel.setLevel(level);
  }

  trace = msg => Loglevel.trace(msg);
  log = msg => Loglevel.log(msg); //debug
  info = msg => Loglevel.info(msg);
  warn = msg => Loglevel.warn(msg);
  error = msg => Loglevel.error(msg);
}

export default new Logger();
