

import React from 'react';
import { Grid, Image, Header, Divider } from 'semantic-ui-react';
import queryString from 'query-string';
import OrdrsLogo from '../../../assets/images/ordrs-logo.svg';

const msgMapping = ['Apologies.', 'Success!'];

const actionNotes = {
  reviewOrder: {
    heading: 'Hooray!',
    additionalText: (
      <>
        You have an order to review.<br />
        Sign-in now to view this order.
      </>
    )
  }
}

class AuthSideCol extends React.Component {
  render() {
    const { claimStatus } = this.props;
    const parsedHash = queryString.parse(document.location.search);
    return (
      <Grid.Column>
        <div className="signup-section">
          <Image src={OrdrsLogo} centered size="tiny" />
          <Divider section hidden />
          <Header as="h1" inverted>
            {claimStatus && msgMapping[claimStatus.status] ?
              msgMapping[claimStatus.status] : (
                parsedHash && parsedHash.action ?
                  actionNotes[parsedHash.action].heading : 'Welcome.'
              )}
          </Header>
          {claimStatus && claimStatus.msgHead && (
            <Header as="h2" inverted>{claimStatus.msgHead}</Header>
          )}
          <Header as="h3" inverted>
            {parsedHash && parsedHash.action ? (
              <>
                {actionNotes[parsedHash.action].additionalText}
              </>
            ) : (
              <>
                {claimStatus ? claimStatus.message : (
                  <>
                    We’re happy that you’re here.<br />
                    Please sign-in to continue.
                  </>
                )}
                {claimStatus && claimStatus.message2 && (
                  <>
                    <br />
                    {claimStatus.message2}
                  </>
                )}
                {claimStatus && claimStatus.status === 0 && (
                  <small>
                    <br /><br />
                    You do not need to sign-in at this time.
                  </small>
                )}
              </>
            )}
          </Header>
        </div>
      </Grid.Column>
    );
  }
}

export default AuthSideCol;
