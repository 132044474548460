import React from 'react';
import { inject, observer } from 'mobx-react';
import { filter, intersection, get } from 'lodash';
import { Auth } from 'aws-amplify';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { Sidebar, Image, Menu, Icon } from 'semantic-ui-react';
import copy from 'copy-to-clipboard';
import OrdrsLogo from '../../../assets/images/ordrs-logo.svg';
import { UserContext } from '../../contexts';
import { Utility as Utils } from "../../../utils";

const roleMap = { ADMIN: 'ORDRS', MD: 'M.D.' };
class MySidebar extends React.Component {
  logout = async () => {
    this.props.authStore.logout();
    this.props.history.push('/');
    window.location.reload();
  }

  copyToken = async () => {
    const session = await Auth.currentSession();
    const jwtToken = `{"authorization": "Bearer ${get(session, 'accessToken.jwtToken')}" }`;
    copy(jwtToken);
  }

  render() {
    const { loading, details: data } = this.props.usersStore;
    const { currentSession } = this.props.authStore;
    const permitted = Utils.isSuperAdmin(get(currentSession, 'email')) ? [...document.userMetaData, 'SUPERADMIN'] || [] : document.userMetaData || [];
    let isMobile = this.props.uiStore.isMobile();
    const isTablet = isMobile && document.body.offsetWidth >= 768;
    if (isTablet) {
      isMobile = false;
    }
    const navItems = filter(
      this.props.navStore.sidebarItems,
      n => ((n.accessibleTo.length === 0 || intersection(n.accessibleTo, permitted).length > 0))
    );
    let analyticsMenuIndex = navItems.findIndex(obj => obj.title === 'Analytics');
    if (!data.showGDSAnalytics && analyticsMenuIndex !== -1) {
      navItems.splice(analyticsMenuIndex, 1);
    }
    let role = '';
    try {
      role = document.userMetaData && document.userMetaData.length > 1 ?
        document.userMetaData[1] : document.userMetaData[0];
    } catch (e) { }
    return (
      <UserContext.Consumer>
        {(value) => (
          <Sidebar style={{ backgroundColor: '#1c2339' }} data-id={loading} as={Menu} animation='push' width="thin" inverted vertical visible>
            <Link to="/app/orders"><Image src={OrdrsLogo} className={`logo ${isMobile && !isTablet ? 'dnone' : 'dblock'}`} /></Link>
            {!isTablet && !isMobile && (
              <div className={`logoName ${isMobile ? 'dblock' : 'dnone'}`}>
                <Link to="/app/orders"><Image src={OrdrsLogo} className={`logoImg ${isTablet ? 'tablet-mode' : ''}`} /></Link>
                <span>ORDRS</span>
              </div>
            )}
            <div className="welcomeText">
              <span>Welcome</span>
              <span onDoubleClick={() => this.copyToken()}>{value['given_name']} {value['family_name']} &nbsp;&nbsp;{roleMap[role] || role}</span>
            </div>
            {navItems.map(item => (
              <Menu.Item key={item.to} as={NavLink} to={`/app/${item.to}`} onClick={() => this.props.uiStore.toggleSideBar()}>
                <span>


                  {item.icon &&
                    <Icon className={item.icon} />
                  }
                  <span style={{ marginLeft: '4px' }}>{item.title}
                  </span>
                </span>
              </Menu.Item>
            ))}
            <div className="logout">
              <Menu.Item onClick={this.logout}><Icon className="sign-out" />Signout</Menu.Item>
              {/* <Menu.Item as={Link} onClick={() =>this.props.uiStore.toggleSideBar()} to="/app/profile" className="profile">{value['given_name']} {value['family_name']} &nbsp;&nbsp;{roleMap[role] || role}</Menu.Item> */}
            </div>
          </Sidebar>
        )}
      </UserContext.Consumer>
    );
  }
}

export default inject('navStore', 'authStore', 'usersStore', 'uiStore')(withRouter(observer(MySidebar)));