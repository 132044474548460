import { USER, ADDRESS, GENDER_ORDER, PHONE, EMAIL,  } from './shared';
export const NEW_ORDER = {
  panelId: {
    value: '',
    error: undefined,
    placeHolder: 'Select Panel',
    label: 'PANEL',
    rule: 'required',
  },
  labTestIds: {
    value: [],
    error: undefined,
    placeHolder: 'Select Lab Tests',
    label: 'Lab Tests',
    rule: 'optional',
  },
  firstName: { ...USER.firstName, rule: 'required', objRefOutput: 'patient', objRef: 'patient' },
  lastName: { ...USER.lastName, rule: 'required', objRefOutput: 'patient', objRef: 'patient' },
  dob: { ...USER.dob, rule: 'required|order_age', objRefOutput: 'patient', objRef: 'patient' },
  gender: { ...GENDER_ORDER, rule: 'order_gender', label: 'BIOLOGICAL GENDER', objRefOutput: 'patient', objRef: 'patient' },
  phone: { ...PHONE, rule: 'required', objRefOutput: 'patient', objRef: 'patient' },
  email: { ...EMAIL, rule: 'email', objRefOutput: 'patient' },
  addressLine1: { ...ADDRESS.addressLine1, rule: 'required', objRefOutput: 'patient.address', objRef: 'patient.address' },
  city: { ...ADDRESS.city, rule: 'required', objRefOutput: 'patient.address', objRef: 'patient.address' },
  state: { ...ADDRESS.state, rule: 'required', objRefOutput: 'patient.address', objRef: 'patient.address' },
  zip: { ...ADDRESS.zip, rule: 'required', objRefOutput: 'patient.address', objRef: 'patient.address' },
  orderId: {
    value: '',
    error: undefined,
    label: 'OPTIONAL ID',
    placeHolder: 'Enter Kit ID or unique identifier',
    rule: 'optional',
  },
  context: {
    value: '',
    error: undefined,
    defaultValue: "",
    label: 'CONTEXT',
    placeHolder: 'Add Context',
    rule: 'optional|isValidJson',
  },
};

export const BULK_UPLOAD_MAX = 25;