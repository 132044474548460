import DataStore from '../shared/DataStore';

export class BillingStore extends DataStore {
  constructor() {
    super();
  }

  initLoad(params) {
    super.initLoad(params);
  }

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get useMeta() {
    return super.userMetaData;
  }

  get count() {
    return super.count();
  }
}

export default new BillingStore();
