import React from 'react';
import { isEmpty } from 'lodash';
import ReactJson from 'react-json-view'
import { Header } from 'semantic-ui-react';
import { OrderModal } from '../index';


function JsonViewerModel(props) {
  const { responseData, handleCloseModel, open, modelSize, modelHeader } = props;
  return (

    <OrderModal
      className="auth-modal"
      header={modelHeader || 'Response Payload'}
      size={modelSize || 'large'}
      open={open}
      onClose={e => handleCloseModel(e, false)}
    >
      {responseData && !isEmpty(responseData)
        ? (
          <pre className="no-updates bg-offwhite padded json-text">
            <ReactJson src={responseData} displayDataTypes={false} indentWidth={2} collapseStringsAfterLength={25} />
          </pre>
        )
        : (
          <section className="bg-offwhite mb-20 center-align">
            <Header as="h5">No Response Available.</Header>
          </section>
        )
      }
    </OrderModal>
  );
}

export default JsonViewerModel;
