import React from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { NotFound } from '../../components/shared';
import Auth from '../auth';
import Claim from '../claim';

class Public extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setClaim();
    this.state = { claim: false };
  }

  componentWillUpdate() {
    this.setClaim();
  }
  setClaim = () => {
    const parsedHash = queryString.parse(this.props.location.search);
    if (parsedHash.o && parsedHash.t) {
      this.setState({ claim: parsedHash });
    } else {
      this.setState({ claim: false });
    }
  }
  render() {
    const { claim } = this.state;
    return (
      <Switch>
        {claim && <Route to="/claim" component={Claim} />}
        <Redirect exact strict from="/" to="/auth/login" />
        <Route exact to="/auth/:action" component={Auth} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default withRouter(Public)
