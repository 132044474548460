import { makeObservable, computed, action, observable, toJS, extendObservable } from "mobx";
import DataStore from "../shared/DataStore";
import { get, trim, startCase, orderBy, filter, camelCase, isEmpty, pickBy, has, identity, forEach } from "lodash";
import cleanDeep from "clean-deep";
import omitDeep from 'omit-deep';
import { GqlClient as client } from "../../../../api/gqlApi";
import { MobxApollo, FormHandler as FormHandle, Utility as Utils } from "../../../../utils";
import { NEW_PANEL, MARKER_META, COPY_PANEL, ADD_NEW_FORM_META, ADD_NEW_QUESTION_META, AUTO_GENERATE_KITID_META } from "../../../constants/panel";
import { UNITS } from "../../../constants/marker";
import {
  allPanels,
  panelSubscription,
  createPanel,
  updatePanel,
  updateStatus,
  panel,
  createMarker,
  updateMarker,
  attachFileUploadToPanel,
  addKitIdsToPanel, getKitIdInventory, deleteKitIdsOnPanel,
} from '../../queries/panels';
import { actionButton } from "@aws-amplify/ui";

export class PanelsStore extends DataStore {
  constructor() {
    super(NEW_PANEL, 'panel', {
      all: allPanels,
      create: createPanel,
      update: updatePanel,
      getOne: panel,
      attach: attachFileUploadToPanel,
      updateStatus,
    });
    makeObservable(this, {
      markers: observable,
      addPanel: observable.ref,
      linkTests: observable.ref,
      markerAction: observable.ref,
      MARKERS_FRM: observable.ref,
      COPY_PANEL_FRM: observable.ref,
      ADD_NEW_FORM: observable.ref,
      ADD_NEW_QUESTION_FORM: observable.ref,
      progress: observable.ref,
      panelInventory: observable.ref,
      panelInventoryMeta: observable.ref,
      orderBy: observable.ref,
      direction: observable.ref,
      intakeFormDetails: observable.ref,
      DYNAMIC_PAYLOAD_FRM: observable.ref,
      formCurrentIndex: observable.ref,
      isPreview: observable.ref,
      AUTO_GENERATE_KITID_FORM: observable.ref,
      labSelectedMarkers: observable.ref,
      count: computed,
      loading: computed,
      data: computed,
      panelDetails: computed,
      getIntakeFormList: computed,
      getIntakeFormDetails: computed,
      getCurrentFormDetails: computed,
      initLoad: action,
      saveMarker: action,
      changeAddPanel: action,
      changeLinkTest: action,
      resetMarkers: action,
      addMore: action,
      setCopyForm: action,
      copyPanel: action,
      setIntakeFormDetails: action,
      inputKeyChange: action,
      prepareDynamicForm: action,
      createDynamicFormFields: action,
      formChangeForPlugin: action,
      resetIntakeFormDetails: action,
      savePanelWithMarkers: action,
    });
  }

  MARKERS_FRM = FormHandle.prepareForm(MARKER_META);
  COPY_PANEL_FRM = FormHandle.prepareForm(COPY_PANEL);
  ADD_NEW_FORM = FormHandle.prepareForm(ADD_NEW_FORM_META);
  ADD_NEW_QUESTION_FORM = FormHandle.prepareForm(ADD_NEW_QUESTION_META);
  AUTO_GENERATE_KITID_FORM = FormHandle.prepareForm(AUTO_GENERATE_KITID_META);
  DYNAMIC_PAYLOAD_FRM = {};
  markers = [];
  panelInventory = [];
  panelInventoryMeta = null;
  addPanel = false;
  linkTests = false;
  markerAction = 'new';
  progress = false;
  client = client;
  orderBy = 'createdAt';
  direction = 'DESC';
  intakeFormDetails = [];
  formCurrentIndex = 0;
  isPreview = false;
  labSelectedMarkers = [];

  initLoad(params) {
    super.initLoad(params);
  }

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get panelDetails() {
    return this.entityDetails;
  }

  savePanelWithMarkers(id, forcedParams, addonParams) {
    const existingLabMarkers = get(this.panelDetails, 'labMarkersIds');
    console.log('existingLabMarkers', existingLabMarkers);
    
    const addonDetails = { ...addonParams, labMarkersIds: isEmpty(this.labSelectedMarkers) ? existingLabMarkers : this.labSelectedMarkers }
    console.log(addonDetails);
    this.save(id, forcedParams, addonDetails);
    this.setFieldValue('labSelectedMarkers', []);
  }

  clearRange = (e, result, form) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const tempData = { ...this[formName] };

    tempData.fields.ranges[form[2]].rangeValue.value = (tempData.fields.ranges[form[2]].type.value === 'EQUAL_STRING' || result.value === 'EQUAL_STRING') ?
      '' : tempData.fields.ranges[form[2]].rangeValue.value;

    this.formChange(e, result, form);
    this[formName] = { ...tempData };
  };

  rangeChange = (e, result, form) => {
    const formName = Array.isArray(form) ? form[0] : form;

    this[formName].fields.ranges[form[2]][result.name].rule = ['EQUAL_STRING', 'CATCH_ALL'].includes(this[formName].fields.ranges[form[2]].type.value)
      ? 'alphaNumeric'
      : 'float';

    this.formChange(e, result, form);
  };

  setResetRange = (i, data) => {
    ['name', 'isCritical', 'isReportable', 'isThreshold', 'type', 'rangeValue', 'rangeType'].forEach((k) => {
      this.formChange(
        {},
        { name: k, value: data === false ? '' : data[k] },
        ['MARKERS_FRM', 'ranges', i],
        'dropdown'
      );
    });
  };

  addMore = (form, refKey) => {
    this[form] = FormHandle.addMoreRecordToSubSection(
      this[form],
      refKey,
      1,
      true
    );
    const i = this[form].fields.ranges.length - 1;
    ['name', 'isCritical', 'isReportable', 'isThreshold', 'type', 'rangeValue', 'rangeType'].forEach(() => {
      this.setResetRange(i, false);
    });
    this.currTime = +new Date();
  };

  changeAddPanel = (action) => {
    this.addPanel = !this.addPanel;
    this.markerAction = action || 'new';
    if (this.markerAction === 'new') {
      this.resetMarkers();
    }
    this.progress = false;
  };

  changeLinkTest = (action) => {
    this.linkTests = !this.linkTests;
  };

  setMarker = (data) => {
    const dataToSet = { ...data };
    if (!UNITS.includes(data.units)) {
      dataToSet.units = 'other';
      dataToSet.unitsFreeTxt = data.units;
    }
    this.MARKERS_FRM = FormHandle.setFormData(this.MARKERS_FRM, dataToSet);
    const rangesCnt = data.ranges.length;
    for (let i = 0; i < rangesCnt; i += 1) {
      this.setResetRange(i, data.ranges[i]);
    }
  };

  resetMarkers = () => {
    this.MARKERS_FRM = FormHandle.prepareForm(MARKER_META);
    this.setResetRange(0, false);
    this.MARKERS_FRM = FormHandle.resetFormData(this.MARKERS_FRM);
  };

  saveMarker = async () => {
    const params = FormHandle.evaluateFormData(this.MARKERS_FRM.fields);
    params.ranges = params.ranges.map((r) => {
      r.value = r.rangeValue;
      delete r.rangeValue;
      return r;
    });
    if (params.units === 'other') {
      params.units = params.unitsFreeTxt;
      params.unitsFreeTxt = undefined;
    }
    this.progress = true;
    const roles = document.userMetaData || [];
    try {
      await this.client.mutate({
        mutation: this.markerAction === 'new' ? createMarker : updateMarker,
        variables: {
          panelId: this.entityDetails.id,
          ...toJS(params),
          ...(this.markerAction !== 'new' ? { id: this.markerAction } : null),
        },
        refetchQueries: [
          {
            query: typeof panel === 'function' ? panel(roles.includes('ADMIN')) : panel,
            variables: { id: this.entityDetails.id },
          },
        ],
      });
      this.progress = false;
      const markers = [...this.markers];
      markers.push(params);
      this.markers = markers;
      Utils.toast(
        `Marker ${this.markerAction === 'new' ? 'added' : 'updated'
        } successfully.`,
        'success'
      );
    } catch (e) {
      Utils.toast(
        `Error ${this.markerAction === 'new' ? 'adding' : 'updating'} Marker.`,
        'error'
      );
      this.progress = 0;
    }
  };

  subscribe = async (id, status, userId) => {
    this.progress = true;
    this.noloaderEvent = true;
    const label = status === 'OPT_IN' ? 'Opt-In' : 'Opt-Out';
    try {
      const res = await this.client.mutate({
        mutation: panelSubscription,
        variables: { id, status, userId },
      });
      this.result = this.result.map((p) => {
        if (p.id === id) {
          p.optedInAt = get(res, 'data.panelSubscription.optedInAt');
        }
        return p;
      });
      this.progress = false;
      Utils.toast(`${label} successfully.`, 'success');
      return true;
    } catch (e) {
      this.progress = 0;
      Utils.toast(`Error while ${label}.`, 'error');
      return false;
    } finally {
      this.noloaderEvent = false;
      this.currTime = +new Date();
    }
  };

  addKitIdsToPanel = async (params) => {
    const { panelId, prefix, kitIds, perPage, kitIdGenerationType = undefined, externalPlugin, externalQuantity } = params;
    this.progress = true;
    this.noloaderEvent = true;
    try {
      let variables = {};
      if (kitIdGenerationType && kitIdGenerationType === 'auto') {
        const kitIdFormData = FormHandle.evaluateFormData(this.AUTO_GENERATE_KITID_FORM.fields);
        variables = {
          panelId: panelId,
          type: kitIdGenerationType,
          length: get(kitIdFormData, 'kitIdLength'),
          prefix: get(kitIdFormData, 'kitIdPrefix'),
          postfix: get(kitIdFormData, 'kitIdPostfix'),
          quantity: get(kitIdFormData, 'kitIdQuantity'),
        };
      } else if (kitIdGenerationType && kitIdGenerationType === 'external') {
        variables = {
          panelId: panelId,
          type: kitIdGenerationType,
          plugin: externalPlugin,
          quantity: externalQuantity,
        };
      } else {
        const arrKitIds = kitIds.split(',').map(e => trim(e));
        variables = { panelId: panelId, kitIds: arrKitIds };
      }
      const resp = await this.client.mutate({
        mutation: addKitIdsToPanel,
        variables        
      });
      const customMessage = this.buildKitMessage(get(resp, 'data.addKitIdsToPanel'));
      Utils.toast(customMessage, 'success');
      return true;
    } catch (e) {
      this.progress = 0;
      this.noloaderEvent = false;
      Utils.toast(`Error while uploading new Kit Id(s).`, 'error');
      return false;
    } finally {
      this.noloaderEvent = false;
      this.getKitIdInventory(perPage, 0, prefix);
      this.currTime = +new Date();
    }
  }

  buildKitMessage = (kitIdDetails) => {
    let customMsg = '';
    if (kitIdDetails) {
      if (get(kitIdDetails, 'collision').length === 0 && get(kitIdDetails, 'skipped').length === 0 && get(kitIdDetails, 'success').length > 0) {
        customMsg = 'New Kit Id(s) added successfully.';
      } else {
        if (get(kitIdDetails, 'collision[0]')) {
          customMsg+= `${get(kitIdDetails, 'collision').join()} ${get(kitIdDetails, 'collision').length > 1 ? 'Ids are' : 'Id is'} collision. `;
        }
        if (get(kitIdDetails, 'skipped[0]')) {
          customMsg+= ` ${get(kitIdDetails, 'skipped').join()} ${get(kitIdDetails, 'skipped').length > 1 ? 'Ids are' : 'Id is'} skipped. `;
        }
        if (get(kitIdDetails, 'success[0]')) {
          customMsg+= ` ${get(kitIdDetails, 'success').join()} ${get(kitIdDetails, 'success').length > 1 ? 'Ids are' : 'Id is'} added successfully.`;
        }
      }
    }
    return customMsg;
  }

  deleteKitIdsOnPanel = async (panelPrefix, kitIds, perPage) => {
    this.progress = true;
    this.noloaderEvent = true;
    try {
      await this.client.mutate({
        mutation: deleteKitIdsOnPanel,
        variables: { panelPrefix, kitIds },
      });
      Utils.toast(`Kit Id(s) deleted successfully.`, 'success');
      return true;
    } catch (e) {
      this.progress = 0;
      this.noloaderEvent = false;
      Utils.toast(`Error while deleting Kit Id.`, 'error');
      return false;
    } finally {
      this.noloaderEvent = false;
      this.getKitIdInventory(perPage, 0, panelPrefix);
      this.currTime = +new Date();
    }
  }

  getKitIdInventory = async (perPage, skip, prefix, searchFilter = {}) => {
    this.progress = true;
    this.noloaderEvent = true;

    if (prefix) {
      MobxApollo.graphql({
        client: this.client,
        query: getKitIdInventory,
        fetchPolicy: "network-only",
        variables: {
          first: perPage,
          skip,
          filter: { panel: { prefix }, ...searchFilter },
          orderBy: `${this.orderBy}_${this.direction}`
        },
        onError: (err) => {
          this.progress = 0;
          this.noloaderEvent = false;
          Utils.toast(`Error while loading Kit Id(s).`, "error");
        },
        onFetch: (data) => {
          if (data) {
            if (skip > 0) {
              this.panelInventory = [...this.panelInventory, ...data.getKitIdInventory];
            } else {
              this.panelInventory = data.getKitIdInventory;
            }
            this.panelInventoryMeta = data.getKitIdInventoryMeta.count;
            this.progress = 0;
            this.noloaderEvent = false;
          }
        },
      });
    }
  }

  setInventoryOrder = (by, direction, info) => {
    this.orderBy = by;
    this.direction = direction;
    this.getKitIdInventory(info.perPage, 0, info.prefix, info.filter);
  };

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

  setCopyForm = () => {
    console.log('entityDetails==>', this.entityDetails);
    this.COPY_PANEL_FRM = FormHandle.setFormData(this.COPY_PANEL_FRM, this.entityDetails);
  }

  copyPanel = () => new Promise(async (res, rej) => {
    try {
      this.setLoading(true);
      const existingPanelData = { ...this.entityDetails };
      let panelDetails = cleanDeep(existingPanelData);
      panelDetails = omitDeep(panelDetails, ['__typename', 'fileHandle']);
      const formData = FormHandle.evaluateFormData(this.COPY_PANEL_FRM.fields);
      const authToken = this.getToken(formData.copyAuthToken);
      const targetClientParams = { environment: formData.copyTargetEnv, token: authToken };
      this.setTargetClient(targetClientParams);
      const panelMetaObj = panelDetails.meta ? JSON.parse(panelDetails.meta) : panelDetails.meta;
      panelMetaObj && delete panelMetaObj['panelImage'];
      const panelMeta = panelMetaObj && JSON.stringify(panelMetaObj);
      let params = {};
      params.name = formData.copyPanelName;
      params.prefix = formData.copyPanelPrefix;
      params.brandId = formData.copyBrandDbUuid;
      params.labId = formData.copyLabDbUuid;
      params.config = panelDetails.config;
      params.meta = panelMeta;
      params.ineligibleStates = panelDetails.ineligibleStates;
      // params.labApiIntegration = panelDetails.labApiIntegration;
      params.gender = panelDetails.gender;
      params.maxAge = panelDetails.maxAge;
      params.minAge = panelDetails.minAge;
      params.status = panelDetails.status;
      params.version = panelDetails.version || 'V1';
      params.type = panelDetails.type || 'FULL_PANEL';

      const markerArr = this.fetchPanelMarkers({ markers: panelDetails.markers });
      params.markers = markerArr;
      console.log(params);
      const saveMutation = createPanel(true);
      const resp = await this.targetClient.mutate({
        mutation: saveMutation,
        variables: { ...params },
      });
      // if (get(resp, 'data.createPanel.id')) {
      //   await this.createMarkerCopy({ panelId: get(resp, 'data.createPanel.id'), markers: panelDetails.markers });
      // }
      Utils.toast(
        `${startCase(this.refKey)} created successfully.`,
        "success"
      );
      this.auStatus = 2;
      this.setLoading(false);
      res();
    } catch (e) {
      console.log('error==>', e);
      this.auStatus = 0;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while creating ${this.refKey}.`,
        "error"
      );
      this.setLoading(false);
      rej();
    }
  });

  fetchPanelMarkers = (params) => {
    const { markers } = params;
    const funcArray = [];
    try {
      if (get(markers, '[0]')) {
        markers.forEach((element) => {
          delete element['id'];
          const rangArr = [];
          element.ranges.forEach(item => {
            delete item['id'];
            delete item['rangeValue'];
            rangArr.push(item);
          });
          element.ranges = rangArr;
          funcArray.push(toJS(element));
        });
      }
      return funcArray;
    } catch (err) {
      return funcArray;
    }
  }

  createMarkerCopy = (params) => new Promise(async (resolve, reject) => {
    const { panelId, markers } = params;
    const funcArray = [];
    try {
      if (get(markers, '[0]')) {
        markers.forEach(async (element) => {
          delete element['id'];
          const rangArr = [];
          element.ranges.forEach(item => {
            delete item['id'];
            delete item['rangeValue'];
            rangArr.push(item);
          });
          element.ranges = rangArr;
          funcArray.push(await this.targetClient.mutate({
            mutation: createMarker,
            variables: {
              panelId,
              ...toJS(element),
            }
          }));
        });
        Promise.all(funcArray).then((responseArr) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
      } else {
        resolve();
      }
    } catch (e) {
      reject();
    }
  });

  /* [START] :: Intake Multi Form And Question Builder Function */

  setIntakeFormDetails = (param) => {
    const intakeFormData = get(param, 'intakeForm') || [];
    this.setFieldValue('intakeFormDetails', intakeFormData);
  }

  get getIntakeFormDetails() {
    return (this.intakeFormDetails && toJS(this.intakeFormDetails)) || [];
  }

  get getIntakeFormList() {
    let intakeFormArr = this.getIntakeFormDetails;
    let intakeForms = [];
    intakeForms = [...intakeForms, ...orderBy(filter(intakeFormArr, i => i.page > 0), ['page'], ['asc'])];
    return intakeForms;
  }

  addNewDynamicData = (params) => {
    const { form, regulation, page, type, formIndex } = params;

    let investNow = this.getIntakeFormList;

    if (['QUE_EDIT', 'FORM_EDIT', 'QUE_REQUIRED'].includes(type)) {
      let formData;
      if (type !== 'QUE_REQUIRED') {
        formData = FormHandle.evaluateFormData(this[form].fields);
      }
      const index = investNow.findIndex(i => i.page === page);
      if (['QUE_REQUIRED', 'QUE_EDIT'].includes(type) && formIndex >= 0) {
        const editFormElementMeta = this.generateFormElementMeta(formData);
        const editQUE = type === 'QUE_EDIT' ? {
          inputType: formData.inputType,
          keyValuePair: ['DROPDOWN', 'CHECKBOX', 'RADIO'].includes(formData.inputType) ? formData.keyValuePair : '',
          inputKeyName: formData.inputKeyName,
          defaultVal: formData.defaultVal,
          questionLabel: formData.questionLabel,
          formElementMeta: editFormElementMeta,
          addonProps: formData.addonProps,
          isRequired: formData.isRequired,
          isMultiCheck: formData.isMultiCheck,
          status: formData.status,
        } : { isRequired: !investNow[index].questions[formIndex].isRequired };
        investNow[index].questions[formIndex] = { ...investNow[index].questions[formIndex], ...editQUE };
      } else {
        investNow[index] = { ...investNow[index], title: formData.title, note: formData.note, hideHeader: formData.hideHeader === true || false };
      }
    } else if (form === 'ADD_NEW_FORM') {
      const formData = FormHandle.evaluateFormData(this[form].fields);
      const addPage = {
        page: ((investNow && investNow.length) || 0) + 1,
        regulation,
        title: formData.title,
        note: formData.note,
        hideHeader: formData.hideHeader === true || false,
      };
      investNow.push(addPage);
    } else if (form === 'ADD_NEW_QUESTION_FORM') {
      const formData = FormHandle.evaluateFormData(this[form].fields);
      const agreementToc = investNow.find(i => i.page === page);
      const formElementMeta = this.generateFormElementMeta(formData);

      const addQUE = {
        inputType: formData.inputType,
        keyValuePair: ['DROPDOWN', 'CHECKBOX', 'RADIO'].includes(formData.inputType) ? formData.keyValuePair : '',
        inputKeyName: formData.inputKeyName,
        defaultVal: formData.defaultVal,
        questionLabel: formData.questionLabel,
        order: (get(agreementToc, 'questions[0]') ? get(agreementToc, 'questions').length : 0) + 1,
        formElementMeta: formElementMeta,
        addonProps: formData.addonProps,
        isRequired: formData.isRequired,
        isMultiCheck: formData.isMultiCheck,
        status: formData.status,
      };
      investNow = investNow.map(i => ((i.page === page) ? { ...i, questions: i.questions && i.questions.length ? [...i.questions, addQUE] : [addQUE] } : { ...i }));
    } else if (type === 'PAGE') {
      const index = investNow.findIndex(i => i.page === page && i.regulation === regulation);
      investNow.splice(index, 1);
    } else if (type === 'QUE_DELETE') {
      // delete particular Question
      investNow = investNow.map((i) => {
        let iData;
        if (i.page === page) {
          i.questions.splice(formIndex, 1);
          iData = { ...i, questions: i.questions };
        } else {
          iData = { ...i };
        }
        return iData;
      });
    } else if (type === 'REORDER') {
      const index = investNow.findIndex(i => i.page === page);
      return { index, investNow };
    }
    return { intakeForm: investNow };
  }

  generateFormElementMeta = (formData) => {
    const inputType = get(formData, 'inputType');
    let elementMeta = {};

    switch (inputType) {
      case 'TEXTAREA':
        elementMeta = {
          [get(formData, 'inputKeyName')]: {
            value: '',
            error: undefined,
            placeHolder: `Enter ${startCase(get(formData, 'inputKeyName'))}`,
            label: startCase(get(formData, 'inputKeyName')),
            defaultValue: get(formData, 'defaultVal') || "",
            rule: get(formData, 'isRequired') ? 'required' : 'optional',
          }
        };
        break;
      case 'DROPDOWN':
        const valueArrForDropDown = JSON.parse(get(formData, 'keyValuePair'));
        elementMeta = {
          [get(formData, 'inputKeyName')]: {
            value: '',
            error: undefined,
            values: valueArrForDropDown,
            placeHolder: `Enter ${startCase(get(formData, 'inputKeyName'))}`,
            label: startCase(get(formData, 'inputKeyName')),
            defaultValue: get(formData, 'defaultVal') || "",
            rule: get(formData, 'isRequired') ? 'required' : 'optional',
          }
        };
        break;
      case 'CHECKBOX':
        const valueArrForCheckBox = JSON.parse(get(formData, 'keyValuePair'));
        const isMultiCheck = get(formData, 'isMultiCheck');
        elementMeta = {
          [get(formData, 'inputKeyName')]: {
            value: isMultiCheck ? [] : '',
            error: undefined,
            values: valueArrForCheckBox,
            placeHolder: `Enter ${startCase(get(formData, 'inputKeyName'))}`,
            label: startCase(get(formData, 'inputKeyName')),
            defaultValue: get(formData, 'defaultVal') || (isMultiCheck ? [] : ""),
            rule: get(formData, 'isRequired') ? 'required' : 'optional',
          }
        };
        break;
      case 'RADIO':
        const valueArrForRadio = JSON.parse(get(formData, 'keyValuePair'));
        elementMeta = {
          [get(formData, 'inputKeyName')]: {
            value: '',
            error: undefined,
            values: valueArrForRadio,
            placeHolder: `Enter ${startCase(get(formData, 'inputKeyName'))}`,
            label: startCase(get(formData, 'inputKeyName')),
            defaultValue: get(formData, 'defaultVal') || "",
            rule: get(formData, 'isRequired') ? 'required' : 'optional',
          }
        };
        break;
      default:
        elementMeta = {
          [get(formData, 'inputKeyName')]: {
            value: '',
            error: undefined,
            placeHolder: `Enter ${startCase(get(formData, 'inputKeyName'))}`,
            label: startCase(get(formData, 'inputKeyName')),
            defaultValue: get(formData, 'defaultVal') || "",
            rule: get(formData, 'isRequired') ? 'required' : 'optional',
          }
        };
        break;
    }
    return JSON.stringify(elementMeta);
  }

  setIntakeFormData = ({ type, page, formIndex }) => {
    const intakeForms = this.getIntakeFormList;
    if (type === 'QUE_EDIT') {
      const index = intakeForms.findIndex(i => i.page === page);
      if (index > -1) {
        const formData = intakeForms[index];
        const que = get(formData, 'questions[0]') ? get(formData, `questions[${formIndex}]`) : {};
        this.ADD_NEW_QUESTION_FORM = FormHandle.setFormData(this.ADD_NEW_QUESTION_FORM, que);
        this.ADD_NEW_QUESTION_FORM = FormHandle.validateForm(this.ADD_NEW_QUESTION_FORM);
      }
    } else {
      const index = intakeForms.findIndex(i => i.page === page);
      if (index > -1) {
        const formData = intakeForms[index];
        // formData.hideHeader = [formData.hideHeader];
        this.ADD_NEW_FORM = FormHandle.setFormData(this.ADD_NEW_FORM, formData);
        this.ADD_NEW_FORM = FormHandle.validateForm(this.ADD_NEW_FORM);
      }
    }
  }

  inputKeyChange = (formName, name) => {
    const { value } = this[formName].fields[name];
    if (value !== '') {
      this[formName].fields[name].value = camelCase(value);
    }
  }

  setFormCurrentIndex = (page) => {
    const intakeForms = this.getIntakeFormList;
    const index = intakeForms.findIndex(i => i.page === page);
    if (index > -1) {
      this.setFieldValue('formCurrentIndex', index);
    }
    this.setFieldValue('isPreview', true);
  }

  /* [START] :: Dynamic Form Builder Functions */

  prepareDynamicForm = (page) => {
    const intakeForms = this.getIntakeFormList;
    if (get(intakeForms, '[0]')) {
      intakeForms.forEach((val, key) => {
        this.setFieldValue('DYNAMIC_PAYLOAD_FRM', {}, key);
        if (!isEmpty(val)) {
          const formData = val;
          const formQuestions = get(formData, 'questions') || [];
          let formMeta = {};
          if (formQuestions.length > 0) {
            formQuestions.forEach(element => {
              if (get(element, 'status') === 'PUBLISHED') {
                formMeta = { ...formMeta, ...this.pullValuesForDynamicInput(element) };
              }
            });
          }
          this.createDynamicFormFields(formMeta, key);
        }
      });
    }
  }

  createDynamicFormFields = (formMeta, form) => {
    this.DYNAMIC_PAYLOAD_FRM[form] = {};
    this.DYNAMIC_PAYLOAD_FRM[form] = FormHandle.prepareForm(formMeta, false, true, false, true);
  }

  formChangeForPlugin = (e, res, form, subForm = false, additionalProps = {}) => {
    if (subForm) {
      this[form.parentForm][form.childForm] = FormHandle.onChange(this[form.parentForm][form.childForm], FormHandle.pullValues(e, res));
      this.currTime = +new Date();
    }
  }


  pullValuesForDynamicInput = (data) => {
    let dynamicFormElement = null;
    try {
      if (!isEmpty(data) && get(data, 'formElementMeta')) {
        const inputType = get(data, 'inputType');
        const questionDetails = get(data, 'questionLabel');
        const additionProps = !isEmpty(get(data, 'addonProps')) ? { addonProps: JSON.parse(get(data, 'addonProps')) } : null;
        const key = get(data, 'inputKeyName');
        dynamicFormElement = JSON.parse(get(data, 'formElementMeta'));
        dynamicFormElement[key] = { ...dynamicFormElement[key], type: inputType, question: questionDetails, ...additionProps };
      }
    } catch (err) {
      console.log('ERROR ==>', err);
    }
    return dynamicFormElement;
  };

  get getCurrentFormDetails() {
    const intakeForms = this.getIntakeFormList;
    const index = this.formCurrentIndex;
    return intakeForms[index];
  }

  getFormElement = (fieldKey, formProps, formObj) => {
    console.log('inside getFormElement function');
    let formElement = '';
    const elementType = formProps.type;
    switch (elementType) {
      case 'TEXTAREA':
        formElement = 'FormTextarea';
        this.setFormData(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      case 'DROPDOWN':
        formElement = 'FormDropDown';
        this.setFormData(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      case 'CHECKBOX':
        formElement = 'FormCheckBoxOrRadio';
        this.setFormData(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      case 'RADIO':
        formElement = 'FormCheckBoxOrRadio';
        this.setFormData(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
      default:
        formElement = 'FormInput';
        this.setFormData(formObj.parentForm, fieldKey, formProps.defaultValue, formObj.childForm);
        break;
    }
    return formElement;
  }

  resetIntakeFormDetails = () => {
    this.setFieldValue('DYNAMIC_PAYLOAD_FRM', {});
    this.setFieldValue('formCurrentIndex', 0);
    this.setFieldValue('isPreview', false);
    this.setFieldValue('intakeFormDetails', []);
    this.setFieldValue('currentItem', null);
  }

  manageRangeTypeDropdown = (param = undefined) => {
    let catchAllIndex;
    this.MARKERS_FRM.fields.ranges.forEach((item, index) => {
      if (get(item, 'type.value') === 'CATCH_ALL') {
        catchAllIndex = index;
      }
    });

    if (get(param, 'action') === 'DROP_DOWN_CHANGE' && (!catchAllIndex || catchAllIndex < 0)) {
      this.MARKERS_FRM.fields.ranges.forEach((element, idx) => {
        const valueArr = get(element, 'type.values');
        valueArr.forEach(data => {
          if (data.key === 'CATCH_ALL' && has(data, 'disabled')) {
            delete data.disabled;
          }
        });
        this.MARKERS_FRM.fields.ranges[idx].type.values = valueArr;
      });
    } else if (!param || (get(param, 'action') === 'DROP_DOWN_CHANGE' && catchAllIndex >= 0)) {
      if (catchAllIndex >= 0) {
        this.MARKERS_FRM.fields.ranges.forEach((element, idx) => {
          if (idx !== catchAllIndex) {
            const valueArr = get(element, 'type.values');
            valueArr.forEach(data => {
              if (data.key === 'CATCH_ALL') {
                data['disabled'] = true;
              }
            });
            this.MARKERS_FRM.fields.ranges[idx].type.values = valueArr;
          }
        });
      }
    }
  }
}

export default new PanelsStore();
