import React from 'react';
import { get } from 'lodash';
import { observer, inject } from 'mobx-react';
import { Form } from 'semantic-ui-react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import moment from 'moment';
// import ImageUploadModule from './ImageUploadModule';
import { Utility as Utils } from '../../../utils';

class HtmlEditor extends React.Component {
  constructor(props) {
    super(props);
    // Quill.register('modules/imageUpload', ImageUploadModule);
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    const FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle, true);
  }
  state = { imageLoading: false }
  handleContentChange = (content, delta, source, editor) => {
    this.props.changed(this.props.name, editor.getLength() > 1 ? content : '', this.props.form, this.props.index);
  }

  handleImageUpload = async (file) => {
    try {
      const fileObj = {
        fileData: Utils.isBase64(file) ? Utils.b64toBlob(file) : file,
        fileName: `${moment().unix()}_${Utils.sanitize(file.name)}`,
        contentType: file.type,
        fileSize: file.size.toString(),
        entity: this.props.type || 'BRAND_META',
        entityId: this.props.resourceId
      };
      const res = await this.props.brandsStore.uploadObject(fileObj);
      return get(res, 'url');
    } catch {
    }
  }

  getModules = () => {
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        // ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link'],
        ['clean']                                         // remove formatting button
      ],
    //   imageUpload: {
    //     accept: ['image/jpeg', 'image/png', 'image/gif'],
    //     // maxSize: 100 * 1024, // in bytes
    //     invalid() { // called if image is not in array of accept or greater than maxSize
    //       console.log('upload error');
    //     },
    //     upload(file) {
    //       console.log(file);
    //       try {
    //         return new Promise(async (resolve) => {
    //           const url = await thisParent.handleImageUpload(file);
    //           setTimeout(() => {
    //             resolve(url);
    //           }, 2000);
    //         })
    //       } catch (e) {
    //         console.log(e);
    //       }
    //     }
    //   },
    };
    return modules;
  };

  render() {
    const { readOnly, noDivWrap, label, isHideLabel, defaultValue } = this.props;
    if (readOnly) {
      const EditorView = <Form.Field><label>{label}</label><ReactQuill readOnly value={this.props.content} theme="bubble" /></Form.Field>;
      return noDivWrap ? EditorView : <div className="parsed-data overflow-wrap">{EditorView}</div>;
    }
    return (
      <div class="field">
        {!isHideLabel && label}
        <ReactQuill
          value={this.props.content || null}
          defaultValue={defaultValue}
          modules={this.getModules(this)}
          onChange={this.handleContentChange}
        />
      </div>
    );
  }
}

export default inject('brandsStore')(observer(HtmlEditor))
