import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Image, Header, Divider, Form } from 'semantic-ui-react';
import OrdrsLogo from '../../../assets/images/ordrs-logo.svg';
import { FormInput } from '../../../components/formElements';
import { ListErrors } from '../../../components/shared';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.props.authStore.reset('FORGOT_PASS_FRM');
  }

  submit = async () => {
    const response = await this.props.authStore.forgotPassword();
    if (response === true) {
      this.props.history.push('/auth/confirm-password');
    }
  }
  render() {
    const { FORGOT_PASS_FRM, formChange, status, currTime } = this.props.authStore;
    return (
      <section className="signup-wrap inverted">
        <Container>
          <div className="signup-section">
            <Image src={OrdrsLogo} centered size="tiny" />
            <Divider hidden />
            <Header as="h2" inverted>Forgot your password?</Header>
            <Header as="h3" inverted className="mt-10">Enter your email to receive a link to reset your password.</Header>
            <Form data-time={currTime} inverted onSubmit={this.submit} className="text-left">
              <FormInput
                name="email"
                autoFocus="email"
                fielddata={FORGOT_PASS_FRM.fields.email}
                changed={(e, result) => formChange(e, result, 'FORGOT_PASS_FRM')}
              />
              <Divider hidden />
              {typeof status !== 'boolean' && <ListErrors errors={[status]} />}
              <div className="text-center">
                <Form.Button
                  loading={status === true}
                  primary
                  fluid
                  size="large"
                  content="Reset Password"
                />
                <Link to="/auth/login" className="inverted">Remember your password?</Link>
              </div>
            </Form>
          </div>
        </Container>
      </section>
    );
  }
}

export default inject('authStore')(observer(ForgotPassword));
