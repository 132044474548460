import { makeAutoObservable } from 'mobx';
import { isNull } from 'lodash';

export class UiStore {
  constructor() {
    makeAutoObservable(this);
  }

  redirectURL = undefined;
  appLoading = false;
  openSidebar = false;
  showApproveButton = true;

  isMobile = () => {
    const regExp = {
      ios: /(iPad|iPhone|iPod)/g,
      mobileChrome: /(CriOS)/g,
      mobile: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/g
    };
    const userAgent = navigator.userAgent;
    return !isNull(userAgent.match(regExp.mobile));
  }

  setRedirectURL(url) {
    this.redirectURL = url;
  }
  resetAll = () => {
    this.redirectURL = undefined;
    this.appLoading = false;
    this.openSidebar = false;
  }

  updateLoading = (state) => this.appLoading = state;

  toggleSideBar = () => this.openSidebar = !this.openSidebar;
}

export default new UiStore();
