import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container, Header, Icon } from 'semantic-ui-react';

class Layout extends React.Component {
  state = {
    ticker: null,
  };
  componentDidMount() {
    const ticker = setInterval(() => this.props.UiStore.tick(), 1000);
    this.setState({ ticker });
  }
  componentWillUnmount() {
    clearInterval(this.state.ticker);
  }
  render() {
    const { time } = this.props.UiStore;
    return (
      <Container textAlign="center">
        <br />
        <Header
          as='h1'
          content='This is react mobx based clock;'
        />
        <Header
          as='h2'
          content={time || 'loading time...'}
        />
        <Button primary size='huge'>
          Get Started
          <Icon name='right arrow' />
        </Button>
      </Container>
    )
  }
}

export default inject('authStore', 'uiStore')(observer(Layout));
