import { makeObservable, computed, action, observable } from 'mobx';
import DataStore from '../shared/DataStore';
import { NEW_USER, NEW_LICENSE } from '../../../constants/user';
import {
  allUsers, createUser, updateUser, updateStatus, userLabs,
  user, deleteUser, createApiUser, updateApiUser, forceDeleteUser,
  users, userBrands, getAdminGDSURL, createMDLicense, userLicense, updateMdLicense,
  deleteMdLicense, updateMdPayoutDetials
} from '../../queries/users';
import { MobxApollo, Utility as Utils, FormHandler as FormHandle, } from '../../../../utils';
import { reassignAllMdOrders, ordersCount } from '../../queries/orders';

export class UsersStore extends DataStore {
  constructor() {
    super(NEW_USER, 'user', {
      all: allUsers, create: createUser, update: updateUser,
      createAPI: createApiUser, updateAPI: updateApiUser,
      getOne: user, updateStatus, delete: deleteUser, forceDelete: forceDeleteUser
    });
    makeObservable(this, {
      count: computed,
      useMeta: computed,
      loading: computed,
      data: computed,
      accounts: observable.ref,
      assignedOrderCount: observable.ref,
      userList: observable.ref,
      initLoad: action,
      TEST_FRM: observable.ref,
      addLicense: observable.ref,
      save: action,
      getLicenses: action,
      testAction: observable.ref,
      licenseID: observable.ref,
      stateLicenses: observable.ref,
      newLicense: observable.ref
    });
  }
  TEST_FRM = FormHandle.prepareForm(NEW_LICENSE);
  addLicense = false;
  accounts = [
    { id: 1, createdAt: '12/5/18', qty: 100, amount: 300.00 },
    { id: 2, createdAt: '1/5/19', qty: 150, amount: 450.00 },
    { id: 3, createdAt: '2/5/19', qty: 200, amount: 600.00 },
    { id: 4, createdAt: '3/5/19', qty: 400, amount: 1200.00 },
  ];
  assignedOrderCount = 0;
  userList = [];
  testAction = "new";
  licenseID = "";
  stateLicenses = [];
  newLicense = {
    name: '',
    license: '',
    id: ''
  };

  initLoad(params) {
    super.initLoad(params);
  };

  reassignAllMdOrders = async (mdId, lockMd, justification) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: reassignAllMdOrders,
        variables: { mdId, lockMd, justification },
      });

      this.progress = false;
      Utils.toast('Orders reassigned', 'success');
    } catch (e) {
      this.progress = 0;
      Utils.toast('Error while performing action', 'error', {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  getUsers = (role) => {
    this.progress = true;
    this.assignedOrderCount = 0;
    const filter = { role_in: [role] };
    MobxApollo.graphql({
      client: this.client,
      fetchPolicy: 'network-only',
      query: users,
      variables: { first: 50, orderBy: 'firstName_ASC', skip: 0, filter },
      onFetch: (res) => {
        if (res) {
          this.userList = res.users;
        }
        this.progress = false;
      },
      onError: () => {
        Utils.toast('Error while loading assign order count.', 'error');
        this.progress = false;
      },
    });
  }

  getAssignedOrderCount = async (mdId) => {
    this.progress = true;
    this.assignedOrderCount = 0;
    const filter = { 'OR': [{ 'reviewAssignedMd': { 'id': mdId }, 'status_in': ['MD_ASSIGNED'] }, { 'releasedAssignedMd': { 'id': mdId }, 'status_in': ['MD_REVIEW'] }] };
    MobxApollo.graphql({
      client: this.client,
      fetchPolicy: 'network-only',
      query: ordersCount,
      variables: { filter },
      onFetch: (res) => {
        if (res) {
          this.assignedOrderCount = res.ordersMeta.count;
        }
        this.progress = false;
      },
      onError: () => {
        Utils.toast('Error while loading assign order count.', 'error');
        this.progress = false;
      },
    });
  };

  getUserBrands = async (userId) => {
    try {
      console.log("Inside getUserBrands store method")
      const result = await this.client.query({
        query: userBrands(),
        variables: {
          id: userId,
        },
      });
      console.log("result from userBrands", result);
      return result
    } catch (e) {
      console.log(e.message)
      return false;
    }
  }

  getUserLabs = async (userId) => {
    try {
      console.log("Inside userLabs store method")
      const result = await this.client.query({
        query: userLabs(),
        variables: {
          id: userId,
        },
      });
      console.log("result from userLabs", result);
      return result
    } catch (e) {
      console.log(e.message)
      return false;
    }
  }

  saveLicense = async (id, userId, licenseID, state) => {
    if (!state || state == '') {
      Utils.toast("Please select State", "error");
      return false;
    } else {
      const roles = document.userMetaData || [];
      const params = FormHandle.evaluateFormData(this.TEST_FRM.fields);
      let queryParams = {
        name: state,
        license: params.license,
        userId
      }
      this.newLicense = {
        name: state,
        license: params.license
      }
      this.TEST_FRM = FormHandle.resetFormData(this.TEST_FRM);
      const saveMutation = (id === 'new') ? createMDLicense : updateMdLicense;
      if (id === 'edit') {
        queryParams.id = licenseID;
      }
      try {
        const uploadResult = await this.client.mutate({
          mutation: saveMutation,
          variables: queryParams,
        });
        const createLicenseId = uploadResult.data?.createMdLicense?.id;
        const updateLicenseId = uploadResult.data?.updateMdLicense?.id;
        if (createLicenseId || updateLicenseId) {
          this.newLicense.id = createLicenseId || updateLicenseId;
        }
        Utils.toast("State License added successfully.", "success");
        return;
      } catch (e) {
        Utils.toast("Error while performing action", "error", {}, e.message);
        return false;
      } finally {
        this.currTime = +new Date();
        return;
      }
    }
  }

  saveMdPayouts = async (id, payoutDetails) => {
    const requiredFields = [
      'approvalPrice',
      'expeditedReleasePrice',
      'outreachPrice',
      'provisionalApprovalPrice',
      'releasePrice',
    ];
    for (const field of requiredFields) {
      const value = payoutDetails[field];
      const label = field.replace(/([A-Z])/g, ' $1').toLowerCase();
      if (!value || value === '') {
        Utils.toast(`Please provide ${label}`, 'error');
        return false;
      }
      if (!/^\d+$/.test(value)) {
        Utils.toast(`${label} should be a valid number`, 'error');
        return false;
      }
    }
    let queryParams = {
      id,
      payoutDetails
    }
    console.log("saveMdPayouts saveMdPayouts")
    console.log(queryParams)

    try {
      const updateMdPayoutResults = await this.client.mutate({
        mutation: updateMdPayoutDetials,
        variables: queryParams,
      });
      console.log(updateMdPayoutResults)
      Utils.toast("State License added successfully.", "success");
      return;
    } catch (e) {
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
      return;
    }

  }


  saveMdPayouts = async (id, payoutDetails) => {
    const requiredFields = [
      'approvalPrice',
      'expeditedReleasePrice',
      'outreachPrice',
      'provisionalApprovalPrice',
      'releasePrice',
    ];
    for (const field of requiredFields) {
      const value = payoutDetails[field];
      const label = field.replace(/([A-Z])/g, ' $1').toLowerCase();
      if (!value || value === '') {
        Utils.toast(`Please provide ${label}`, 'error');
        return false;
      }
      if (!/^\d+$/.test(value)) {
        Utils.toast(`${label} should be a valid number`, 'error');
        return false;
      }
    }
    let queryParams = {
      id,
      payoutDetails
    }
    console.log("saveMdPayouts saveMdPayouts")
    console.log(queryParams)

    try {
      const updateMdPayoutResults = await this.client.mutate({
        mutation: updateMdPayoutDetials,
        variables: queryParams,
      });
      console.log(updateMdPayoutResults)
      Utils.toast("State License added successfully.", "success");
      return;
    } catch (e) {
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
      return;
    }


  }

  deleteLicense = async (id, userId) => {
    this.progress = true;
    let queryParams = {
      id,
      userId
    }
    this.TEST_FRM = FormHandle.resetFormData(this.TEST_FRM);
    try {
      const uploadResult = await this.client.mutate({
        mutation: deleteMdLicense,
        variables: queryParams
      });
      if (uploadResult.data && uploadResult.data.deleteMdLicense && uploadResult.data.deleteMdLicense.id) {
        this.newLicense.id = uploadResult.data.deleteMdLicense.id;
      }
      this.progress = false;
      Utils.toast("State License deleted successfully.", "success");
      return;
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
      return;
    }
  }

  getAdminGDSURL = async () => {
    try {
      const result = await this.client.query({
        query: getAdminGDSURL()
      });
      if (result.data && result.data.getAdminGDSURL) {
        return result.data.getAdminGDSURL
      }

      return ""
    } catch (e) {
      console.log(e.message)
      return false;
    }
  }

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get useMeta() {
    return super.userMetaData;
  }

  get count() {
    return super.count();
  }
  changeAddLicense = (data) => {
    this.addLicense = !this.addLicense;
    this.progress = false;
    if (data && data.action) {
      this.testAction = data.action;
    }
    if (this.testAction === 'edit') {
      this.licenseID = data.id;
    }
    if (data) {
      this.TEST_FRM = FormHandle.setFormData(this.TEST_FRM, data);
    }
  };
  getLicenses = async (userId) => {
    try {
      // this.progress = true;
      const result = await this.client.query({
        query: userLicense(),
        variables: {
          id: userId,
        },
      });
      if (result && result.data && result.data.user && result.data.user.licensesPerState) {
        this.stateLicenses = result.data.user.licensesPerState;
      }
      this.progress = false;
      return result;
    } catch (e) {
      this.progress = false;
      console.log(e.message)
      return false;
    }
  }
}

// decorate(UsersStore, {
//   ...decorateDefault,
//   count: computed,
//   useMeta: computed,
//   loading: computed,
//   data: computed,
//   accounts: observable.ref,
//   assignedOrderCount: observable.ref,
//   userList: observable.ref,
//   initLoad: action
// })

export default new UsersStore();
