import { EMAIL, PASSWORD } from './shared';

export const LOGIN = { email: { ...EMAIL }, password: { ...PASSWORD.password } };

export const FORGOT_PASS = { email: { ...EMAIL } };

export const CONFIRM_PASS = {
  code: {
    value: '',
    error: undefined,
    label: 'ENTER VERIFICATION CODE HERE',
    rule: 'required',
  },
  ...PASSWORD
};
