import gql from 'graphql-tag';

export const dropShipments = gql`
  query dropShipments ($filter: DropShipmentFilter) {
    dropShipments(filter: $filter) {
      dropShipments {
        id
        status
        plugin
        brandId
        createdAt
        lastSyncAt
        lastUpdatedBy
        reviewFlag
        reviewMessage
        shopType
        shopOrderId
        shopOrderDetails
        shopStatusSyncAt
        shopStatusSyncBy
        shopFinancialStatus
        shopFulfillmentStatus
        shipType
        shipRequestId
        shipStatus
        shipStatusSyncAt
        shipStatusSyncBy
        shipRequestDate
        shipDate
        shipDetails
      }
      lastKey
    }
  }
`;

export const dropShipment = gql`
  query dropShipment( $id: String!){
    dropShipment ( id: $id) {
      id
      createdAt
      lastSyncAt
      brandId
      status
      plugin
      shopFinancialStatus
      shopFulfillmentStatus
      shipType
      shipStatus
      shipDetails
      shopOrderDetails
    }
  }
`;


export const dropShipmentSync = gql`
  mutation dropShipmentSync($id: String!) {
    dropShipmentSync(id: $id)
  }
`;

export const dropShipmentCancel = gql`
  mutation dropShipmentCancel($ids: [ID!]) {
    dropShipmentCancel(dropShipmentId: $ids)
  }
`;

export const dropShipmentDelete = gql`
  mutation dropShipmentDelete($id: String!) {
    dropShipmentDelete(id: $id)
  }
`;

export const dropShipmentSendShip = gql`
  mutation dropShipmentSendShip($id: String!) {
    dropShipmentSendShip(id: $id)
  }
`;

export const dropShipmentSyncShop = gql`
  mutation dropShipmentSyncShop($plugin: String!) {
    dropShipmentSyncShop(plugin: $plugin)
  }
`;

export const dropShipmentSyncShip = gql`
  mutation dropShipmentSyncShip($plugin: String!) {
    dropShipmentSyncShip(plugin: $plugin)
  }
`;
