import fetch from 'isomorphic-fetch';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { get, lowerCase } from 'lodash';
import { API_ROOT, DEPLOY_ENV } from '../constants/common'; //DEV_FEATURE_ONLY

global.fetch = fetch;

const httpLink = createHttpLink({ uri: API_ROOT });
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let jwtToken = '';
  try {
    const session = await Auth.currentSession();
    jwtToken = get(session, 'accessToken.jwtToken');
  } catch (e) {
    jwtToken = '';
  }
  return {
    headers: {
      ...headers,
      authorization: jwtToken ? `Bearer ${jwtToken}` : '',
    },
  };
});

const claimAuthLink = setContext(async (_, { headers }) => {
  const { jwtToken } = document.claimStatus;
  return {
    headers: {
      ...headers,
      authorization: jwtToken ? `Bearer ${jwtToken}` : '',
    },
  };
});

const afterWareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // const context = operation.getContext();
    // window.logger({ params: { operation, context } });
    return response;
  });
});

// const errorLink = onError((res) => {
//   const params = {
//     type: 'error',
//     body: get(res, 'networkError') || get(res, 'graphQLErrors'),
//     subject: 'Triggered by graphql client error handler',
//     action: get(res, 'operation.operationName') || 'GraphQL Client'
//   };
//   // const context = res.operation.getContext();
//   // console.log({ res, context })
//   console.log(params);
// });



// use with apollo-client
export const GqlClient = new ApolloClient({
  link: from([authLink, afterWareLink, httpLink]),
  cache: new InMemoryCache(),
  // connectToDevTools: DEV_FEATURE_ONLY,
});

export const ClaimClient = new ApolloClient({
  link: from([claimAuthLink, afterWareLink, httpLink]),
  cache: new InMemoryCache(),
  // connectToDevTools: DEV_FEATURE_ONLY,
});


export const dynamicEnvGqlApi = (param) => {
  const { environment, token } = param;
  const rootUrl = API_ROOT;
  const envApi = ['localhost'].includes(DEPLOY_ENV) ? 'dev-api' : ['master'].includes(DEPLOY_ENV) ? 'api' : `${DEPLOY_ENV}-api`;
  const resultApi = environment === 'MASTER' ? 'api' : `${lowerCase(environment)}-api`;
  const appEnvUrl = rootUrl.replace(envApi, resultApi);
  const dynamicHttpLink = createHttpLink({ uri: appEnvUrl });
  const dynamicAuthLink = setContext(async (_, { headers }) => {
    let jwtToken = '';
    try {
      jwtToken = token;
    } catch (e) {
      jwtToken = '';
    }
    return {
      headers: {
        ...headers,
        authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    };
  });

  const dynamicAfterWareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      return response;
    });
  });

  const GqlDynamicClient = new ApolloClient({
    link: from([dynamicAuthLink, dynamicAfterWareLink, dynamicHttpLink]),
    cache: new InMemoryCache(),
  });

  return GqlDynamicClient;
}


export const getData = (data, key) => data && data.loading && data.partial ?
  data.loading : get(data, key);

