import { makeObservable, observable, computed, action } from 'mobx';
import DataStore from '../shared/DataStore';
import { NEW_BRAND } from '../../../constants/brand';
import { MobxApollo, FormHandler as FormHandle, Utility as Utils } from "../../../../utils";
import {
  allBrands,
  createBrand,
  updateBrand,
  brand,
  deleteBrand,
  brands,
  getDropShipmentPlugins,
  deleteBrandSFTPCredentails,
  testConnection,
  updateGDSAnalyticsSetting,
  getGDSAnalyticsFlag,
  allBrandsForBilling,
  allMDForBilling,
  activeMDBilling,
  processBrandSFTPOrders
} from "../../queries/brands";
import { brandOrdersCount, MDOrdersAllCount, brandOrdersWithPanel } from '../../queries/orders';
import { groupBy } from 'lodash';

export class BrandsStore extends DataStore {
  constructor() {
    super(NEW_BRAND, 'brand', {
      all: allBrands,
      create: createBrand,
      update: updateBrand,
      getOne: brand,
      delete: deleteBrand
    });

    makeObservable(this, {
      count: computed,
      loading: computed,
      data: computed,
      initLoad: action,
      brandList: observable.ref,
      shipmentOptionList: observable.ref,
      orderCounts: observable.ref,
      brandsForBilling: observable.ref,
      panelsWithOrderCount: observable.ref,
      mdsForBilling: observable.ref,
      activeMDBilling: observable.ref
    });
  }

  componentDidMount() {
    const userType =
      document.userMetaData && document.userMetaData.length > 1
        ? document.userMetaData[1]
        : document.userMetaData[0];

    if (["ADMIN"].includes(userType)) {
      this.getShipmentOptionList();
    }
  }

  brandList = [];
  shipmentOptionList = [];
  orderCounts = null;
  panelsWithOrderCount = [];
  brandsForBilling = [];
  mdsForBilling = [];
  activeMDBilling = {};
  gdsAnalyticsFlag = false;
  createdOrdersCount = 0;
  cancelledOrdersCount = 0;
  outreachOrdersCount = 0;

  initLoad(params) {
    super.initLoad(params);
  }

  checkAndCallDropShipment = () => {
    const userType =
      document.userMetaData && document.userMetaData.length > 1
        ? document.userMetaData[1]
        : document.userMetaData[0];

    if (["ADMIN"].includes(userType)) {
      this.getShipmentOptionList();
    }
  }

  testSFTPConnection = async (brandId, connectionType) => {
    this.progress = true;
    const roles = document.userMetaData || [];
    try {
      await this.client.mutate({
        mutation: testConnection(),
        variables: {
          id: brandId,
          connectionType: connectionType
        },
        refetchQueries: [
          {
            query: typeof brand === 'function' ? brand(roles.includes('ADMIN')) : brand,
            variables: { id: this.entityDetails.id },
          },
        ],
      });
      this.progress = false;
      Utils.toast(
        `SFTP connection tested successfully.`,
        'success'
      );
    } catch (e) {
      Utils.toast(
        `Error while testing SFTP connection`,
        'error'
      );
    }
  }

  deleteSFTPCreds = async (brandId) => {
    this.progress = true;
    const roles = document.userMetaData || [];
    try {
      await this.client.mutate({
        mutation: deleteBrandSFTPCredentails(),
        variables: {
          id: brandId
        },
        refetchQueries: [
          {
            query: typeof brand === 'function' ? brand(roles.includes('ADMIN')) : brand,
            variables: { id: this.entityDetails.id },
          },
        ],
      });
      this.progress = false;
      Utils.toast(
        `SFTP Credentails deleted successfully.`,
        'success'
      );
    } catch (e) {
      Utils.toast(
        `Error while deleting SFTP Credentails`,
        'error'
      );
    }
  }

  getShipmentOptionList = () => {
    MobxApollo.graphql({
      client: this.client,
      query: getDropShipmentPlugins,
      fetchPolicy: "network-only",
      variables: {},
      onError: (err) => {
        console.error("onError=>", err);
      },
      onFetch: (data) => {
        if (data) {
          this.shipmentOptionList = data['getDropShipmentPlugins'];
        }
      },
    });
  };

  getBrandList = () => {
    MobxApollo.graphql({
      client: this.client,
      query: brands,
      fetchPolicy: 'network-only',
      variables: {
        first: this.params.perPage,
        skip: 0,
        orderBy: 'name_ASC',
        filter: [],
      },
      onError: (err) => {
        console.error('onError=>', err);
      },
      onFetch: (data) => {
        if (data) {
          this.brandList = data['brands'];
        }
      },
    });
  };


  getGDSAnalyticsUserSetting = async (pocEmailID) => {
    try {
      const result = await this.client.query({
        query: getGDSAnalyticsFlag(),
        variables: {
          email: pocEmailID,
        },
      });
      console.log("result from the getGDSAnalyticsUserSetting2");
      if (result.data && result.data.getGDSAnalyticsUserSetting) {
        return result.data.getGDSAnalyticsUserSetting.showGDSAnalytics
      }
      return false;
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return !showGDSAnalyticsFlag;
    }
  }

  updateGDSAnalyticsSetting = async (pocEmailId, showGDSAnalyticsFlag) => {
    this.progress = true;
    try {
      await this.client.mutate({
        mutation: updateGDSAnalyticsSetting(),
        variables: {
          email: pocEmailId,
          showGDSAnalytics: showGDSAnalyticsFlag
        },
      });
      this.progress = false;
      Utils.toast("GDS Analytics setting saved successfully", "success");
      return showGDSAnalyticsFlag;
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return !showGDSAnalyticsFlag;
    }
  }

  getBrandOrders = async (brandId, startDate, endDate) => {
    const filter = { brand: { id: brandId } };
    const createdFilter = { ...filter, createdAt_gte: startDate, createdAt_lte: endDate };
    const cancelledFilter = {
      ...filter,
      activity_some: { type: 'CANCELED', createdAt_gte: startDate, createdAt_lte: endDate },
    };
    const outreachFilter = {
      ...filter,
      activity_some: {
        type_in: ['OUTREACH_SUCCESSFUL_INPUT', 'OUTREACH_UNSUCCESSFUL_INPUT'],
        createdAt_gte: startDate,
        createdAt_lte: endDate,
      },
    };
    const fullfilmentFilter = {
      createdAtFromDate: startDate,
      createdAtToDate: endDate,
      brandId,
      status: 'COMPLETED'
    };

    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: brandOrdersCount,
        variables: { createdFilter, cancelledFilter, outreachFilter, fullfilmentFilter },
        onFetch: (res) => {
          if (res) {
            this.orderCounts = res;
            resolve(res); // Resolve the promise with the result
          }
          this.progress = false;
        },
        onError: (error) => {
          Utils.toast('Error while loading order counts.', 'error');
          this.progress = false;
          reject(error); // Reject the promise with the error
        },
      });
    });
  };

  getAllBrandsForBilling = async (query) => {
    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: allBrandsForBilling,
        variables: query,
        onFetch: (res) => {
          if (res && res.brands) {
            this.brandsForBilling = res.brands || [];
            resolve(res.brands || []); // Resolve the promise with the result
          }
          this.progress = false;
        },
        onError: (error) => {
          Utils.toast('Error while loading order counts.', 'error');
          this.progress = false;
          reject(error); // Reject the promise with the error
        },
      });
    });
  };

  getAllMDForBilling = async (query) => {
    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: allMDForBilling,
        variables: query,
        onFetch: (res) => {
          if (res && res.users) {
            this.mdsForBilling = res.users || [];
            resolve(res.users || []); // Resolve the promise with the result
          }
          this.progress = false;
        },
        onError: (error) => {
          Utils.toast('Error while loading order counts.', 'error');
          this.progress = false;
          reject(error); // Reject the promise with the error
        },
      });
    });
  };

  getMDOrdersCount = async (approveFilter, releaseFilter, outreachFilter, expeditedReleaseFilter, provisionalApprovalFilter, unsuccessFullOutreachFilter) => {
    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: MDOrdersAllCount,
        variables: { approveFilter, releaseFilter, outreachFilter, expeditedReleaseFilter,  provisionalApprovalFilter, unsuccessFullOutreachFilter},
        onFetch: (res) => {
          if (res) {
            this.orderCounts = res;
            resolve(res); // Resolve the promise with the result
          }
          this.progress = false;
        },
        onError: (error) => {
          Utils.toast('Error while loading order counts.', 'error');
          this.progress = false;
          reject(error); // Reject the promise with the error
        },
      });
    });
  };

  getActiveMDBilling = async (query) => {
    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: activeMDBilling,
        variables: query,
        onFetch: (res) => {
          console.log(res)
          if (res && res.activeMDBilling) {
            this.activeMDBilling = res.activeMDBilling[0] || {};
            resolve(res.activeMDBilling[0] || {}); // Resolve the promise with the result
          }
          this.progress = false;
        },
        onError: (error) => {
          Utils.toast('Error while loading all active MD billing.', 'error');
          this.progress = false;
          reject(error); // Reject the promise with the error
        },
      });
    });
  };

  getBrandOrdersWithPanel = async (brandId, startDate, endDate) => {
    const filter = { brand: { id: brandId }, createdAt_gte: startDate, createdAt_lte: endDate, panel: { ordrsLabPanel: true } };
    this.panelsWithOrderCount = [];
    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: brandOrdersWithPanel,
        variables: { filter },
        onFetch: (res) => {
          if (res) {
            const panels = groupBy(res.orders, (order) => order.panel.id);
            for (const panelId in panels) {
              const orders = panels[panelId];
              const panel = orders[0].panel;
              const panelPrice = panel.ordrsLabPanelDropdown === 'TESTCODEPRICE' ? (
                panel.labTests.reduce((acc, labtest) => {
                  return acc += labtest.testPrice;
                }, 0)
              ) : panel.panelPrice;

              const panelOrders = {
                panelName: panel.name,
                panelPrice,
                ordersCount: orders.length,
                totalPrice: (panelPrice * orders.length)
              };

              this.panelsWithOrderCount.push(panelOrders);
            }
            resolve(res); // Resolve the promise with the result
          }
          this.progress = false;
        },
        onError: (error) => {
          Utils.toast('Error while loading barnd orders with panel.', 'error');
          this.progress = false;
          reject(error); // Reject the promise with the error
        },
      });
    });
  };

  processBrandSFTPOrders = async (brandId, operation) => {
    this.progress = true;
    try {
      const { data } = await this.client.mutate({
        mutation: processBrandSFTPOrders(),
        variables: {
          brandId,
          operation
        }
      });
      this.progress = false;
      Utils.toast(
        data.processBrandSFTPOrders,
        'success'
      );
    } catch (e) {
      Utils.toast(
        data.processBrandSFTPOrders,
        'error'
      );
    }
  }

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

}

export default new BrandsStore();
