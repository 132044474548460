import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { isEmpty } from 'lodash'

const Actions = props => (
  !isEmpty(props.items) ?
    props.isMobile ?
      <Dropdown icon='ellipsis vertical' loading={props.loading} disabled={props.disabled}>
        <Dropdown.Menu>
          {Object.keys(props.items).map(i => (
            <Dropdown.Item className={`action_${Array.isArray(props.items[i]) ? props.items[i][0] : props.items[i]}`} onClick={() => props.trigger(i)} key={i}>
              {Array.isArray(props.items[i]) ? props.items[i][0] : props.items[i]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      :
      <Dropdown text='Actions' icon='dropdown' loading={props.loading} disabled={props.disabled} floating button className='right labeled icon primary action-btn-fixed'>
        <Dropdown.Menu>
          {Object.keys(props.items).map(i => (
            <Dropdown.Item className={`action_${Array.isArray(props.items[i]) ? props.items[i][0] : props.items[i]}`} onClick={() => props.trigger(i)} key={i}>
              {Array.isArray(props.items[i]) ? props.items[i][0] : props.items[i]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    : null
);

export default Actions;
