import gql from 'graphql-tag';

const commonCreateUpdate = {
  p1: `
    $panelId: ID!,
    $patient: PatientInputType!,
    $orderId: String,
    $context: String,
    $labTestIds: [ID]
  `,
  p2: `
    panelId: $panelId,
    patient: $patient,
    orderId: $orderId,
    context: $context,
    labTestIds: $labTestIds
  `
};

export const allOrders = gql`
  query allOrders($first: Int, $skip: Int, $orderBy: OrderOrderBy, $filter: OrderFilter) {
    orders(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      id
      orderId
      panel {
        id
        name
        brand {
          id
          name
        }
        lab {
          id
          name
        }
      }
      patient {
        firstName
        lastName
        gender
        dob
        address {
          state
        }
      }
      activity(orderBy: createdAt_DESC){
        type
        createdAt
      }
      reviewAssignedMd {
        id
        firstName
        lastName
      }
      releasedAssignedMd {
        id
        firstName
        lastName
      }
      isFlagged
      outreach
      outreachStatus
      status
      createdAt
    }
    ordersMeta(filter: $filter) {
      count
    }
  }
`;

export const summaryAllOrdersList = gql`
  query allOrders($orderBy: OrderOrderBy, $filter: OrderFilter) {
    orders(orderBy: $orderBy, filter: $filter) {
      id
      orderId
      panel {
        id
        name
        prefix
        brand {
          id
          name
        }
        lab {
          id
          name
        }
      }
      patient {
        firstName
        lastName
      }
      reviewAssignedMd {
        id
        firstName
        lastName
      }
      status
      createdAt
      updatedAt
      mdReviewDate
      mdReleaseDate
    }
  }
`;

export const summaryAllOrdersCount = gql`
  query allOrders($orderBy: OrderOrderBy, $filter: OrderFilter) {
    orders(orderBy: $orderBy, filter: $filter) {
      id
      panel {
        id
        name
        brand {
          id
          name
        }
        lab {
          id
          name
        }
      }
      status
    }
  }
`;

export const ordersCount = gql`
  query ordersMeta($filter: OrderFilter) {
    ordersMeta(filter: $filter) {
      count
    }
  }
`;

export const mdOrdersCount = gql`
  query ordersMeta($filter: OrderFilter) {
    ordersMeta(filter: $filter) {
      count
    }
  }
`;

export const getEligibleMdForAssignment = gql`
query getEligibleMdForAssignment($orderId: ID!) {
  getEligibleMdForAssignment(orderId: $orderId) {
    id
    npi
    firstName
    lastName
  }
}
`;

export const ordersDownload = gql`
  query downloadOrders($orderBy: OrderOrderBy, $filter: OrderFilter) {
    orders(orderBy: $orderBy, filter: $filter) {
      id
      orderId
      outreach
      status
      createdAt
      panel {
        id
        name
        prefix
      }
      markers {
        name
        markerRef
        markerDesc
        resultCode
        reading
      }
      reviewAssignedMd {
        id
        firstName
        lastName
        npi
      }
      releasedAssignedMd {
        id
        firstName
        lastName
        npi
      }
      lab {
        id
        name
        address {
          addressLine1
          addressLine2
          city
          state
          zip
        }
        poc {
          user {
            id
            firstName
            lastName
          }
        }
      }
      patient {
        firstName
        lastName
        gender
        phone
        email
        dob
        address {
          addressLine1
          addressLine2
          city
          state
          zip
        }
      }
    }
  }
`;

export const checkMdOrders = gql`
query allOrders($first: Int, $filter: OrderFilter){
  orders(first: $first, filter: $filter){
    id
    orderId
    status
  }
}
`;

export const adminOrdersSmartSearch = gql`
query adminOrdersSmartSearch($ref: String!, $first: Int, $skip: Int){
  adminOrdersSmartSearch(ref: $ref, first: $first, skip: $skip) {
    id
    orderId
    panel {
      id
      name
      brand {
        id
        name
      }
      lab {
        id
        name
      }
    }
    patient {
      firstName
      lastName
      gender
      dob
      address {
        state
      }
    }
    statusActivity {
      createdAt
    }
    activity(orderBy: createdAt_DESC){
      type
      createdAt
    }
    reviewAssignedMd {
      id
      firstName
      lastName
    }
    releasedAssignedMd {
      id
      firstName
      lastName
    }
    isFlagged
    outreach
    outreachStatus
    flagActivity {
      createdAt
    }
    status
    createdAt
  }
}
`;

export const assignMdToOrder = gql`
  mutation assignMdToOrder($mdId: ID!, $orderId: ID!, $justification: String) {
    assignMdToOrder(mdId: $mdId, orderId: $orderId, justification: $justification) {
      id
      reviewAssignedMd {
        id
        firstName
        lastName
        email
      }
      releasedAssignedMd {
        id
        firstName
        lastName
        email
      }
      patient {
        firstName
      }
      activity(orderBy: createdAt_DESC){
        type
        activity
        justification
      }
    }
  }
`;

export const reassignAllMdOrders = gql`
  mutation reassignAllMdOrders($mdId: ID!, $lockMd: Boolean, $justification: String) {
    reassignAllMdOrders(mdId: $mdId, lockMd: $lockMd, justification: $justification) {
      id
    }
  }
`;

export const createOrder = gql`
  mutation createOrder(${commonCreateUpdate.p1}) {
    createOrder(${commonCreateUpdate.p2}) {
      id
    }
  }
`;

export const updateOrder = gql`
  mutation updateOrder($id: ID!, $markers: [OrderMarkerUpdateInputType!]!) {
    updateOrder(id: $id, markers: $markers) {
      id
    }
  }
`;

const adminParams = `
  id
  labOrderId
  labOrderStatus
  labOrderSyncAt
  labOrderSyncBy
  context
`;

export const order = (isAdmin = false) => {
  return gql`
  query order($id: ID!) {
    order(id: $id) {
      orderId
      isFlagged
      outreach
      outreachStatus
      ${isAdmin ? adminParams : `id`}
      labOrderRequisitionPdf      
      brand {
        id
        name
      }
      panel {
        id
        name
        version
        labTests {
          id name
          results {
            id name
          }
        }
      }
      lab {
        id
        name
        address {
          addressLine1
          city
          state
          zip
        }
      }
      patient {
        firstName
        lastName
        gender
        email
        dob
        phone
        address {
          addressLine1
          city
          state
          zip
        }
      }
      markers {
        name
        rangeRef
        markerRef
        markerDesc
        resultCode
        markerNote
        reading
        specimen
        units
        isCritical
        isReportable
        isThreshold
        rangeType
        ranges {
          name
          type
          value
          isCritical
          isReportable
          isThreshold
          rangeType
        }
      }
      statusActivity {
        activity
        createdAt
      }
      reviewAssignedMd {
        id
      }
      releasedAssignedMd {
        id
      }
      status
      panelAttachmentsInfo {
        fileId
        fileName
        contentType
        isUploaded
        deletedAt
        createdAt
      }
      activity {
        activity
        type
        justification
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`;
}

export const orderResultsPDF = gql`
  query orderResultsPDF($id: ID!) {
    order(id: $id) {
      orderId
      resultsPdfAttachmentsInfo {
        fileId
        fileName
        createdAt
      }
    }
  }
`;

export const orderActivitiesQry = gql`
  query orderActivitiesQry($id: ID!, $first: Int, $skip: Int) {
    order(id: $id) {
      orderId
      activity(first: $first, skip: $skip) {
        activity
        type
        justification
        activityData
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const mdReview = gql`
  mutation mdReview($id: ID, $status: MdReviewOrderStatusEnum!, $justification: String) {
    mdReview( id: $id, status: $status, justification: $justification) {
      id
      status
      justification
      reviewAssignedMd {
        id
      }
    }
  }
`;

const mdApproveAll = gql`
  mutation mdApproveAll($approveAll: Boolean, $orderId: [String]) {
    mdApproveAll( approveAll: $approveAll, orderId: $orderId) {
      id
    }
  }
`;

const attachFile = gql`
  mutation attachFileToOrder($id: ID!, $resultsPdf: ID!) {
    attachFileToOrder( id: $id, resultsPdf: $resultsPdf) {
      id
    }
  }
`;

const attachFileUploadToOrder = gql`
  mutation attachFileUploadToOrder($orderId: String!, $fileId: ID!) {
    attachFileUploadToOrder( orderId: $orderId, fileId: $fileId) {
      success
    }
  }
`;

const labAccept = gql`
  mutation labAccept($id: ID) {
    labAccept(id: $id) {
      id
      status
      reviewAssignedMd {
        id
      }
    }
  }
`;

const labRelease = gql`
  mutation labRelease($id: ID, $justification: String) {
    labRelease(id: $id, justification: $justification) {
      id
      status
      reviewAssignedMd {
        id
      }
    }
  }
`;

const mdClaim = gql`
  mutation mdClaim($id: ID) {
    mdClaim(id: $id) {
      id
      status
      reviewAssignedMd {
        id
      }
      releasedAssignedMd {
        id
      }
    }
  }
`;

const mdRelease = gql`
  mutation mdRelease($id: ID, $status: MDReleaseOrderStatusEnum!) {
    mdRelease(id: $id, status: $status) {
      id
      status
      reviewAssignedMd {
        id
      }
      releasedAssignedMd {
        id
      }
    }
  }
`;

const adminExpeditedRelease = gql`
  mutation adminExpeditedRelease($id: ID) {
    adminExpeditedRelease(id: $id) {
      id
      status
      reviewAssignedMd {
        id
      }
      releasedAssignedMd {
        id
      }
    }
  }
`;

const setFlagByOrderId = gql`
  mutation setFlagByOrderId($id: ID!, $status: OrderFlaggedStatusEnum!, $justification: String) {
    setFlagByOrderId(id: $id, status: $status, justification: $justification) {
      id
      isFlagged
      justification
    }
  }
`;

const cancelOrder = gql`
  mutation cancelOrder($id: ID!, $justification: String) {
    cancelOrder(id: $id, justification: $justification) {
      id
      justification
    }
  }
`;

const labDecline = gql`
  mutation labDecline($id: ID!, $justification: String) {
    labDecline(id: $id, justification: $justification) {
      id
      justification
    }
  }
`;

export const comment = gql`
  mutation createOrderComment($orderId: ID!, $scope: [ActivityScopeEnum!]!, $activity: String!) {
    createOrderComment(orderId: $orderId, scope: $scope, activity: $activity) {
      id
    }
  }
`;

export const createBulkOrder = gql`
  mutation createBulkOrder($orders: [BulkOrderInputType!]!) {
    createBulkOrder(orders: $orders) {
      summary {
        ERROR
        CREATED
      }
      orders {
        orderId
        panelPrefix
        firstName
        lastName
        gender
        phone
        email
        dob
        addressLine1
        addressLine2
        city
        state
        zip
        status
        message
      }
    }
  }
`;

const mdOutreachAttempt = gql`
  mutation mdOutreachAttempt($id: ID!, $justification: String, $status: String) {
    mdOutreachAttempt(id: $id, justification: $justification, status: $status) {
      id
      activity(orderBy: createdAt_DESC){
        type
        activity
        justification
      }
    }
  }
`;

export const editOrderCustomer = gql`
  mutation editOrderCustomer($id: ID!, $panelId: ID!, $gender: GenderEnum!, $firstName: String!, $lastName: String!, $dob: String!, $phone: String!, $address: PatientAddressInputType!) {
    editOrderCustomer(id: $id, panelId: $panelId, gender: $gender, firstName: $firstName, lastName: $lastName, dob: $dob, phone: $phone, address: $address) {
      id
      patient{
        firstName,
        lastName,
        gender,
        dob,
        phone,
        address{
          addressLine1,
          addressLine2,
          city,
          state,
          zip
        }
      }
    }
  }
`;

export const brandOrdersCount = gql`
  query brandOrdersMeta($createdFilter: OrderFilter, $cancelledFilter: OrderFilter, $outreachFilter: OrderFilter, $fullfilmentFilter: DropShipmentFilter) {
    createdOrders: ordersMeta(filter: $createdFilter) {
      count
    }
    cancelledOrders: ordersMeta(filter: $cancelledFilter) {
      count
    }
    outreachOrders: ordersMeta(filter: $outreachFilter) {
      count
    }
    fullfilmentOrders: dropShipments(filter: $fullfilmentFilter) { 
      dropShipments { 
        id status plugin brandId createdAt lastSyncAt lastUpdatedBy reviewFlag reviewMessage shopType shopOrderId shopOrderDetails shopStatusSyncAt shopStatusSyncBy shopFinancialStatus shopFulfillmentStatus shipType shipRequestId shipStatus shipStatusSyncAt shipStatusSyncBy shipRequestDate shipDate shipDetails
      } lastKey
    }
  }
`;

export const MDOrdersAllCount = gql`
  query brandOrdersMeta($approveFilter: OrderFilter, $releaseFilter: OrderFilter, $outreachFilter: OrderFilter, $expeditedReleaseFilter: OrderFilter, $provisionalApprovalFilter: OrderFilter, $unsuccessFullOutreachFilter: OrderFilter) {
    approveCount: ordersMeta(filter: $approveFilter) {
      count
    }
    releaseCount: ordersMeta(filter: $releaseFilter) {
      count
    }
    outreachCount: ordersMeta(filter: $outreachFilter) {
      count
    }
    expeditedReleaseCount: ordersMeta(filter: $expeditedReleaseFilter) {
      count
    }
    provisionalApprovalCount: ordersMeta(filter: $provisionalApprovalFilter) {
      count
    }
    unsuccessfullOutreachCount: ordersMeta(filter: $unsuccessFullOutreachFilter) {
      count
    }
  }
`;

export const brandOrdersWithPanel = gql`
  query orders($filter: OrderFilter) {
    orders(filter: $filter) {
      id
      orderId
      panel {
        id
        name
        brand {
          id
          name
        }
        lab {
          id
          name
        }
        panelPrice
        ordrsLabPanel
        ordrsLabPanelDropdown
        labTests {
          id
          name
          testPrice
        }
      }
    }
  }
`;

export const syncPluginOrder = gql`
  mutation 
  syncPluginOrder($id: ID!) {
    syncPluginOrder(id: $id)
  }
`;

export const syncOrderMarkersWithPanel = gql`
  mutation 
  syncOrderMarkersWithPanel($orderId: String) {
    syncOrderMarkersWithPanel(orderId: $orderId) {
      id
    }
  }
`;

export const adminProcessCreatedStateOrder = gql`
  mutation 
  adminProcessCreatedStateOrder($id: ID!) {
    adminProcessCreatedStateOrder(id: $id)
  }
`;

export const adminProcessLabReleaseToMDReview = gql`
  mutation 
  adminProcessLabReleaseToMDReview($id: ID!) {
    adminProcessLabReleaseToMDReview(id: $id)
  }
`;

export const correctMarkers = gql`
  mutation correctMarkers($id: ID, $orderId: String) {
    correctMarkers(id: $id, orderId: $orderId)
  }
`;

export const orderAction = {
  mdApproveAll,
  mdRelease,
  mdClaim,
  labRelease,
  labDecline,
  labAccept,
  mdReview,
  cancelOrder,
  setFlagByOrderId,
  attachFile,
  attachFileUploadToOrder,
  assignMdToOrder,
  mdOutreachAttempt,
  mdOrdersCount,
  adminExpeditedRelease,
  correctMarkers 
};