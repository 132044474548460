import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { FieldError } from '../../shared';

const maskMap = { phone: '(###) ###-####', date: '##/##/####' };

class MaskedInput extends Component {
  state = { showError: false };
  triggerError = (val) => {
    this.setState({ showError: val });
  }
  render() {
    const { props } = this;
    const {
      label,
      error,
      value,
      placeHolder,
    } = props.fielddata || props;
    const { displayMode, readOnly } = props;
    const fieldClass = `${props.containerclassname || ''} ${displayMode ? 'display-only' : ''}`;

    return (
      <Form.Field
        error={(!!error && this.state.showError) || (!!error && props.showerror)}
        className={fieldClass}
        width={props.containerwidth || false}
      >
        {!props.hidelabel &&
          <label>{props.label || label}</label>
        }
        <NumberFormat
          readOnly={readOnly}
          type="text"
          format={props.as ? maskMap[props.as] : props.format }
          placeholder={(readOnly) ? 'N/A' : placeHolder}
          {...props}
          value={value}
          onValueChange={(values) => { props.changed && props.changed(values, props.name); this.triggerError(props.showerror || false); }} onBlur={() => this.triggerError(true)} error={(!!error && this.state.showError) || (!!error && props.showerror)}
        />
        {((error && this.state.showError && !props.showErrorOnField) ||
        (error && props.showerror && !props.showErrorOnField))
         &&
         <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default observer(MaskedInput);