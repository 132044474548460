import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { DEPLOY_ENV } from './constants/common';
import App from './App';
import * as stores from './services/stores';
import { ErrorBoundary } from './components/layout';
import * as serviceWorker from './serviceWorker';

if (DEPLOY_ENV === 'localhost') {
  window.APP_STATE = stores;
}

ReactDOM.render(
  <ErrorBoundary>
    <Provider {...stores}>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
