import { makeObservable, observable, computed, action, toJS } from "mobx";
import { mapValues } from "lodash";
import DataStore from "../shared/DataStore";
import LabsStore from "../labs";
import {
  uniqueMarkers,
} from "../../queries/markers";
import {
  lab,
  createLabTest,
  updateLabTest,
  bulkUploadLabTests,
  downloadLabTests,
  deleteManyLabTests
} from "../../queries/labs";
import {
  panel
} from "../../queries/panels";
import { panelAttachLabTests, updatePanelLabTests } from "../../queries/panels";
import { NEW_TEST, LINK_TESTS } from "../../../constants/lab";
import {
  MobxApollo,
  FormHandler as FormHandle,
  Utility as Utils,
} from "../../../../utils";
export class TestsStore extends DataStore {
  constructor() {
    super(null, "uniquePanelMarker", {
      all: uniqueMarkers,
    });

    makeObservable(this, {
      tests: observable.ref,
      progress: observable.ref,
      downloadResult: observable.ref,
      testAction: observable.ref,
      TEST_FRM: observable.ref,
      PANEL_LINK_TEST_FRM: observable.ref,
      addTest: observable.ref,
      uploadTest: observable.ref,
      linkResult: observable.ref,
      count: computed,
      loading: computed,
      data: computed,
      upload: action,
      initLoad: action,
      save: action,
      savePanelTests: action,
      bulkImportTests: action
    });
  }

  TEST_FRM = FormHandle.prepareForm(NEW_TEST);
  PANEL_LINK_TEST_FRM = FormHandle.prepareForm(LINK_TESTS);
  downloadResult = [];
  progress = false;
  addTest = false;
  uploadTest = false;
  linkResult = false;
  tests = [];
  testAction = "new";

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

  savePanelTests = async (id) => {
    this.progress = true;
    const labDetails = LabsStore.labDetails;
    const params = FormHandle.evaluateFormData(this.PANEL_LINK_TEST_FRM.fields);
    const roles = document.userMetaData || [];
    try {
      await this.client.mutate({
        mutation: updatePanelLabTests,
        variables: { labTestIds: params.tests, panelId: id },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
          {
            query: typeof panel === "function"
              ? panel(roles.includes("ADMIN"))
              : panel,
            variables: { id },
          },
        ],
      });

      this.progress = false;
      Utils.toast("Test added/updated successfully.", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.toggleLinkResult();
    }
  }

  save = async (id) => {
    this.progress = true;
    const labDetails = LabsStore.labDetails;
    const roles = document.userMetaData || [];
    const params = FormHandle.evaluateFormData(this.TEST_FRM.fields);
    console.log('before', this.tests);
    this.tests = [...this.tests, { id: this.tests.length, ...params }];
    console.log('after', this.tests);
    // this.changeAddTest();
    this.TEST_FRM = FormHandle.resetFormData(this.TEST_FRM);
    const saveMutation = (id === 'new') ? createLabTest : updateLabTest;
    if (id !== 'new') {
      params.labTestId = id;
    }
    if (params.testPrice === '') {
      params.testPrice = 0;
    }
    params.results = params.results.filter((item) => item !== null && item !== undefined)
    console.log('params ===>>>>> ', params);
    try {
      await this.client.mutate({
        mutation: saveMutation,
        variables: { ...params, labId: labDetails.id },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
        ],
      });

      this.progress = false;
      Utils.toast("Test added/updated successfully.", "success");
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  }

  bulkImportTests = async (orderTests) => {
    const labDetails = LabsStore.labDetails;
    this.progress = true;
    try {
      const roles = document.userMetaData || [];
      const res = await this.client.mutate({
        mutation: bulkUploadLabTests,
        variables: { labId: labDetails.id, testsData: orderTests },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
        ],
      });
      this.progress = false;
      if (res && res.data && res.data.uploadBulkTests) {
        if(res.data.uploadBulkTests.error === "0") {
          Utils.toast(
            'Test bulk upload operation completed, please review the generated result for details.',
            'success'
          );
        } else {
          let errorMessage = 'Error while uploadig tests- ' + res.data.uploadBulkTests.message
          Utils.toast(errorMessage, 'error');
        }
      }
      return true;
    } catch (e) {
      this.progress = 0;
      console.log(e);
      Utils.toast('Error while uploadig tests', 'error');
      return false;
    } finally {
      this.currTime = +new Date();
    }
  };

  bulkImportTests = async (orderTests) => {
    const labDetails = LabsStore.labDetails;
    this.progress = true;
    try {
      const roles = document.userMetaData || [];
      const res = await this.client.mutate({
        mutation: bulkUploadLabTests,
        variables: { labId: labDetails.id, testsData: orderTests },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
        ],
      });
      this.progress = false;
      if (res && res.data && res.data.uploadBulkTests) {
        if(res.data.uploadBulkTests.error === "0") {
          Utils.toast(
            'Test bulk upload operation completed, please review the generated result for details.',
            'success'
          );
        } else {
          let errorMessage = 'Error while uploadig tests- ' + res.data.uploadBulkTests.message
          Utils.toast(errorMessage, 'error');
        }
      }
      return true;
    } catch (e) {
      this.progress = 0;
      console.log(e);
      Utils.toast('Error while uploadig tests', 'error');
      return false;
    } finally {
      this.currTime = +new Date();
    }
  };

  changeAddTest = (data) => {
    this.addTest = !this.addTest;
    this.progress = false;
    this.testAction = data?.action === 'edit' ? data?.id : data?.action;
    if (data) {
      data.results = (data.results && data.results.length > 0) ? data.results.map(r => r.id) : [];
      this.TEST_FRM = FormHandle.setFormData(this.TEST_FRM, data);
      console.log('this.TEST_FRM', this.TEST_FRM);
    }
  };

  changeUploadTests = () => {
    this.uploadTest = !this.uploadTest;
    this.progress = false;
    console.log("calling toggle upload")
  };

  changeAddPanelTest = (data) => {
    this.linkResult = !this.linkResult;
    this.progress = false;
    this.testAction = data?.action === 'edit' ? data?.id : data?.action;
    if (data) {
      data.results = (data.results && data.results.length > 0) ? data.results.map(r => r.id) : [];
      this.PANEL_LINK_TEST_FRM = FormHandle.setFormData(this.PANEL_LINK_TEST_FRM, data);
    }
  }

  initPanelTest = (data) => {
    if (data) {
      data.tests = (data.tests && data.tests.length > 0) ? data.tests.map(r => r.id) : [];
      this.PANEL_LINK_TEST_FRM = FormHandle.setFormData(this.PANEL_LINK_TEST_FRM, data);
    }
  }

  toggleLinkResult = (data) => {
    this.linkResult = !this.linkResult;
  }
  downloadLabTests = async (ids) => {
    try {
      const result = await this.client.query({
        query: downloadLabTests,
        variables: {
          ids,
        },
      });
      if(result && result.data && result.data.downloadLabTests) {
        return result.data.downloadLabTests;
      } else {
        Utils.toast("No data found for the selected labs", "error");
      }
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
    }
  }
  deleteManyLabTests = async (ids) => {
    try {
      const labDetails = LabsStore.labDetails;
      const roles = document.userMetaData || [];
      await this.client.mutate({
        mutation: deleteManyLabTests,
        variables: { ids },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
        ],
      });
      Utils.toast(
        'Tests removed successfully.',
        'success'
      );
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error removing tests", "error", {}, e.message);
    }
  }
}

export default new TestsStore();
