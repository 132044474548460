import gql from 'graphql-tag';
import { address, brands, labs} from '../common';

const commonProps = `
  id
  createdAt
  firstName
  lastName
  email
  phone
  role
  userType
  status
  lastLogin
  mdType
`;

const commonAdminProps = `
  id
  createdAt
  firstName
  lastName
  email
  phone
  role
  userType
  status
  lastLogin
  approvalPrice
  releasePrice
  outreachPrice
  expeditedReleasePrice
  provisionalApprovalPrice
  portalUsers {
    id
    firstName
    lastName
    address {
      addressLine1
      addressLine2
      city
      state
      zip
    }
    brand {
      id
      name
    }
    orders {
      id
      orderId
      status
      createdAt
    }
  }
  mdType
`;

const superAdminOnlyLicensesState = `
  licenses
  licensesPerState{
    id
    name
    reviewCount
    approveCount
    license
  }
  reviewCount
  releaseCount
  approvalPrice
  releasePrice
  outreachPrice
  expeditedReleasePrice
  provisionalApprovalPrice
`;

const adminOnlyCommonLicenses = `
  licenses
`;

export const payoutDetails = `
  payoutDetails{
      approvalPrice
      releasePrice
      outreachPrice
      expeditedReleasePrice
      provisionalApprovalPrice
    }
`;

export const updateMdPayout = {
  a1: `
    $payoutDetails: PayoutDetailsInputType,
  `,
  a2: `
    payoutDetails: $payoutDetails,
  `,
};

const commonCreateUpdate = {
  p1: `
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $phone: String,
    $gender: GenderEnum,
    $dob: String,
    $address: AddressInputType,
    $npi: String,
    $mdType: MdTypeEnum,
    $externalId: String
  `,
  p2: `
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    phone: $phone,
    gender: $gender,
    dob: $dob,
    npi: $npi,
    mdType: $mdType,
    address: $address,
    externalId: $externalId
  `
};

export const allUsers = (isAdmin = false) => {
  return gql`
  query allUsers($first: Int, $skip: Int, $orderBy: UserOrderBy, $filter: UserFilter) {
    users(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      ${isAdmin ? commonAdminProps : commonProps}
    }
    usersMeta(filter: $filter) {
      count
    }
  }
`;
}

export const users = gql`
  query users($first: Int, $skip: Int, $orderBy: UserOrderBy, $filter: UserFilter) {
    users(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      ${commonProps}
    }
  }
`;

export const createUser = gql`
  mutation createUser(${commonCreateUpdate.p1}, $password: String!, $userType: UserTypeEnum, $role: UserRoleEnum!) {
    createUser(${commonCreateUpdate.p2}, password: $password, userType: $userType, role: $role) {
      id
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($id: ID!, ${commonCreateUpdate.p1}) {
    updateUser(id: $id, ${commonCreateUpdate.p2}) {
      id
    }
  }
`;

export const updateMdPayoutDetials = gql`
  mutation updateMdPayoutDetials($id: ID!, ${updateMdPayout.a1}) {
    updateMdPayoutDetials(id: $id, ${updateMdPayout.a2}) {
      id
    }
  }
`;

export const createApiUser = gql`
  mutation createApiUser($name: String!, $userType: UserTypeEnum!, $whitelistIp: [String!]!) {
    createApiUser(name: $name, userType: $userType, whitelistIp: $whitelistIp) {
      id
    }
  }
`;

export const updateApiUser = gql`
  mutation updateApiUser($id: ID!, $whitelistIp: [String!]!) {
    updateApiUser(id: $id, whitelistIp: $whitelistIp) {
      id
    }
  }
`;

export const user = (isAdmin = false, isSuperAdmin = false) => {
  return gql`
  query user($id: ID) {
    user(id: $id) {
      ${isAdmin ? commonAdminProps : commonProps}
      name:firstName
      gender
      dob
      npi
      ${isSuperAdmin ? superAdminOnlyLicensesState : adminOnlyCommonLicenses}
      whitelistIp
      clientId
      secret
      apiKey
      externalId
      showGDSAnalytics
      ${address}
      licensesPerState { name license }
    }
  }
`;
}

export const userBrands = (isAdmin = false, isSuperAdmin = false) => {
  console.log("brands from common", brands);
  return gql`
  query user($id: ID) {
    user(id: $id) {
      externalId
      showGDSAnalytics
      ${brands}
    }
  }
`;
}

export const userLabs = (isAdmin = false, isSuperAdmin = false) => {
  console.log("labs from common", labs);
  return gql`
  query user($id: ID) {
    user(id: $id) {
      externalId
      showGDSAnalytics
      ${labs}
    }
  }
`;
}

export const getAdminGDSURL = () => {
  console.log("getAdminGDSURL store calling api calls");
  return gql`
  query getAdminGDSURL {
    getAdminGDSURL{
      brandURL
      labURL
      mdURL
    }
  }
`;
}
export const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
			id
		}
  }
`;

export const forceDeleteUser = gql`
  mutation forceDeleteUser($id: ID!) {
    forceDeleteUser(id: $id) {
			id
		}
  }
`;

export const updateStatus = gql`
  mutation updateUserStatus($id: ID!, $status: UserUpdateStatusEnum!) {
    updateUserStatus(id: $id, status: $status) {
			id
		}
  }
`;

export const createMDLicense = gql`
  mutation createMdLicense($name: String!, $license: String!, $userId: ID!) {
    createMdLicense(name: $name, license: $license, userId: $userId) {
      id
    }
  }
  `;

export const updateMdLicense = gql`
  mutation updateMdLicense($id: ID!, $name: String!, $license: String!, $userId: ID!) {
    updateMdLicense(id: $id, name: $name, license: $license, userId: $userId) {
      id
    }
  }
  `;

  export const deleteMdLicense = gql`
  mutation deleteMdLicense($id: ID!, $userId: ID!) {
    deleteMdLicense(id: $id, userId: $userId) {
      id
    }
  }
  `;

  export const userLicense = (isAdmin = false, isSuperAdmin = false) => {
    return gql`
    query user($id: ID) {
      user(id: $id) {
        id
        ${isSuperAdmin ? superAdminOnlyLicensesState : superAdminOnlyLicensesState}
      }
    }
  `;
  }

  export const userLicense1 = gql`
    query user($id: ID) {
      user(id: $id) {
        id
        licenses
        licensesPerState{
          id
          name
          reviewCount
          approveCount
          license
        }
        reviewCount
        releaseCount
      }
    }
  `;
  // export const userLabs = (isAdmin = false, isSuperAdmin = false) => {
  //   console.log("labs from common", labs);
  //   return gql`
  //   query user($id: ID) {
  //     user(id: $id) {
  //       externalId
  //       showGDSAnalytics
  //       ${labs}
  //     }
  //   }
  // `;
  // }