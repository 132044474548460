import gql from 'graphql-tag';
import { address, commonPropsBrand, commonPropsBrandIndividual, commonCreateUpdateBrand } from './common';

export const allBrands = gql`
  query allBrands($first: Int, $skip: Int, $orderBy: BrandOrderBy, $filter: BrandFilter) {
    brands(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      ${commonPropsBrand}
      dropShipment
      meta
      panelCount
      orderCount(where: {status_not_in: [MD_DECLINED, MD_RELEASED, CANCELED]})
      billingType
      monthlyFee
      perOrderFee
      includedOrders
      portalFee
      fulfillmentPerOrderFee
      fulfillmentMonhtlyFee
      batchSize
      batchReBilledAt
      batchLastBilledDate
      currentBatchSize
    }
    brandsMeta(filter: $filter) {
      count
    }
  }
`;

export const brands = gql`
  query brands($first: Int, $skip: Int, $orderBy: BrandOrderBy, $filter: BrandFilter) {
    brands(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
      ${commonPropsBrand}
      panelCount
      orderCount(where: {status_not_in: [MD_DECLINED, MD_RELEASED, CANCELED]})
    }
  }
`;

export const createBrand = (isAdmin = false) => {
  return gql`
	mutation createBrand(${isAdmin ? commonCreateUpdateBrand.a1 : commonCreateUpdateBrand.p1}) {
		createBrand(${isAdmin ? commonCreateUpdateBrand.a2 : commonCreateUpdateBrand.p2}) {
			id
		}
  }`;
}

export const brand = (isAdmin = false) => {
  return gql`
    query brand($id: ID!) {
    brand(id: $id) {
      ${commonPropsBrandIndividual}
      sftpUsername
      gdsAnalyticsURL
      associatedUsers {
        id
        firstName
        lastName
      }
      providerUsers {
        id
        firstName
        lastName
      } 
      ${isAdmin ? `dropShipment` : ``}
      ${isAdmin ? `dropShipmentMeta` : ``}
      ${isAdmin ? `pluginMeta` : ``}
      ${isAdmin ? `sftpResultProcess` : ``}
      ${isAdmin ? `sftpUsername` : ``}
      ${isAdmin ? `sftpPPK` : ``}
      ${isAdmin ? `sftpUrl` : ``}
      ${isAdmin ? `sftpPort` : ``}
      ${isAdmin ? `sftpBrandPrefix` : ``}
      ${isAdmin ? `sftpInputPath` : ``}
      ${isAdmin ? `sftpOutputPath` : ``}
      ${isAdmin ? `billingType` : ``}
      ${isAdmin ? `monthlyFee` : ``}
      ${isAdmin ? `perOrderFee` : ``}
      ${isAdmin ? `includedOrders` : ``}
      ${isAdmin ? `portalFee` : ``}
      ${isAdmin ? `fulfillmentPerOrderFee` : ``}
      ${isAdmin ? `fulfillmentMonhtlyFee` : ``}
      ${isAdmin ? `batchFee` : ``}
      ${isAdmin ? `batchSize` : ``}
      ${isAdmin ? `batchReBilledAt` : ``}
      ${isAdmin ? `batchLastBilledDate` : ``}
      ${isAdmin ? `currentBatchSize` : ``}
      ${address}
    }
  }
`;
}

export const updateBrand = (isAdmin = false) => {
  return gql`
    mutation updateBrand($id: ID!, ${isAdmin ? commonCreateUpdateBrand.a1 : commonCreateUpdateBrand.p1}) {
    updateBrand(id: $id, ${isAdmin ? commonCreateUpdateBrand.a2 : commonCreateUpdateBrand.p2}) {
      id
    }
  }
`;
}

export const deleteBrand = gql`
  mutation deleteBrand($id: ID!) {
    deleteBrand(id: $id) {
			id
		}
  }
`;

export const updateGDSAnalyticsSetting = () => {
  return gql`
  mutation updateGDSAnalyticsSetting($email: String!, $showGDSAnalytics: Boolean!) {
    updateGDSAnalyticsSetting(email: $email, showGDSAnalytics: $showGDSAnalytics) {
			id
      showGDSAnalytics
		}
  }
`;
}

export const getGDSAnalyticsFlag = () => {
  return gql`
   query getGDSAnalyticsUserSetting($email: String!) {
    getGDSAnalyticsUserSetting(email: $email) {
      showGDSAnalytics
    }
  }
`;
}

export const getDropShipmentPlugins = gql`
  query getDropShipmentPlugins {
    getDropShipmentPlugins {
      name
    }
  }
`;

export const deleteBrandSFTPCredentails = () => {
  return gql`
    mutation deleteSFTPCredentails(
      $id: ID!
    ) {
      deleteSFTPCredentails(
        id: $id
      ) {
      id
    }
  }
`;
}

export const testConnection = () => {
  return gql`
    mutation testSFTPConnection(
      $id: ID!,
      $connectionType: String!
    ) {
      testSFTPConnection(
        id: $id,
        connectionType: $connectionType
      ) {
      id
    }
  }
`;
}

export const allBrandsForBilling = gql`
  query{
    brands {
      id
      name
      billingType
      monthlyFee
      perOrderFee
      includedOrders
      portalFee
      fulfillmentPerOrderFee
      fulfillmentMonhtlyFee
      batchSize
      batchReBilledAt
      batchLastBilledDate
      currentBatchSize
    }
  }
`;

export const allMDForBilling = gql`
query users($first: Int, $skip: Int, $orderBy: UserOrderBy, $filter: UserFilter) {
  users(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter) {
    id
    firstName
    lastName
    email
    npi
    approvalPrice
    releasePrice
    outreachPrice
    expeditedReleasePrice
    provisionalApprovalPrice
  }
}
`;

export const activeMDBilling = gql`
query ActiveMDBilling($startDate: String!, $endDate: String!) {
  activeMDBilling(startDate: $startDate, endDate: $endDate) {
    approval
    releases
    outreaches
    expeditedRelease
    provisionalApproval
    allMDData {
      id
      npi
      firstName
      lastName
      approval
      releases
      outreaches
      expeditedRelease
      provisionalApproval
    }
  }
}
`;

export const processBrandSFTPOrders = () => {
  return gql`
    mutation processBrandSFTPOrders($brandId: ID!, $operation: String!) {
      processBrandSFTPOrders(brandId: $brandId, operation: $operation)
    }
  `;
};

