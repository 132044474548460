import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Image, Form, Divider } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { FormInput } from '../../../components/formElements';
import { ListErrors } from '../../../components/shared';
import OrdrsTitle from '../../../assets/images/ordrs-title.svg';
import AuthSideCol from '../components/AuthSideCol';

class Login extends React.Component {
  login = async () => {
    const response = await this.props.authStore.login();
    const { uiStore } = this.props;
    if (response === true) {
      this.props.history.push(
        uiStore.redirectURL ? uiStore.redirectURL.pathname : '/app/orders'
      );
    }
  };
  render() {
    const { LOGIN_FRM, formChange, status } = this.props.authStore;
    const { claimStatus } = document;

    return (
      <section className="signup-wrap">
        <Container>
          <Grid stackable columns="equal">
            <AuthSideCol claimStatus={claimStatus} />
            <Grid.Column>
              <div className="signup-section">
                <Image src={OrdrsTitle} centered className="logoname" />
                <Divider section hidden />
                <Form onSubmit={this.login}>
                  {Object.keys(LOGIN_FRM.fields).map(field => (
                    <FormInput
                      key={field}
                      type={field === 'password' ? 'password' : 'email'}
                      name={field}
                      autoFocus={field === 'email'}
                      fielddata={LOGIN_FRM.fields[field]}
                      changed={(e, result) =>
                        formChange(e, result, 'LOGIN_FRM')
                      }
                    />
                  ))}
                  <Divider hidden />
                  {typeof status !== 'boolean' && (
                    <ListErrors errors={[status]} />
                  )}
                  <div className="text-center">
                    <Form.Button
                      primary
                      fluid
                      size="large"
                      content="Sign In"
                      loading={status === true}
                    />
                    <Link to="/auth/forgot-password">Forgot password?</Link>
                  </div>
                </Form>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    );
  }
}

export default inject('authStore', 'uiStore')(observer(Login));
