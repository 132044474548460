import { makeObservable, observable, computed, action, toJS } from "mobx";
import DataStore from "../shared/DataStore";
import LabsStore from "../labs";
import {
  MobxApollo,
  FormHandler as FormHandle,
  Utility as Utils,
} from "../../../../utils";
import {
  uniqueMarkers,
  downloadOrderMarkers,
  uploadOrderMarkers,
  createLabMarker,
  updateLabMarker,
  createLabResult,
  updateLabResult,
  uploadBulkResults,
  downloadLabTestResults,
  deleteManyLabMarkers
} from "../../queries/markers";
import { lab } from "../../queries/labs"
import { MARKER_META, RESULT_META } from "../../../constants/panel";
import { UNITS } from "../../../constants/marker";
export class MarkersStore extends DataStore {
  constructor() {
    super(null, "uniquePanelMarker", {
      all: uniqueMarkers,
    });

    makeObservable(this, {
      markers: observable.ref,
      progress: observable.ref,
      downloadResult: observable.ref,
      markerAction: observable.ref,
      MARKERS_FRM: observable.ref,
      addMarker: observable.ref,
      bulkUploadResults: observable.ref,
      count: computed,
      loading: computed,
      data: computed,
      upload: action,
      initLoad: action,
      saveMarker: action,
      bulkImportResults: action
    });
  }

  MARKERS_FRM = FormHandle.prepareForm(MARKER_META);
  downloadResult = [];
  progress = false;
  addMarker = false;
  markers = [];
  markerAction = "new";
  bulkUploadResults = false;

  initLoad(params) {
    super.initLoad(params);
  }

  toggleBulkUploadResultsPopup = () => {
    this.bulkUploadResults = !this.bulkUploadResults;
    this.progress = false;
    console.log("calling toggle upload")
  };

  bulkImportResults = async (orderResults) => {
    const labDetails = LabsStore.labDetails;
    const roles = document.userMetaData || [];
    try {
      const res = await this.client.mutate({
        mutation: uploadBulkResults,
        variables: { labId: labDetails.id, results: orderResults },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
        ],
      });
      this.progress = false;
      if (res && res.data && res.data.uploadBulkResults) {
        if(res.data.uploadBulkResults.error === "0") {
          Utils.toast(
            'Results bulk upload operation completed, please review the generated result for details.',
            'success'
          );
        } else {
          let errorMessage = 'Error while uploadig Results- ' + res.data.uploadBulkResults.message
          Utils.toast(errorMessage, 'error');
        }
      }
      return true;
    } catch (e) {
      this.progress = 0;
      console.log(e);
      Utils.toast('Error while importing orders', 'error');
      return false;
    } finally {
      this.currTime = +new Date();
    }
  };

  download = (markerName) => {
    this.noloaderEvent = true;
    this.progress = true;
    MobxApollo.graphql({
      client: this.client,
      query: downloadOrderMarkers,
      variables: { markerName },
      onFetch: (res) => {
        if (res) {
          this.downloadResult = [];
          const params = {
            fields: ["markerId", "reading", "reading"],
            fileName: markerName,
            data: res.downloadOrderMarkers.map((r) => ({
              markerId: `${r.orderOrderId}_${markerName}`,
              ...r,
            })),
          };
          Utils.downloadCSV(params);
          Utils.toast("Lab results downloaded successfully.", "success");
          this.noloaderEvent = false;
          this.progress = false;
        }
      },
      onError: (e) => {
        Utils.toast(
          "Error while downloading Lab results.",
          "error",
          {},
          e.message
        );
        this.noloaderEvent = false;
        this.progress = false;
      },
    });
  };

  upload = async (markerName, data, force = false) => {
    this.progress = true;
    const ignored = [];
    try {
      const formattedMarkers = [];
      data.forEach((m, idx) => {
        if (idx === 0 && m[0] === "markerId" && m[1] === "reading") {
          // do nothing.. first row valid titles
        } else {
          if (m[0]) {
            const oId =
              (m[0].match(/_/g) || []).length > 1
                ? m[0].substring(0, m[0].lastIndexOf("_") + 0)
                : m[0].split("_")[0];
            const mName = m[0].substring(
              m[0].lastIndexOf("_") + 1,
              m[0].length
            );
            // if (mName === markerName) {
            //   formattedMarkers.push({ orderOrderId: oId, reading: m[1] });
            // } else {
            //   ignored.push(m);
            // }
            formattedMarkers.push({
              orderOrderId: oId,
              name: mName,
              reading: m[1],
            });
          } else {
            ignored.push(m);
          }
        }
      });
      const uploadResult = await this.client.mutate({
        mutation: uploadOrderMarkers,
        variables: { markers: formattedMarkers, force },
      });
      this.progress = false;
      if (
        uploadResult &&
        uploadResult.data &&
        uploadResult.data.uploadOrderMarkers
      ) {
        const data = uploadResult.data.uploadOrderMarkers;
        if (data.summary.ERROR > 0) {
          //error
          Utils.toast(
            "Please correct the errors in your file to upload results.",
            "error"
          );
        } else if (
          data.summary.ERROR === 0 &&
          data.summary.CONFLICTS > 0 && !force
        ) {
          // no errors just conflicts and no force update
          Utils.toast(
            "Please review conflicts and Force Update to override.",
            "warning"
          );
        } else if (
          data.summary.ERROR === 0 &&
          ((data.summary.CONFLICTS > 0 && force) ||
            (data.summary.CONFLICTS === 0 && data.summary.GOOD > 0))
        ) {
          // success, updated some records.
          Utils.toast("Updated marker values.", "success");
        } else if (
          data.summary.GOOD === 0 &&
          ((data.summary.CONFLICTS > 0 && force) ||
            data.summary.CONFLICTS === 0)
        ) {
          // nothing to update
          Utils.toast("Nothing to update.", "info");
        }
      }
      const resData = { ...uploadResult.data.uploadOrderMarkers };
      resData.summary.IGNORED = ignored.length;
      resData.ignored = ignored;
      return resData;
    } catch (e) {
      this.progress = 0;
      console.log(e);
      Utils.toast("Error while uploading", "error");
      return false;
    } finally {
      this.currTime = +new Date();
    }
  };

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

  // Functions for Marker functionality:

  clearRange = (e, result, form) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const tempData = { ...this[formName] };

    tempData.fields.ranges[form[2]].rangeValue.value =
      tempData.fields.ranges[form[2]].type.value === "EQUAL_STRING" ||
      result.value === "EQUAL_STRING"
        ? ""
        : tempData.fields.ranges[form[2]].rangeValue.value;

    this.formChange(e, result, form);
    this[formName] = { ...tempData };
  };

  rangeChange = (e, result, form) => {
    const formName = Array.isArray(form) ? form[0] : form;

    this[formName].fields.ranges[form[2]][result.name].rule =
      this[formName].fields.ranges[form[2]].type.value === "EQUAL_STRING"
        ? "alphaNumeric"
        : "float";

    this.formChange(e, result, form);
  };

  setResetRange = (i, data) => {
    [
      "name",
      "isCritical",
      "isReportable",
      "isThreshold",
      "type",
      "rangeValue",
      "rangeType",
    ].forEach((k) => {
      this.formChange(
        {},
        { name: k, value: data === false ? "" : data[k] },
        ["MARKERS_FRM", "ranges", i],
        "dropdown"
      );
    });
  };

  addMore = (form, refKey) => {
    this[form] = FormHandle.addMoreRecordToSubSection(
      this[form],
      refKey,
      1,
      true
    );
    const i = this[form].fields.ranges.length - 1;
    [
      "name",
      "isCritical",
      "isReportable",
      "isThreshold",
      "type",
      "rangeValue",
      "rangeType",
    ].forEach(() => {
      this.setResetRange(i, false);
    });
    this.currTime = +new Date();
  };

  changeAddPanel = (action) => {
    this.addMarker = !this.addMarker;
    this.markerAction = action || "new";
    if (this.markerAction === "new") {
      this.resetMarkers();
    }
    this.progress = false;
  };

  setMarker = (data) => {
    const dataToSet = { ...data };
    if (!UNITS.includes(data.units)) {
      dataToSet.units = "other";
      dataToSet.unitsFreeTxt = data.units;
    }
    this.MARKERS_FRM = FormHandle.setFormData(this.MARKERS_FRM, dataToSet);
    const rangesCnt = data.ranges.length;
    for (let i = 0; i < rangesCnt; i += 1) {
      this.setResetRange(i, data.ranges[i]);
    }
  };

  resetMarkers = () => {
    this.MARKERS_FRM = FormHandle.prepareForm(MARKER_META);
    this.setResetRange(0, false);
    this.MARKERS_FRM = FormHandle.resetFormData(this.MARKERS_FRM);
  };

  saveMarker = async (reference) => {
    const labDetails = LabsStore.labDetails;
    const params = FormHandle.evaluateFormData(this.MARKERS_FRM.fields);
    params.ranges = params.ranges.map((r) => {
      r.value = r.rangeValue;
      delete r.rangeValue;
      return r;
    });
    if (params.units === "other") {
      params.units = params.unitsFreeTxt;
      params.unitsFreeTxt = undefined;
    }
    this.progress = true;
    const roles = document.userMetaData || [];

    let mutationToUse = this.markerAction === "new" ? createLabMarker : updateLabMarker;
    if (reference === 'lab') {
      mutationToUse = this.markerAction === "new" ? createLabResult : updateLabResult;
    }
    try {
      await this.client.mutate({
        mutation: mutationToUse,
        variables: {
          labId: labDetails.id,
          ...toJS(params),
          ...(this.markerAction !== "new" ? { id: this.markerAction } : null),
        },
        refetchQueries: [
          {
            query:
              typeof lab === "function"
                ? lab(roles.includes("ADMIN"))
                : lab,
            variables: { id: labDetails.id },
          },
        ],
      });
      this.progress = false;
      const markers = [...this.markers];
      markers.push(params);
      this.markers = markers;
      Utils.toast(
        `Marker ${
          this.markerAction === "new" ? "added" : "updated"
        } successfully.`,
        "success"
      );
    } catch (e) {
      console.log('Error :: Lab :: Marker ==>', e);
      Utils.toast(
        `Error ${this.markerAction === "new" ? "adding" : "updating"} Marker.`,
        "error"
      );
      this.progress = 0;
    }
  };
  downloadLabTestResults = async (id) => {
    try {
      const result = await this.client.query({
        query: downloadLabTestResults,
        variables: {
          id,
        },
      });
      if(result && result.data && result.data.downloadLabTestResults) {
        return result.data.downloadLabTestResults;
      } else {
        Utils.toast("No data found for the selected labs", "error");
      }
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error while performing action", "error", {}, e.message);
    }
  }
  deleteManyLabTests = async (ids) => {
    try {
      const labDetails = LabsStore.labDetails;
      const roles = document.userMetaData || [];
      await this.client.mutate({
        mutation: deleteManyLabMarkers,
        variables: { ids },
        refetchQueries: [
          {
            query: typeof lab === "function"
              ? lab(roles.includes("ADMIN"))
              : lab,
            variables: { id: labDetails.id },
          },
        ],
      });
      Utils.toast(
        'Results removed successfully.',
        'success'
      );
    } catch (e) {
      this.progress = 0;
      Utils.toast("Error removing tests", "error", {}, e.message);
    }
  }
}

export default new MarkersStore();
