import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import ConfirmPassword from './containers/ConfirmPassword';
import { NotFound } from '../../components/shared';

class Auth extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/confirm-password" component={ConfirmPassword} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default withRouter(Auth);
