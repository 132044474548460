import React, { Suspense, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Sidebar,
  Icon,
} from 'semantic-ui-react';
import { MySidebar } from '../../components/layout';
import { NotFound, InlineLoader } from '../../components/shared';
import { Utility as Utils } from '../../utils/index';
import { UserContext } from '../../components/contexts';

class Private extends React.Component {
  componentDidMount() {
    if (!this.props.authStore.currentSession) {
      this.props.uiStore.setRedirectURL(this.props.history.location);
      this.props.history.push('/auth/login');
    }
    const userRole = this.props.authStore.userRole();
    Utils.injectFreshChat(userRole);
  }

  getPrivateRoutes = (roles) => {
    const routes = [];
    this.props.navStore.myRoutes.forEach((item) => {
      if (item.path) {
        routes[`${item.path}_${item.to}`] = (
          <Route
            path={`/app/${item.to}`}
            component={lazy(() =>
              import(
                `./${typeof item.path === 'object' && roles
                  ? item.path[roles[0]]
                  : item.path
                }`
              )
            )}
            key={item.path}
          />
        );
      }
    });
    return routes;
  };
  render() {
    const routes = this.getPrivateRoutes(['admin']);
    const { currentSession } = this.props.authStore;
    const { openSidebar, toggleSideBar } = this.props.uiStore;
    let isMobile = this.props.uiStore.isMobile();
    // const isTablet = isMobile && document.body.offsetWidth >= 768;
    const isTablet = isMobile && document.body.offsetWidth >= 768;
    if (isTablet) {
      isMobile = false;
    }
    // const divHeight = window.innerHeight - 153;
    return (
      <UserContext.Provider value={currentSession}>
        <Sidebar.Pushable>
          {isMobile ? (
            <div
              className={`mobileSidebar ${openSidebar && 'open'}`}
            >
              <MySidebar />
              <div className="backbtnmobile" style={isTablet ? { display: 'none' } : {} }>
                <Icon
                  className="chevron left icon"
                  onClick={() => toggleSideBar()}
                />
              </div>
            </div>
          ) : isTablet ? (
            // <span>
            //   <MySidebar />
            // </span>

          <MySidebar />
          ) : (
            <MySidebar />
            )}

          {/* <Sidebar.Pusher > */}
          <Sidebar.Pusher style={isTablet ? { marginLeft: '-75px', width : '90%' } : {}}>
          {/* style={isTablet ? { marginLeft: '-50px' } : {}} */}
            <Suspense
              fallback={<InlineLoader styledAs={{ marginTop: '100px' }} />}
            >
              <Switch>
                {Object.keys(routes).map((route) => routes[route])}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </UserContext.Provider>
    );
  }
}

export default inject('navStore', 'authStore', 'uiStore')(withRouter(observer(Private)));