import { makeObservable, observable, action, toJS } from "mobx";
import { startCase, pickBy, isEmpty, toUpper, isNull, get, set, lowerCase, has, isNil } from "lodash";
import cleanDeep from "clean-deep";
import moment from 'moment';
import { GqlClient as client, getData, dynamicEnvGqlApi } from "../../../../api/gqlApi";
import apiService from "../../../../api/restApi";
import {
  getUploadSignedUrl,
  removeFile,
  removeFileFromPanel,
  deleteAttachment,
  getFileUploadUrlForOrder,
  getFileUploadUrlForPanel,
  getFileUploadUrlForPanelMeta,
  getFileUploadUrlForBrandMeta,
  getDownloadLink,
  getDownloadLinkByFileNameKey,
  getLabOrderRequisitionPdfDownloadLink
} from '../../queries/common';
import { uiStore, authStore } from '../../index';
import {
  MobxApollo,
  FormHandler as FormHandle,
  Utility as Utils,
} from '../../../../utils';
import { NOTIFICATION_ACTION_LIST } from '../../../../services/constants/shared'
import { DEPLOY_ENV } from '../../../../constants/common';
import { dropShipment } from "../../queries/shipment";

export default class DataStore {
  constructor(form, refKey, queryMutations) {
    makeObservable(this, {
      listData: observable,
      result: observable.ref,
      pages: observable.ref,
      meta: observable.ref,
      isLoading: observable,
      currTime: observable,
      refUrl: observable.ref,
      lastKey: observable.ref,
      getOne: action,
      resetServerState: action,
      setFilter: action,
      setOrder: action,
      setParam: action,
      setRefUrl: action,
      initSearch: action,
      formChange: action,
      editorChange: action,
      maskChange: action,
      validateForm: action,
      save: action,
      updateStatus: action,
      delete: action,
      reset: action,
      upload: action,
      addMore: action,
      uploadMedia: action,
      resetImageCropper: action,
      setMediaAttribute: action,
      setFormKeyData: action,
      downloadFile: action,
      removeFile: action,
      removeOne: action,
      resetAll: action,
      removeMedia: action,
      setTargetClient: action,
      setFormData: action,
      setFieldValue: action,
      initLoad: action,
      CREATE_FRM: observable.ref,
      entityDetails: observable.ref,
      auStatus: observable.ref,
      noloaderEvent: observable.ref,
      listAction: observable.ref,
      gStatus: observable.ref,
      metaCount: observable.ref,
      metaParams: observable.ref,
      pageTitle: observable.ref,
      isActionInProgress: observable.ref,
      detailsRecordLabTests: observable.ref
      // currentItem: observable.ref,
    });
    this.CREATE_FRM = form && FormHandle.prepareForm(form);
    this.gqlRef = queryMutations;
    this.keyMap = this.keyMap || { all: `${refKey}s`, item: refKey };
    this.refKey = refKey;
  }

  listData = null;
  result = [];
  pages = [];
  entityDetails = {};
  listAction = true;
  meta = {};
  metaCount = {};
  metaParams = {};
  currentItem = null;
  userMetaData = null;
  params = { orderBy: 'createdAt_DESC' };
  searchParam = {};
  gqlRef = {};
  currTime = null;
  auStatus = null; // 0: error, 1: loading, 2: success
  gStatus = null;
  CREATE_FRM = null;
  keyMap = null;
  client = client;
  targetClient = null;
  noloaderEvent = false;
  refKey = null;
  refUrl = '';
  isLoading = false;
  lastKey = null;
  pageTitle = null;
  detailsRecordLabTests = [];
  isActionInProgress = false;

  resetServerState() {
    this.lastKey = null;
    this.pages = [];
  }

  initLoad(params, forced = false) {
    this.params = forced ? { ...params } : { ...this.params, ...params };
    const metaInfo = this.params.metaInfo;
    const uId = this.params.uId;
    const referTabs = this.params.referTabs;
    const store = this.params.store;
    const currentRole = get(this.params, 'currentRole');

    this.params.metaInfo = this.params.uId = this.params.referTabs = null;
    const role = currentRole
      || (document.userMetaData && document.userMetaData.length > 1
        ? document.userMetaData[1]
        : document.userMetaData[0]);

    if (metaInfo && get(metaInfo, 'module')) {
      switch (metaInfo.module) {
        case 'Orders':
          this.params.orderBy = role === 'MD' ? 'createdAt_ASC' : 'createdAt_DESC';
          break;
        case 'Labs':
        case 'Brands':
          this.params.orderBy = 'name_ASC';
          break;
        default:
          this.params.orderBy = 'createdAt_DESC';
          break;
      }
    } else {
      if (params && params.orderBy) {
        this.params.orderBy = params.orderBy;
      }
    }
    let graphQlQuery = this.gqlRef.all;
    if (typeof this.gqlRef.all === 'function') {
      const roles = document.userMetaData || [];
      graphQlQuery = this.gqlRef.all(roles.includes('ADMIN'));
    }

    let variables = { first: this.params.perPage, skip: 0, orderBy: this.params.orderBy, filter: this.params.filters, userId: this.params.panelUserId };

    if (get(metaInfo, 'module')) {
      this.params.filters = metaInfo.module === 'Fulfilment' && this.lastKey ? { ...this.params.filters, lastKey: this.lastKey } : { ...this.params.filters }
      variables = metaInfo.module === 'Fulfilment' ?
        { filter: { ...this.params.filters } }
        : { ...variables };
    }
    // if (this.lastKey) { // get(metaInfo, 'module') === 'Fulfilment' && 
    //   this.params.filters.lastKey = this.lastKey;
    // }

    const loadMoreData = this.lastKey ? true : false;
    if (this.lastKey) {
      this.lastKey = null;
    }

    // MobxApollo.setLoading(true);
    this.setLoading(true);
    this.result = get(metaInfo, 'module') === 'Fulfilment' ? [...this.result] : [];
    this.pages = [...this.pages] || [];
    this.listData = MobxApollo.graphql({
      client: this.client,
      query: graphQlQuery,
      fetchPolicy: "network-only",
      variables,
      onError: (err) => {
        console.error('onError=>', err);
        // MobxApollo.setLoading(false);
        this.setLoading(false);
      },
      onFetch: (data) => {
        if (data) {
          if (this.keyMap.all === 'dropShipments') {
            if (loadMoreData) {
              this.result = [...this.result, ...data[this.keyMap.all][this.keyMap.all]];
              // hack to avoid multiple push...
              const firstOfLastPage = this.pages[this.pages.length - 1][0];
              const firstOfNewPage = data[this.keyMap.all][this.keyMap.all][0];
              if (firstOfLastPage.id !== firstOfNewPage.id) {
                this.pages.push(data[this.keyMap.all][this.keyMap.all]);
              }
            } else {
              this.result = data[this.keyMap.all][this.keyMap.all];
              this.pages[0] = data[this.keyMap.all][this.keyMap.all];
            }

            this.lastKey = data[this.keyMap.all].lastKey ? data[this.keyMap.all].lastKey : null;

            // this.pageTitle = this.lastKey ? `Showing ${this.result.length} of many fulfilment` : `Showing ${this.result.length} fulfilment`;
          } else {
            this.result = data[this.keyMap.all];
            this.meta = data[`${this.keyMap.all}Meta`];
            this.lastKey = null;
            // console.log(get(data, 'orders[0].id'));
            if(get(data, 'orders[0].id')) {
              this.orderIdList = get(data, 'orders').map(item => item.id);
            } else {
              this.orderIdList = []
            }
          }
          this.setLoading(false);
        }
      },
    });

    this.searchParam = this.params.filters;

    if (referTabs && store === 'ordersStore') {

      this.metaCount = {};

      Object.keys(referTabs).forEach((status) => {
        const currentStatus = get(params, 'currentStatus');
        // const currentAction = get(params, 'currentAction');
        this.unsetFilter();
        const role = currentRole
          || (document.userMetaData && document.userMetaData.length > 1
            ? document.userMetaData[1]
            : document.userMetaData[0]); // need to improve
        const mappedStatus =
          referTabs[status].accessTo[role] ||
          referTabs[status].accessTo.default;
        const isStatusInclude = !mappedStatus || (mappedStatus && !mappedStatus.includes('ANY'));

        if (isStatusInclude) {
          metaInfo.tabKey.forEach((tK, idx) => {
            if (referTabs[status][tK] !== undefined || mappedStatus !== undefined) {
              this.setFilter(
                `${tK}_in`,
                idx > 0 ? referTabs[status][tK] : mappedStatus,
              );
            }
          });
        }

        // patch
        if (
          metaInfo.addUserContext &&
          metaInfo.addUserContextIf.includes(role) &&
          !metaInfo.addLabAndBrandContextIf.includes(this.params.isMDOrderDetails)
        ) {
          const context = metaInfo.addUserContext[status];
          if (context) {
            if (context.as === 'AND') {
              this.setFilter(context.refKey, { id: uId, });
              if (context.outreachStatus !== undefined) {
                this.setFilter('outreachStatus', context.outreachStatus);
              }
            }
            if (context.as === 'OR') {
              this.setFilter(
                context.as,
                mappedStatus.map((s, indx) => (
                  {
                    [context.refKey[indx]]: { id: uId },
                    status_in: [s],
                  }
                )),
                true
              );
            }
          }
        }

        if (
          metaInfo.addLabAndBrandContext &&
          metaInfo.addLabAndBrandContextIf.includes(this.params.isMDOrderDetails)
        ) {
          const context = metaInfo.addLabAndBrandContext[this.params.isMDOrderDetails];
          if (context) {
            if (context.as === "AND") {
              this.setFilter(context.refKey, {
                id: uId,
              });
            }
          }
        }

        if (metaInfo.addActivityContext && metaInfo.addActivityContextIf.includes(role) && get(metaInfo, `addActivityContext[${status}]`)) {
          this.setFilter(get(metaInfo, `addActivityContext[${status}].refKey`), { ...get(metaInfo, `addActivityContext[${status}].refObj`), ...get(metaInfo, `addActivityContext[${status}].typeObj`) });
          const dateRangeObj = (currentStatus === status) ? get(params, 'addOnFilters.dateRangeObj') : get(metaInfo, `addActivityContext[${status}].refObj`);
          if (get(params, 'addOnFilters.refKey')) {
            const addonFilterObj = { [get(params, 'addOnFilters.refKey')]: { ...dateRangeObj, ...get(metaInfo, `addActivityContext[${status}].typeObj`) } }
            this.params.filters = { ...this.params.filters, ...addonFilterObj };
          }
        }

        if (status === 'submitted') {
          this.setFilter('OR', [{ 'outreachStatus': null }, { 'outreachStatus_not': 'PENDING' }]);
        }

        if (!isEmpty(this.params.filters)) {
          // MobxApollo.setLoading(true);
          this.metaParams[status] = JSON.parse(JSON.stringify(this.params));
          MobxApollo.graphql({
            client: this.client,
            query: this.gqlRef.ordersCount,
            fetchPolicy: 'network-only',
            variables: {
              filter: this.params.filters,
              userId: this.params.panelUserId,
            },
            onError: (err) => {
              // MobxApollo.setLoading(false);
              console.error('onError=>', err);
            },
            onFetch: (data) => {
              if (data) {
                this.metaCount = { ...this.metaCount, [status]: data[`${this.keyMap.all}Meta`] };
                // MobxApollo.setLoading(false);
              }
            },
          });
        }
      });
    }

    this.currTime = +new Date();
  }

  getOne = (id, params) => {
    this.entityDetails = {};
    this.gStatus = 1;
    let graphQlQuery = this.gqlRef.getOne;
    if (typeof this.gqlRef.getOne === 'function') {
      const roles = document.userMetaData || [];
      let isSuperAdminAccess = false;
      if (get(params, 'module') === 'user') {
        isSuperAdminAccess = this.checkForSuperAdminAccess();
        graphQlQuery = this.gqlRef.getOne(roles.includes('ADMIN'), isSuperAdminAccess);
      } if (get(params, 'module') === 'panel') {
        graphQlQuery = this.gqlRef.getOne(roles.includes('ADMIN'), roles.includes('BRAND'));
      } else {
        graphQlQuery = this.gqlRef.getOne(roles.includes('ADMIN'));
      }
    }
    this.currentItem = MobxApollo.graphql({
      // client: this.client,
      client,
      query: graphQlQuery,
      fetchPolicy: 'network-only',
      // fetchPolicy: "cache-first",
      variables: id ? { id } : null,
      onFetch: async (res) => {
        if (res) {
          const resultData = JSON.parse(JSON.stringify(res[this.keyMap.item]));
          let data = resultData;
          if (data && data.associatedUsers) {
            data.associatedUsersId = data.associatedUsers.map((u) => u.id);
          }
          if (data && data.providerUsers) {
            data.providerUsersId = data.providerUsers.map((u) => u.id);
          }
          if (data && data.config && isNull(data.config.expeditedRelease)) {
            data.config.expeditedRelease = true;
          }
          if (!id) {
            this.userMetaData = [data.role, data.userType].filter(
              (r) => r !== '' && r !== null
            );
            document.userMetaData = this.userMetaData;
            this.gStatus = 3;
            uiStore.updateLoading(false);
          }
          this.entityDetails = data;

          if (id) {
            let setData = { ...data };
            if (setData && (this.keyMap.item === 'brand' || this.keyMap.item === 'lab')) {
              setData.preference = [];
              if (!setData.notification) {
                setData.preference = Utils.convertObjectToArray(NOTIFICATION_ACTION_LIST[toUpper(this.keyMap.item)], 'action');
              } else {
                if (!setData.notification.preference) {
                  setData.preference = Utils.convertObjectToArray(NOTIFICATION_ACTION_LIST[toUpper(this.keyMap.item)], 'action');
                } else {
                  const preference = { ...NOTIFICATION_ACTION_LIST[toUpper(this.keyMap.item)], ...JSON.parse(setData.notification.preference) };
                  setData.preference = Utils.convertObjectToArray(preference, 'action');
                  data.preference = data.notification.preference ? JSON.parse(JSON.stringify(setData.preference)) : null;
                }
              }
            }
            if (data.whitelistIp && Array.isArray(data.whitelistIp)) {
              setData.whitelistIp = data.whitelistIp.join(',');
            }
            if (get(params, 'jsonFields')) {
              setData = await this.jsonParser(get(params, 'jsonFields'), setData);
            }
            if (this.keyMap.item === 'panel') {
              if (!get(setData, 'meta')) {
                setData.meta = {}
              }
              setData.meta.panelName = get(setData, 'meta.panelName') ? setData.meta.panelName : setData.name;
              setData.meta.prefix = get(setData, 'meta.panelPrefix') ? setData.meta.panelPrefix : setData.prefix;

              this.detailsRecordLabTests = setData.labTests;
            }
            if (this.keyMap.item === 'brand') {
              if (get(setData, 'meta.privateMenu')) {
                setData.privateMenu = get(setData, 'meta.privateMenu');
              }
              if (get(setData, 'meta.publicMenu')) {
                setData.publicMenu = get(setData, 'meta.publicMenu');
              }
              if (setData.meta && !get(setData, 'meta.validateOrderId')) {
                setData.meta.validateOrderId = get(setData, 'meta.validateOrderId', true);
              }
            }
            this.CREATE_FRM = FormHandle.setFormData(this.CREATE_FRM, setData);
            this.validateForm('CREATE_FRM', true);
            this.gStatus = 2;
          }
          this.setFieldValue('isActionInProgress', false);
        }
        this.currTime = +new Date();
      },
      onError: () => {
        if (!id) {
          uiStore.updateLoading(false);
          this.setFieldValue('isActionInProgress', false);
        }
      },
    });
  };

  loadMore = async () => {
    this.setLoading(true);
    await this.listData.ref.fetchMore({
      variables: { skip: this.data.length },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return Object.assign({}, previousResult, {
          [this.keyMap.all]: [
            ...previousResult[this.keyMap.all],
            ...fetchMoreResult[this.keyMap.all],
          ],
        });
      },
    });
    this.setLoading(false);
  };

  setFilter = (by, value, forced = false) => {
    this.params.filters = forced
      ? { [by]: value }
      : { ...this.params.filters, [by]: value };
  };

  unsetFilter = () => {
    this.params.filters = {};
  };


  setOrder = (by, direction, currentTab, filters = {}) => {
    if (currentTab) {
      this.params = { ...this.metaParams[currentTab], orderBy: `${by}_${direction}` }; // createdAt_DESC
    }
    else {
      this.params = { ...this.params, orderBy: `${by}_${direction}` }; // createdAt_DESC
    }

    if (filters.selectedStatus) {
      this.params.filters = { ...this.params.filters, status_in: [filters.selectedStatus] };
    }

    this.initLoad();
  };

  setParam = (newParam) => {
    this.params = { ...this.params, ...newParam };
  };

  setRefUrl = (refUrl) => {
    this.refUrl = refUrl || '';
  };

  setFieldValue = (field, value, objRef = false, isArray = false) => {
    if (isArray) {
      this[field] = this[field].concat(value);
    } else if (objRef) {
      const tempRef = this[field];
      this[field] = set(tempRef, objRef, value);
      this[field] = tempRef;
    } else {
      this[field] = value;
    }
    this.currTime = +new Date();
  }

  initSearch = (value, against) => {
    const filters = [];
    against.forEach((i) => {
      filters.push({ [i]: value });
    });
    let updatedFilters = { ...this.params.filters };

    updatedFilters = value
      ? { ...this.searchParam, OR: filters }
      : pickBy(updatedFilters, (f, key) => key !== 'OR');
    this.initLoad({ filters: updatedFilters });
  };

  formChange = (e, result, form, type) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (Array.isArray(form)) {
      this[formName] = FormHandle.onArrayFieldChange(
        this[formName],
        FormHandle.pullValues(e, result),
        form[1],
        form[2],
        type
      );
    } else {
      this[formName] = FormHandle.onChange(
        this[formName],
        FormHandle.pullValues(e, result),
        type
      );
    }
    this.currTime = +new Date();
  };

  editorChange = (field, value, form, ref, index = undefined, isMultiForm) => {
    if (isMultiForm) {
      this[form] = FormHandle.onArrayFieldChange(
        this[form],
        { name: field, value },
        ref,
        index,
      );
    } else {
      this[form] = FormHandle.onChange(
        this[form],
        { name: field, value },
      );
    }
    // if (index !== undefined) {
    //   this[form].fields[ref][index][field].value = value;
    //   this[form].fields[ref][index][field].isDirty = true;
    // } else {
    //   this[form].fields[field].value = value;
    //   this[form].fields[field].isDirty = true;
    // }
    // this[form] = FormHandle.validateForm(this[form], { isMultiForm });
    this.currTime = +new Date();
  }

  maskChange = (values, field, form, type) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const fieldValue = type ? values[`${type}Value`] : values.value; //floatValue
    if (Array.isArray(form)) {
      this[formName] = FormHandle.onArrayFieldChange(
        this[formName],
        { name: field, value: fieldValue },
        form[1],
        form[2]
      );
    } else {
      this[formName] = FormHandle.onChange(this[formName], {
        name: field,
        value: fieldValue,
      });
    }
    this.currTime = +new Date();
  };

  validateForm = (formName, isMultiForm = false) => {
    this[formName] = FormHandle.validateForm(this[formName], isMultiForm);
  };

  updateStatus = async (id, status) => {
    if (!status) return;
    this.auStatus = 1;
    try {
      const newStatus = Array.isArray(status)
        ? status.filter((s) => s !== this.details.status)[0]
        : status;
      let graphQlQuery = this.gqlRef.getOne;
      if (typeof this.gqlRef.getOne === 'function') {
        const roles = document.userMetaData || [];
        graphQlQuery = this.gqlRef.getOne(roles.includes('ADMIN'));
      }
      await this.client.mutate({
        mutation: this.gqlRef.updateStatus,
        variables: { id, status: newStatus },
        refetchQueries: [
          {
            query: graphQlQuery,
            variables: { id },
          },
        ],
      });
      Utils.toast(
        `${startCase(this.refKey)} status updated successfully.`,
        'success'
      );
      this.auStatus = 2;
    } catch (e) {
      this.auStatus = 0;
      Utils.toast(`Error while updating ${this.refKey} status.`, 'error');
    }
  };

  delete = async (id, action) => {
    if (!id) return;
    this.auStatus = 1;
    try {
      await this.client.mutate({
        mutation: this.gqlRef[action],
        variables: { id },
      });
      Utils.toast(`${startCase(this.refKey)} deleted successfully.`, 'success');
      this.auStatus = 2;
    } catch (e) {
      this.auStatus = 0;
      Utils.toast(`Error while deleting ${this.refKey}.`, 'error');
    }
  };

  jsonParser = async (jsonKeys = [], dataObj = {}) => {
    const data = JSON.parse(JSON.stringify(dataObj));
    jsonKeys.forEach(key => {
      if (get(data, key) && typeof get(data, key) === 'string') {
        try {
          set(data, key, JSON.parse(get(data, key)));
        } catch (e) {
          console.log(e);
          set(data, key, {});
        }
      }
    });
    return await data;
  };

  jsonStringify = (jsonKeys = [], dataObj = {}) => {
    const data = JSON.parse(JSON.stringify(dataObj));
    jsonKeys.forEach(key => {
      if (get(data, key) && typeof get(data, key) !== 'string') {
        try {
          set(data, key, JSON.stringify(get(data, key)));
        } catch (e) {
          console.log(e);
          set(data, key, {});
        }
      }
    });
    return data;
  };

  save = async (id, forcedParams, addonParams) => {
    this.auStatus = 1;
    this.noloaderEvent = true;
    try {
      let action = id ? 'update' : 'create';
      let formData = FormHandle.evaluateFormData(this.CREATE_FRM.fields);
      const isDropShipment = has(formData, 'dropShipment');
      if (get(addonParams, 'module') === 'panel') {
        if(!get(formData, 'ordrsLabPanel')) {
          formData = { ...formData, ordrsLabPanelDropdown: '', panelPrice: 0};
        } else {
          if(get(formData, 'ordrsLabPanelDropdown') === 'TESTCODEPRICE') {
            formData = { ...formData, panelPrice: 0};
          }
        }

        if (!get(formData, 'allowCorrectedResults')) {
          formData = { ...formData, allowCorrectedResults: false };
        }
        if (!get(formData, 'allowDynamicRangeRef')) {
          formData = { ...formData, allowDynamicRangeRef: false };
        }        
        if (get(addonParams, 'labMarkersIds[0]')) {
          formData = { ...formData, labMarkersIds: get(addonParams, 'labMarkersIds') };
        }
        if (get(addonParams, 'questionData') && !isEmpty(get(addonParams, 'questionData'))) {
          let metaDetails = get(formData, 'meta') || {};
          metaDetails = { ...metaDetails, ...get(addonParams, 'questionData') };
          set(formData, 'meta', metaDetails);
        } else {
          let metaDetails = get(formData, 'meta') || {};
          const intakeDetails = { intakeForm: this.getIntakeFormList };
          metaDetails = { ...metaDetails, ...intakeDetails };
          set(formData, 'meta', metaDetails);
        }
      }
      let params = forcedParams || cleanDeep(formData);
      if(formData.associatedUsersId && Array.isArray(formData.associatedUsersId) && formData.associatedUsersId.length === 0) {
        params.associatedUsersId = [];
      }
      if(formData.providerUsersId && Array.isArray(formData.providerUsersId) && formData.providerUsersId.length === 0) {
        if(formData.authType === 'PROVIDER') {
          this.CREATE_FRM = FormHandle.setFormData(this.CREATE_FRM, formData);
          this.auStatus = 0;
          this.noloaderEvent = false;
          return Utils.toast(
            'Please enter Providers',
            'error'
          );
        } else {
          params.providerUsersId = [];
        }
      }
      if (get(addonParams, 'module') === 'panel' && has(formData, 'contextDefaults')) {
        params.contextDefaults = !isEmpty(get(formData, 'contextDefaults')) ? get(formData, 'contextDefaults') : null;
      }

      if (isDropShipment) {
        params.dropShipment = get(formData, 'dropShipment') ? get(formData, 'dropShipment') : null;
      }
      if (get(addonParams, 'includeKeys[0]')) {
        get(addonParams, 'includeKeys').forEach(key => {
          if (!get(params, key)) {
            params = { ...params, [key]: get(formData, key) };
          }
        });
      }
      // hack for optional licenses which goes off after cleanDeep
      if (this.CREATE_FRM.fields.licenses) {
        params = {
          ...params,
          ...{ licenses: this.CREATE_FRM.fields.licenses.value },
        };
      }
      if (this.CREATE_FRM.fields.preference) {
        params = {
          ...params,
          ...{
            notification:
            {
              ...params.notification,
              preference: JSON.stringify(Utils.convertArrayToObject(this.CREATE_FRM.fields.preference, 'action'))
            }
          },
          preference: undefined
        };
      }
      params = action === 'create' ? (get(addonParams, 'module') === 'panel' && params.status) ? { ...params, status: params.status } : { ...params, status: undefined } : { ...params };

      if (get(addonParams, 'module') === 'panel' && get(formData, 'labApiIntegration') === 'QUEST') {
        const toggleIncompleteResult = isNil(get(formData, 'meta.allowIncompleteResults')) || get(formData, 'meta.allowIncompleteResults') === '' ? true : get(formData, 'meta.allowIncompleteResults');
        set(params, 'meta.allowIncompleteResults', toggleIncompleteResult);
      }


      // hack for create/update API user
      if (params.role === 'API') {
        action = `${action}${params.role}`;
        params.whitelistIp = Array.isArray(params.whitelistIp)
          ? params.whitelistIp
          : params.whitelistIp
            ? params.whitelistIp.split(',')
            : ['*'];
      }
      if (get(addonParams, 'jsonFields')) {
        params = this.jsonStringify(get(addonParams, 'jsonFields'), params);
      }
      let saveMutation = this.gqlRef[action];
      console.log(typeof this.gqlRef[action]);
      if (typeof this.gqlRef[action] === 'function') {
        const roles = document.userMetaData || [];
        saveMutation = this.gqlRef[action](roles.includes('ADMIN'));
      }
      console.log(typeof this.gqlRef[action]);
      let graphQlQuery = this.gqlRef.getOne;
      if (typeof this.gqlRef.getOne === 'function') {
        const roles = document.userMetaData || [];
        graphQlQuery = this.gqlRef.getOne(roles.includes('ADMIN'));
      }
      console.log('refetchQueriesrefetchQueriesrefetchQueries', graphQlQuery);

      if (get(addonParams, 'module') === 'panel') {
        params.panelRef = params.panelRef || null;
      }
      await this.client.mutate({
        mutation: saveMutation,
        variables: { id, ...params },
        refetchQueries: id
          ? [
            {
              query: graphQlQuery,
              variables: { id },
            },
          ]
          : [],
      });
      Utils.toast(
        `${startCase(this.refKey)} ${id ? 'updated' : 'created'} successfully.`,
        'success'
      );
      this.CREATE_FRM = FormHandle.setFormData(this.CREATE_FRM, params);

      this.auStatus = 2;
      this.noloaderEvent = false;
    } catch (e) {
      this.auStatus = 0;
      this.noloaderEvent = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while ${id ? 'updating' : 'creating'} ${this.refKey}.`,
        'error'
      );
    }
  };

  getFileUploadMutation = (type, returnType) => {
    if (returnType === 'mutation') {
      switch (type) {
        case 'ORDER': return getFileUploadUrlForOrder;
        case 'PANEL': return getFileUploadUrlForPanel;
        default: return getFileUploadUrlForOrder;
      }
    }
    if (returnType === 'name') {
      switch (type) {
        case 'ORDER': return 'getFileUploadUrlForOrder';
        case 'PANEL': return 'getFileUploadUrlForPanel';
        default: return getFileUploadUrlForOrder;
      }
    }
  }

  upload = async (id, files, type, panelPrefix = null) => {
    this.auStatus = 1;
    this.gStatus = 1;
    try {
      const file = files[0];
      const params = {
        fileName: file.name,
        contentType: file.type,
        orderId: type === 'ORDER' ? id : undefined,
        panelId: type === 'PANEL' ? id : undefined
      };

      const getFileUploadUrlMutation = this.getFileUploadMutation(type, 'mutation');
      const getFileUploadUrlName = this.getFileUploadMutation(type, 'name');

      const res = await this.client.mutate({
        mutation: getFileUploadUrlMutation,
        variables: params,
      });
      // const params = {
      //   fileName: file.name,
      //   contentType: file.type,
      //   entity: name,
      //   entityId: id,
      // };
      // const res = await this.client.mutate({
      //   mutation: getUploadSignedUrl,
      //   variables: params,
      // });
      // const { signedUrl, id: uploadId } = res.data.getUploadSignedUrl;
      const { uploadUrl, fileId } = res.data[getFileUploadUrlName];
      const roles = document.userMetaData || [];
      await apiService.upload(uploadUrl, file);
      if (this.gqlRef.attach) {
        if (type === 'ORDER') {
          await this.client.mutate({
            mutation: this.gqlRef.attach,
            variables: { orderId: id, fileId: fileId },
            refetchQueries: [
              {
                query: typeof this.gqlRef.getOne === 'function' ? this.gqlRef.getOne(roles.includes('ADMIN')) : this.gqlRef.getOne,
                variables: { id },
              },
            ],
          });
        }
        if (type === 'PANEL') {
          await this.client.mutate({
            mutation: this.gqlRef.attach,
            variables: { panelId: id, panelPrefix, fileId: fileId },
            refetchQueries: [
              {
                query: typeof this.gqlRef.getOne === 'function' ? this.gqlRef.getOne(roles.includes('ADMIN')) : this.gqlRef.getOne,
                variables: { id },
              },
            ],
          });
        }
      }
      this.auStatus = 2;
      this.gStatus = 2;
      Utils.toast(`Document attached successfully`, 'success');
    } catch (e) {
      this.auStatus = 0;
      this.gStatus = 0;
      console.log(e);
      Utils.toast(`Error while uploading`, 'error');
    }
  };

  uploadFileToPanel = async (name, id, files, panelPrefix) => {
    try {
      const file = files[0];
      const params = {
        fileName: file.name,
        contentType: file.type,
        entity: name,
        entityId: id,
      };
      const res = await this.client.mutate({
        mutation: getUploadSignedUrl,
        variables: params,
      });
      const roles = document.userMetaData || [];
      const { signedUrl, id: uploadId } = res.data.getUploadSignedUrl;
      await apiService.upload(signedUrl, file);
      if (this.gqlRef.attach) {
        await this.client.mutate({
          mutation: this.gqlRef.attach,
          variables: { panelId: id, panelPrefix, attachmentId: uploadId },
          refetchQueries: [
            {
              query: typeof this.gqlRef.getOne === 'function' ? this.gqlRef.getOne(roles.includes('ADMIN')) : this.gqlRef.getOne,
              variables: { id },
            },
          ],
        });
      }
      Utils.toast(`Document attached successfully`, 'success');
    } catch (e) {
      Utils.toast(`Error while uploading`, 'error');
    }
  };

  downloadFile = async (id, params) => {
    if (!id) return;
    const { addonProps } = params;
    const isHistoryDownload = !isEmpty(addonProps) && get(addonProps, 'historyProps');
    let variableProps = {};
    if (isHistoryDownload) {
      const contentType = get(addonProps, 'contentType');
      variableProps = contentType ? { key: get(addonProps, 'key'), fileName: get(addonProps, 'fileName'), contentType: contentType } : { key: get(addonProps, 'key'), fileName: get(addonProps, 'fileName') };
    } else {
      let orderId = get(params, 'type') === 'PANEL' ? 'PANEL_UPLOAD' : params.orderId;
      variableProps = { orderId, fileId: id };
    }
    this.noloaderEvent = true;
    MobxApollo.graphql({
      client: this.client,
      query: isHistoryDownload ? getDownloadLinkByFileNameKey : getDownloadLink,
      variables: variableProps,
      onFetch: (res) => {
        if (res) {
          const { signedUrl } = isHistoryDownload ? res.getDownloadLinkByFileNameKey : res.getDownloadLink;
          window.open(signedUrl);
          this.noloaderEvent = false;
        }
      },
      onError: () => {
        Utils.toast('Error while downloading.', 'error');
        this.noloaderEvent = false;
      },
    });
  };

  downloadRequisitionFile = async (orderId) => {
    MobxApollo.graphql({
      client: this.client,
      query: getLabOrderRequisitionPdfDownloadLink,
      variables: { orderId },
      onFetch: (res) => {
        if (res) {
          const { downloadUrl } = get(res, 'getLabOrderRequisitionPdfDownloadLink');
          if (downloadUrl)
            window.open(downloadUrl);
          this.noloaderEvent = false;
        }
      },
      onError: () => {
        Utils.toast('Error while downloading.', 'error');
        this.noloaderEvent = false;
      },
    });
  };



  setMediaAttribute = (form, attr, value, field, index = -1, arrayName) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (attr) {
      if (index > -1) {
        this.setFormKeyData(formName, field, value, attr, arrayName, index);
      } else {
        this.setFormKeyData(formName, field, value, attr);
      }
    }
  }

  setFormKeyData = (form, field, elementValue, fieldKey = 'value', subForm = false, index = -1) => {
    if (subForm && index > -1) {
      this[form].fields[subForm][index][field][fieldKey] = elementValue;
    } else if (subForm) {
      this[form][subForm].fields[field][fieldKey] = elementValue;
    } else {
      this[form].fields[field][fieldKey] = elementValue;
    }
    if (fieldKey === 'error' && elementValue) {
      this[form].meta.isValid = false;
    }
    this.currTime = +new Date();
  }

  resetImageCropper = (form, field, index = -1, arrayName) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const attributes = ['fileName', 'fileType', 'fileSize', 'error', 'base64String', 'showLoader', 'value', 'confirmModal'];
    attributes.forEach((val) => {
      const typeCheck = index > -1 ? this[formName].fields[arrayName][index][field][val] : this[formName].fields[field][val];
      if ((typeof typeCheck === 'object') && (typeCheck !== null)) {
        if (index > -1) {
          this[formName].fields[arrayName][index][field][val] = val === 'isDirty' ? true : {};
        } else {
          this[formName].fields[field][val] = val === 'isDirty' ? true : {};
        }
      } else if (index > -1) {
        this[formName].fields[arrayName][index][field][val] = val === 'isDirty' ? true : ['showLoader', 'confirmModal'].includes(val) ? false : '';
      } else {
        this[formName].fields[field][val] = val === 'isDirty' ? true : ['showLoader', 'confirmModal'].includes(val) ? false : '';
      }
    });
    this.currTime = +new Date();
  }

  uploadMedia = async (name, form, params) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const arrayName = Array.isArray(form) ? form[1] : false;
    const index = Array.isArray(form) ? form[2] : -1;
    try {
      const field = index > -1 ? this[formName].fields[arrayName][index][name] : this[formName].fields[name];
      const { base64String, fileName, fileType, type, fileSize } = field;
      const fileObj = {
        fileData: Utils.isBase64(base64String) ? Utils.b64toBlob(base64String) : base64String,
        fileName: `${moment().unix()}_${Utils.sanitize(fileName)}`,
        contentType: fileType,
        fileSize: fileSize.toString(),
        entity: type || get(params, 'type'),
        entityId: get(params, 'resourceId')
      };
      this.setMediaAttribute(formName, 'showLoader', true, name, index, arrayName);
      // uploading file to S3 bucket or any other services as per identifier.
      const res = await this.uploadObject(fileObj, params);
      this.setMediaAttribute(formName, 'value', get(res, 'url'), name, index, arrayName);
      this.setMediaAttribute(formName, 'id', get(res, 'fileId'), name, index, arrayName);
      this.setMediaAttribute(formName, 'isDirty', true, name, index, arrayName);
      this.setMediaAttribute(formName, 'showLoader', false, name, index, arrayName);
    } catch (err) {
      console.log(err);
      this.setMediaAttribute(formName, 'showLoader', false, name, index, arrayName);
    }
    this.currTime = +new Date();
  };

  removeMedia = (name, form, index, arrayName) => new Promise(async (resolve, reject) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const arrayNameKey = Array.isArray(form) ? form[1] : false;
    const indexKey = Array.isArray(form) ? form[2] : -1;
    try {
      const field = indexKey > -1 ? this[formName].fields[arrayNameKey][indexKey][name] : this[formName].fields[name];
      const payload = { id: field.id };
      this.setMediaAttribute(formName, 'showLoader', true, name, index, arrayName);
      const res = await this.client.mutate({
        mutation: deleteAttachment,
        variables: payload,
      });
      if (res) {
        this.resetImageCropper(form, name, index, arrayName);
      }
      this.setMediaAttribute(formName, 'showLoader', false, name, index, arrayName);
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });

  uploadObject = (payload, params = {}) => new Promise(async (resolve, reject) => {
    try {
      if (params.type === 'PANEL_META') {
        const panelParam = {
          fileName: get(payload, 'fileName'),
          contentType: get(payload, 'contentType'),
          panelId: get(params, 'resourceId')
        };
        const res = await this.client.mutate({
          mutation: getFileUploadUrlForPanelMeta,
          variables: panelParam,
        });
        await apiService.uploadOnS3(get(res, 'data.getFileUploadUrlForPanelMeta.uploadUrl'), payload.fileData, payload.contentType);
        setTimeout(() => {
          resolve(get(res, 'data.getFileUploadUrlForPanelMeta'));
        }, 500);
      }

      if (params.type === 'BRAND_META') {
        const panelParam = {
          fileName: get(payload, 'fileName'),
          contentType: get(payload, 'contentType'),
          brandId: get(params, 'resourceId')
        };
        const res = await this.client.mutate({
          mutation: getFileUploadUrlForBrandMeta,
          variables: panelParam,
        });
        await apiService.uploadOnS3(get(res, 'data.getFileUploadUrlForBrandMeta.uploadUrl'), payload.fileData, payload.contentType);
        setTimeout(() => {
          resolve(get(res, 'data.getFileUploadUrlForBrandMeta'));
        }, 500);
      }
    } catch (err) {
      reject(err);
    }
  });

  removeFile = async (id, info = null) => {
    if (!id) return;
    try {
      const roles = document.userMetaData || [];
      if (info && !get(info, 'isOrder')) {
        await this.client.mutate({
          mutation: removeFileFromPanel,
          variables: { panelId: info.panelId, panelPrefix: info.panelPrefix, attachmentId: info.attachmentId },
          refetchQueries: [
            {
              query: typeof this.gqlRef.getOne === 'function' ? this.gqlRef.getOne(roles.includes('ADMIN')) : this.gqlRef.getOne,
              variables: { id },
            },
          ],
        });
      } else {
        await this.client.mutate({
          mutation: removeFile,
          variables: { id: info.id, attachmentId: info.attachmentId },
          refetchQueries: [
            {
              query: typeof this.gqlRef.getOne === 'function' ? this.gqlRef.getOne(roles.includes('ADMIN')) : this.gqlRef.getOne,
              variables: { id },
            },
          ],
        });
      }

      Utils.toast(`Attachment removed successfully.`, 'success');
    } catch (e) {
      Utils.toast(`Error while removing attachment`, 'error');
    }
  };

  reset = (form, userType = null) => {
    if (form) {
      this[form] = FormHandle.resetFormData(this[form]);
      if (!isNull(userType)) {
        this.resetNotificationForUser(userType, form);
      }
    }
    this.params = { orderBy: 'createdAt_DESC' };
  };

  forceToResetForm = (form) => {
    this[form] = FormHandle.resetFormData(this[form]);
    this.currTime = +new Date();
  }

  resetNotificationForUser = (userType, form) => {
    const defaultNotification = Utils.convertObjectToArray(NOTIFICATION_ACTION_LIST[userType], 'action');
    let preference = [];
    let i = 0;
    defaultNotification.forEach(defaultSettings => {
      preference[i] = JSON.parse(JSON.stringify(this[form].fields.preference[0]));
      preference[i].action.value = defaultSettings.action;
      preference[i].email.value = defaultSettings.email;
      preference[i].webhook.value = defaultSettings.webhook;
      i += 1;
    });
    this[form].fields.preference = preference;
  }


  resetAll = () => {
    this.client.clearStore();
  };

  addMore = (form, key, count = 1) => {
    this[form] = FormHandle.addMoreRecordToSubSection(this[form], key, count, true);
    this.currTime = +new Date();
  }

  removeOne = (form, arrayName, index, e = undefined) => {
    if (e) {
      e.preventDefault();
    }
    this[form].fields[arrayName].splice(index, 1);
    this.currTime = +new Date();
  }

  setLoading = status => this.isLoading = status;

  loading() {
    return this.isLoading;
    // return MobxApollo.loading || false;
  }

  data() {
    getData(MobxApollo.loading, this.currTime);
    return toJS(this.result) || [];
  }

  dataPages() {
    getData(MobxApollo.loading, this.currTime);
    return toJS(this.pages) || [];
  }

  details() {
    return getData(this.currentItem, `data.${this.keyMap.item}`) || {};
  }

  count() {
    const meta = toJS(this.meta);
    return getData(meta, 'count') || 0;
  }

  setTargetClient = (params) => {
    this.targetClient = ((['localhost'].includes(DEPLOY_ENV) && params.environment === 'DEV') || DEPLOY_ENV === lowerCase(params.environment)) ? this.client : dynamicEnvGqlApi(params);
  }

  getToken = (tokenVal) => {
    const token = this.isValidJson(tokenVal);
    if (token && typeof (token) === 'object') {
      const tokenValue = token['authorization'];
      return tokenValue.replace(/Bearer | \s/g, '');
    } else if (typeof (tokenVal) === 'string' && tokenVal.includes("Bearer")) {
      return tokenVal.replace(/Bearer | \s/g, '');
    } else {
      return tokenVal
    }
  }
  isValidJson = (param) => {
    try {
      const token = JSON.parse(param);
      return token;
    } catch (e) {
      return false;
    }
  }

  setFormData = (form, elemRef, elementValue, subForm = false) => {
    if (subForm || subForm === 0) {
      this[form][subForm].fields[elemRef].value = elementValue;
    } else {
      this[form].fields[elemRef].value = elementValue;
    }
    this.currTime = +new Date();
  }

  checkForSuperAdminAccess = () => {
    const { currentSession } = authStore;
    return Utils.isSuperAdmin(get(currentSession, 'email'));
  }
}
