import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Image } from 'semantic-ui-react';

class Image64 extends Component {
  state = {
    count: 1,
    data: '',
    emptyImg: '',
  }
  constructor(props) {
    super(props);
    setTimeout(() => {
      this.getImage();
    }, props.timeout || 0);
  }

  stateUpdate = (field, val) => {
    this.setState({ [field]: val });
  }

  getImage = () => {
    const { srcUrl } = this.props;
    const { emptyImg } = this.state;
    const UPLOADS_CONFIG = '';
    if (srcUrl) {
      const imgUrl = (srcUrl.includes('https://') || srcUrl.includes('http://')) ? srcUrl : `https://${UPLOADS_CONFIG.bucket}/${srcUrl}`;
      try {
        this.stateUpdate('data', imgUrl);
      } catch (e) {
        this.stateUpdate('data', emptyImg);
      }
    } else {
      this.stateUpdate('data', emptyImg);
    }
  };

  handelOnError = (e) => {
    const { count, data, emptyImg } = this.state;
    if (count < 10) {
      e.target.error = null;
      if (data) {
        e.target.src = `${data}`;
      } else {
        e.target.src = emptyImg;
      }
      this.stateUpdate('count', count + 1);
    }
  }
  render() {
    return (this.props.bg ? (
      <div {...this.props} style={{ backgroundImage: `url(${this.state.data})` }} />
    ) : (
      <Image {...this.props} src={`${this.state.data}`} onError={this.handelOnError} />
    ))
  }
}

export default (observer(Image64));
