import gql from "graphql-tag";

const commonUpdate = {
  p1: `
		$name: String!,
		$version: PanelVersionEnum,
		$type: PanelTypeEnum,
		$prefix: String!,
		$gender: GenderEnum,
		$minAge: Int,
		$maxAge: Int,
		$brandId: ID,
		$labId: ID,
		$meta: String,
		$brandNotificationEmail: String,
		$labNotificationEmail: String,
		$status: PanelStatusEnum,
		$ineligibleStates: [String],
		$ordrsLabPanel: Boolean,
		$ordrsLabPanelDropdown: String,
		$panelPrice: Float,
		$config: PanelConfigInputType,
		$panelRef: String,
		$panelDesc: String,
  `,
  p2: `
		name: $name,
		version: $version,
		type: $type,
		prefix: $prefix,
		gender: $gender,
		minAge: $minAge,
		maxAge: $maxAge,
		meta: $meta,
		brandId: $brandId,
		brandNotificationEmail: $brandNotificationEmail,
		labId: $labId,
		labNotificationEmail: $labNotificationEmail,
		status: $status,
		ineligibleStates: $ineligibleStates
		ordrsLabPanel: $ordrsLabPanel
		ordrsLabPanelDropdown: $ordrsLabPanelDropdown
		panelPrice: $panelPrice
		config: $config
		panelRef: $panelRef
		panelDesc: $panelDesc
	`,
  a1: `
		$name: String!,
		$version: PanelVersionEnum,
		$type: PanelTypeEnum,
		$prefix: String!,
		$gender: GenderEnum,
		$minAge: Int,
		$maxAge: Int,
		$brandId: ID,
		$labId: ID,
		$meta: String,
		$brandNotificationEmail: String,
		$labNotificationEmail: String,
		$labApiIntegration: String,
		$status: PanelStatusEnum,
		$ineligibleStates: [String],
		$ordrsLabPanel: Boolean,
		$ordrsLabPanelDropdown: String,
		$panelPrice: Float,
		$config: PanelConfigInputType
		$pluginMeta: String,
		$contextDefaults: String,
		$panelRef: String
		$panelDesc: String
		$labMarkersIds: [ID!]
  `,
  a2: `
		name: $name,
		version: $version,
		type: $type,
		prefix: $prefix,
		gender: $gender,
		minAge: $minAge,
		maxAge: $maxAge,
		meta: $meta,
		brandId: $brandId,
		brandNotificationEmail: $brandNotificationEmail,
		labId: $labId,
		labNotificationEmail: $labNotificationEmail,
		labApiIntegration: $labApiIntegration
		status: $status,
		ineligibleStates: $ineligibleStates
		ordrsLabPanel: $ordrsLabPanel
		ordrsLabPanelDropdown: $ordrsLabPanelDropdown
		panelPrice: $panelPrice
		config: $config
		pluginMeta: $pluginMeta,
		contextDefaults: $contextDefaults,
		panelRef: $panelRef
		panelDesc: $panelDesc
		labMarkersIds: $labMarkersIds
  `,
};

const commonCreate = {
  p1: `
		$name: String!,
		$version: PanelVersionEnum!,
		$type: PanelTypeEnum!,
		$prefix: String!,
		$gender: GenderEnum,
		$minAge: Int,
		$maxAge: Int,
		$brandId: ID,
		$labId: ID,
		$meta: String,
		$brandNotificationEmail: String,
		$labNotificationEmail: String,
		$ineligibleStates: [String],
		$config: PanelConfigInputType
		$panelRef: String
		$panelDesc: String
	`,
  p2: `
		name: $name,
		version: $version,
		type: $type,
		prefix: $prefix,
		gender: $gender,
		minAge: $minAge,
		maxAge: $maxAge,
		brandId: $brandId,
		meta: $meta,
		brandNotificationEmail: $brandNotificationEmail,
		labId: $labId,
		labNotificationEmail: $labNotificationEmail,
		ineligibleStates: $ineligibleStates,
		config: $config
		panelRef: $panelRef
		panelDesc: $panelDesc
	`,
  a1: `
		$name: String!,
		$version: PanelVersionEnum!,
		$type: PanelTypeEnum!,
		$prefix: String!,
		$gender: GenderEnum,
		$minAge: Int,
		$maxAge: Int,
		$brandId: ID,
		$labId: ID,
		$meta: String,
		$brandNotificationEmail: String,
		$labNotificationEmail: String,
		$labApiIntegration: String,
		$ineligibleStates: [String],
		$markers: [PanelMarkerInputType!]
		$config: PanelConfigInputType
		$pluginMeta: String,
		$contextDefaults: String,
		$panelRef: String
		$panelDesc: String
	`,
  a2: `
		name: $name,
		version: $version,
		type: $type,
		prefix: $prefix,
		gender: $gender,
		minAge: $minAge,
		maxAge: $maxAge,
		brandId: $brandId,
		meta: $meta,
		brandNotificationEmail: $brandNotificationEmail,
		labId: $labId,
		labNotificationEmail: $labNotificationEmail,
		labApiIntegration: $labApiIntegration
		ineligibleStates: $ineligibleStates,
		markers: $markers,
		config: $config
		pluginMeta: $pluginMeta,
		contextDefaults: $contextDefaults,
		panelRef: $panelRef
		panelDesc: $panelDesc
	`,
};

const adminBrandPanelActivity = `
activity {
	activity
	type
	justification
	createdAt
	createdBy {
	id
	firstName
	lastName
	}
}
`;

export const allPanels = (isAdmin = false) => {
  return gql`
  query allPanels($first: Int, $skip: Int, $orderBy: PanelOrderBy, $filter: PanelFilter, $userId: ID) {
    panels(first: $first, skip: $skip, orderBy: $orderBy, filter: $filter, userId: $userId) {
		id
		name
		version
		type
		prefix
		gender
		minAge
		maxAge
		status
		ineligibleStates
		${isAdmin ? `labApiIntegration` : ``}
		${isAdmin ? `pluginMeta` : ``}
		${isAdmin ? `contextDefaults` : ``}
		lab {
			id
			name
		}
		brand {
			id
			name
		}
		markers {
			id
			name
			markerRef
			markerDesc
		}
		optedInAt
		labTests {
			id
			name
			testDesc
			testCode
			${isAdmin ? `testPrice` : ``}
			reflex
		}
    }
    panelsMeta(filter: $filter) {
      count
    }
  }
`;
};

export const createPanel = (isAdmin = false) => {
  return gql`
	mutation createPanel(${isAdmin ? commonCreate.a1 : commonCreate.p1}) {
		createPanel(${isAdmin ? commonCreate.a2 : commonCreate.p2}) {
			id
		}
	}
`;
};

export const updatePanel = (isAdmin = false) => {
  return gql`
  mutation updatePanel($id: ID!, ${
    isAdmin ? commonUpdate.a1 : commonUpdate.p1
  }) {
    updatePanel(id: $id, ${isAdmin ? commonUpdate.a2 : commonUpdate.p2}) {
			id
		}
  }
`;
};

export const panelAttachLabTests = gql`
  mutation updatePanel(
    $id: ID!
    $labTestIds: [ID!]
  ) {
    updatePanel(id: $id, labTestIds: $labTestIds) {
      id
    }
  }
`;

export const updatePanelLabTests = gql`
  mutation updatePanelLabTests(
    $panelId: ID!
    $labTestIds: [ID!]!
  ) {
    updatePanelLabTests(
      panelId: $panelId
      labTestIds: $labTestIds
    ) {
	  id
	}
  }
`;

export const updateStatus = gql`
  mutation updatePanelStatus($id: ID!, $status: PanelStatusEnum!) {
    updatePanelStatus(id: $id, status: $status) {
      id
    }
  }
`;

export const panel = (isAdmin = false, isBrand = false) => {
  return gql`
  query panel($id: ID!) {
    panel(id: $id) {
			id
			version
			type
			name
			prefix
			meta
			gender
			minAge
			maxAge
			status
			optedInAt
			brandNotificationEmail
			labNotificationEmail
			panelRef
			panelDesc
			${isAdmin ? `labApiIntegration` : ``}
			${isAdmin ? `ordrsLabPanel` : ``}
			${isAdmin ? `ordrsLabPanelDropdown` : ``}
			${isAdmin ? `panelPrice` : ``}
			${isAdmin ? `pluginMeta` : ``}
			${isAdmin ? `contextDefaults` : ``}
			config{
				provisionalApprove
				expeditedRelease
				isReportRequired
			}
			ineligibleStates
				lab {
					id
					name
				}
				brand {
					id
					name
				}
				labMarkersIds
				labMarkers {
					id
					name
					markerRef
					markerDesc
					resultCode
					specimen
					units
					ranges {
						id
						name
						type
						value
						rangeValue:value
						isThreshold
						isCritical
						isReportable
						rangeType
					}
				}
				markers {
					id
					name
					markerRef
					markerDesc
					resultCode
					specimen
					units
					ranges {
						id
						name
						type
						value
						rangeValue:value
						isThreshold
						isCritical
						isReportable
						rangeType
					}
			}
			labTestIds
			attachmentsInfo {
				fileId
				fileName
				contentType
				createdAt
			}
			labTests {
				id
				name
				testDesc
				testCode
				${isAdmin ? `testPrice` : ``}
				results {
					id
					name
					markerRef
					resultCode
					specimen
					units
					ranges {
						id
						name
						type
						value
						isCritical
						isThreshold
						rangeType
						isReportable
					}
					markerDesc
					reflex
				}
				reflex
			}
			${isAdmin || isBrand ? adminBrandPanelActivity : ``}			
		}
	}
`;
};

export const createMarker = gql`
  mutation createPanelMarker(
    $panelId: ID!
    $name: String!
    $markerRef: String
    $markerDesc: String
    $resultCode: String
    $specimen: SpecimanEnum!
    $units: String!
    $ranges: [PanelMarkerRangeInputType]
  ) {
    createPanelMarker(
      panelId: $panelId
      name: $name
      markerRef: $markerRef
      markerDesc: $markerDesc
      resultCode: $resultCode
      specimen: $specimen
      units: $units
      ranges: $ranges
    ) {
      id
    }
  }
`;

export const updateMarker = gql`
  mutation updatePanelMarker(
    $id: ID!
    $panelId: ID!
    $name: String!
    $markerRef: String
    $markerDesc: String
    $resultCode: String
    $specimen: SpecimanEnum!
    $units: String!
    $ranges: [PanelMarkerRangeInputType]
  ) {
    updatePanelMarker(
      id: $id
      panelId: $panelId
      name: $name
      markerRef: $markerRef
      markerDesc: $markerDesc
      resultCode: $resultCode
      specimen: $specimen
      units: $units
      ranges: $ranges
    ) {
      id
      markerRef
    }
  }
`;

export const panelSubscription = gql`
  mutation panelSubscription(
    $id: ID!
    $userId: ID
    $status: PanelSubscriptionStatusEnum!
  ) {
    panelSubscription(id: $id, userId: $userId, status: $status) {
      id
      optedInAt
    }
  }
`;

export const attachFileToPanel = gql`
  mutation attachFileToPanel(
    $panelId: ID
    $panelPrefix: String
    $attachmentId: ID!
  ) {
    attachFileToPanel(
      panelId: $panelId
      panelPrefix: $panelPrefix
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

export const attachFileUploadToPanel = gql`
  mutation attachFileUploadToPanel(
    $panelId: ID
    $panelPrefix: String
    $fileId: ID!
  ) {
    attachFileUploadToPanel(
      panelId: $panelId
      panelPrefix: $panelPrefix
      fileId: $fileId
    ) {
      success
    }
  }
`;

export const addKitIdsToPanel = gql`
  mutation addKitIdsToPanel(
    $panelId: ID
    $kitIds: [String]
    $type: String
    $length: Int
    $prefix: String
    $postfix: String
    $quantity: Int
	$plugin: String
  ) {
    addKitIdsToPanel(
      panelId: $panelId
      kitIds: $kitIds
      type: $type
      length: $length
      prefix: $prefix
      postfix: $postfix
      quantity: $quantity
	  plugin: $plugin
    ) {
      success
      skipped
      collision
    }
  }
`;

export const getKitIdInventory = gql`
  query getKitIdInventory(
    $first: Int
    $skip: Int
    $filter: InventoryFilter
    $orderBy: InventoryOrderBy
  ) {
    getKitIdInventory(
      first: $first
      skip: $skip
      filter: $filter
      orderBy: $orderBy
    ) {
      id
      kitId
      panel {
        id
        prefix
      }
      order {
        id
        orderId
      }
      createdAt
      createdBy {
        id
        firstName
      }
      registeredAt
    }
    getKitIdInventoryMeta(filter: $filter) {
      count
    }
  }
`;

export const getKitIdInventoryMeta = gql`
  query getKitIdInventoryMeta($filter: InventoryFilter) {
    getKitIdInventoryMeta(filter: $filter) {
      count
    }
  }
`;

export const deleteKitIdsOnPanel = gql`
  mutation deleteKitIdsOnPanel($panelPrefix: String, $kitIds: [String]!) {
    deleteKitIdsOnPanel(panelPrefix: $panelPrefix, kitIds: $kitIds) {
      deleted
      skipped
      collision
    }
  }
`;
