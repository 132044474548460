import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, Popup, Icon, TextArea } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
// import parse from 'html-react-parser';
import { FieldError } from '../../shared';

class FormTextarea extends Component {
  constructor(props) {
    super(props);
    this.state = { showError: false };
    if (this.props.defaultValue && (this.props.fielddata.value === '' || this.props.fielddata.value === undefined)) {
      this.props.changed({}, { name: this.props.name, value: this.props.defaultValue });
    }
  }

  triggerError = (val) => {
    this.setState({ showError: val });
  }
  render() {
    const { props } = this;
    const {
      label,
      error,
      tooltip,
      placeHolder,
      defaultValue,
      value,
    } = props.fielddata;
    const { displayMode, readOnly, addonProps } = props;
    const classes = `${props.containerclassname || ''} ${props.readOnly ? 'display-only' : ''}`;
    const customToolTip = !isEmpty(get(addonProps, 'tooltip'));
    const toolTopPros = get(addonProps, 'toolTipProps');

    return (
      <Form.Field className={classes} error={(!!error && this.state.showError)}>
        {!props.isHideLabel && label !== '' &&
          <label>
            {props.label || label}
            {tooltip &&
              <Popup
                trigger={<Icon className="help circle" />}
                content={tooltip}
                position="top center"
                className="center-align"
                wide
              />
            }
            {customToolTip &&
              <Popup
                trigger={<Icon className="help circle" />}
                // content={addonProps.tooltip}
                position="top left"
                className="left-align"
                wide
                {...toolTopPros}
              >
                <Popup.Content>
                  {addonProps.tooltip}
                  {/* {parse(addonProps.tooltip)} */}
                </Popup.Content>
              </Popup>
            }

            {props.removed &&
              <Link to={props.linkto} onClick={e => props.removed(e)}>
                <Icon className="close-circle" color="grey" />
              </Link>
            }
          </label>
        }
        {props.readOnly ?
          <p className="commet-area">{value}</p> :
          <TextArea
            {...props}
            value={value === '' ? undefined : value}
            label={label}
            placeholder={(displayMode || readOnly) ? '' : placeHolder}
            defaultValue={props.defaultValue ? props.defaultValue : defaultValue}
            onChange={(e) => {
              props.changed(e, { name: e.target.name, value: e.target.value });
              this.triggerError(false);
            }}
            onBlur={() => this.triggerError(true)}
          />
        }
        {error && this.state.showError &&
          <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default observer(FormTextarea);