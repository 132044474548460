import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Form, Popup, Icon, Input } from 'semantic-ui-react';
import { FieldError } from '../../shared';

class FormInput extends Component {
  state = { showError: false, showPassword: false };
  triggerError = (val) => {
    this.setState({ showError: val });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { props } = this;
    const {
      label,
      error,
      tooltip,
      placeHolder,
      defaultValue,
      value,
    } = props.fielddata;
    const maxlength = props.fielddata.maxLength ? props.fielddata.maxLength : (
      props.maxLength ? props.maxLength : false
    );
    const { displayMode, readOnly, dataid, togglePassword, type, icon } = props;
    const fieldClass = `${props.containerclassname || ''} ${displayMode ? ' display-only' : ''}`;
    return (
      <Form.Field
        width={props.containerwidth || false}
        className={fieldClass}
        error={(!!error && this.state.showError) || (!!error && props.showerror)}
      >
        {!props.isHideLabel && label !== '' &&
          <label>
            {props.label || label}
            {tooltip &&
              <Popup
                hoverable={props.hoverable}
                trigger={<Icon className="help circle" />}
                content={tooltip}
                position="top center"
                className="center-align"
                wide
              />
            }
          </label>
        }
        {props.type === 'password' &&
          <input style={{ opacity: 0, position: 'absolute', width: 0 }} tabIndex={-1} value="something" />
        }
        <Input
          fluid
          autoComplete="nope"
          maxLength={maxlength || false}
          placeholder={(displayMode || readOnly) ? '' : placeHolder}
          icon={(icon || togglePassword) && <Icon onClick={togglePassword ? this.handleClickShowPassword : false} name={togglePassword ? (this.state.showPassword ? 'eye' : 'eye slash') : icon} link />}
          onChange={
            (e) => {
              props.changed(e, { name: e.target.name, value: e.target.value, dataid });
              this.triggerError(props.showerror || false);
            }}
          onBlur={
            (e) => {
              props.changed(e, { name: e.target.name, value: e.target.value.trim(), dataid })
              this.triggerError(true);
              if (props.onblur) {
                this.props.onblur(e.target.value.trim());
              }
            }
          }
          readOnly={displayMode}
          {...props}
          type={(togglePassword && this.state.showPassword) ? 'text' : (type || 'text')}
          // value={value || null}
          value={value || defaultValue ||''}
          defaultValue={defaultValue}
          label={props.prefix || false}
        />
        {((error && this.state.showError) || (error && props.showerror)) &&
          <FieldError error={error} />
        }
      </Form.Field>
    );
  }
}

export default observer(FormInput);
