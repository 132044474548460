import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { get } from 'lodash';
import { Form, Popup, Icon, Dropdown } from 'semantic-ui-react';
import { FieldError } from '../../shared';

const FormDropDown = observer((props) => {
  const [hidePlaceHolder, setHidePlaceHolder] = useState(false);

  useEffect(() => {
    if (((Array.isArray(toJS(value)) && get(toJS(value), '[0]')) || (!Array.isArray(toJS(value)) && toJS(value)))) {
      setHidePlaceHolder(true);
    }
  }, [hidePlaceHolder]);

  const {
    label,
    error,
    value,
    tooltip,
    placeHolder,
    values,
    defaultValue,
  } = props.fielddata;
  let selectedValue = value;
  if(props.fromLicense) {
    selectedValue = props.selectedValue
  } else {
    selectedValue = toJS(value);
  }
  return (
    <Form.Field error={error} width={props.containerwidth || false} className={props.containerclassname || ''}>
      {!props.isHideLabel && label !== '' &&
        <label>
          {label}
          {(props.tooltip || tooltip) &&
            <Popup
              trigger={<Icon className="help circle" />}
              content={props.tooltip || tooltip}
              position="top center"
              className="center-align"
              wide
            />
          }
        </label>
      }
      <Dropdown options={values} defaultValue={defaultValue} {...props} onOpen={() => setHidePlaceHolder(true)} onClose={() => setHidePlaceHolder(false)} value={selectedValue} placeholder={placeHolder} />
      {error &&
        <FieldError error={error} />
      }
    </Form.Field>
  );
});

export default FormDropDown;
