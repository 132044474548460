import { ADDRESS, PHONE, EMAIL, WEBHOOK_URL, NOTIFICATION_NAME_EMAIL, ADMIN_NOTE, PREFERENCE, GDS } from './shared';

  export const BRAND_THEMES = [
  {key: 'live-smart',text: 'LIVE SMART',value: 'live-smart'},
  {key: 'ebien',text: 'EBIEN',value: 'ebien'},
  ]
  const LOGO_POSITION = [
    {key: 'LEFT',text: 'LEFT',value: 'LEFT'},
    {key: 'CENTER',text: 'CENTER',value: 'CENTER'},
    {key: 'RIGHT',text: 'RIGHT',value: 'RIGHT'},
    ]
  const FILE_UPLOAD = {
    fileName: '',
    fileType: '',
    fileSize: '',
    id: '',
    base64String: '',
    tags: [],
    showLoader: false,
    confirmModal: false,
    objType: 'fileUpload',
  };

  export const META_PRIVATE_NAV = {
    privateMenu: [{
      title: {
        value: '',
        error: undefined,
        label: 'NAME',
        rule: 'optional',
        // objRef: 'meta',
        objRefOutput: 'meta',
      },
      to: {
        value: '',
        error: undefined,
        label: 'URL',
        rule: 'optional',
        // objRef: 'meta',
        objRefOutput: 'meta',
      },
      newTab: {
        value: false,
        error: undefined,
        label: 'OPEN IN NEW TAB',
        rule: 'optional',
        // objRef: 'meta',
        objRefOutput: 'meta',
        default: false
      },
      extLink: {
        value: false,
        error: undefined,
        label: 'EXTERNAL LINK',
        rule: 'optional',
        // objRef: 'meta',
        objRefOutput: 'meta',
        default: false
      }
    }]
  }

export const LOGO_URL = {
  backText: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Back Url Text',
    label: 'BACK TEXT',
    rule: 'optional',
    objRefOutput: 'meta.backInfo',
    objRef:'meta.backInfo'
  },
  backUrl: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Back Url',
    label: 'BACK URL',
    rule: 'optional',
    objRefOutput: 'meta.backInfo',
    objRef:'meta.backInfo'
  },
  backUrlNewTab: {
    value: false,
    error: undefined,
    label: 'OPEN IN NEW TAB',
    rule: 'optional',
    objRefOutput: 'meta.backInfo',
    objRef:'meta.backInfo',
    default: false
  },
  backUrlExtLink: {
    value: false,
    error: undefined,
    label: 'EXTERNAL LINK',
    rule: 'optional',
    objRefOutput: 'meta.logoInfo',
    objRef:'meta.logoInfo',
    default: false
  },
  logoUrl: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Logo Url',
    label: 'LOGO URL',
    rule: 'optional',
    objRefOutput: 'meta.logoInfo',
    objRef:'meta.logoInfo'
  },
  logoUrlNewTab: {
    value: false,
    error: undefined,
    label: 'OPEN IN NEW TAB',
    rule: 'optional',
    objRefOutput: 'meta.logoInfo',
    objRef:'meta.logoInfo',
    default: false
  },
  logoUrlExtLink: {
    value: false,
    error: undefined,
    label: 'EXTERNAL LINK',
    rule: 'optional',
    objRefOutput: 'meta.logoInfo',
    objRef:'meta.logoInfo',
    default: false
  }
}

export const META_PUBLIC_NAV = {
  publicMenu: [{
    ptitle: {
      value: '',
      error: undefined,
      label: 'NAME',
      rule: 'optional',
      // objRef: 'meta',
      objRefOutput: 'meta',
    },
    pto: {
      value: '',
      error: undefined,
      label: 'URL',
      rule: 'optional',
      // objRef: 'meta',
      objRefOutput: 'meta',
    },
    pnewTab: {
      value: false,
      error: undefined,
      label: 'OPEN IN NEW TAB',
      rule: 'optional',
      // objRef: 'meta',
      objRefOutput: 'meta',
      default: false
    },
    pextLink: {
      value: false,
      error: undefined,
      label: 'EXTERNAL LINK',
      rule: 'optional',
      // objRef: 'meta',
      objRefOutput: 'meta',
      default: false
    }
  }]
}
const PLUGIN_META = {
  sendingFacility: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Sending Facility',
    label: 'SENDING FACILITY',
    rule: 'optional',
    objRef: 'pluginMeta.quest',
    objRefOutput: 'pluginMeta.quest',
  },
  receivingFacility: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Receiving Facility',
    label: 'RECEIVING FACILITY',
    rule: 'optional',
    objRef: 'pluginMeta.quest',
    objRefOutput: 'pluginMeta.quest',
  },
  questUsername: {
    value: '',
    error: undefined,
    placeHolder: 'Enter User Name',
    label: 'USER NAME',
    rule: 'optional',
    objRef: 'pluginMeta.quest',
    objRefOutput: 'pluginMeta.quest',
  },
  questPassword: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Password',
    label: 'PASSWORD',
    rule: 'optional',
    objRef: 'pluginMeta.quest',
    objRefOutput: 'pluginMeta.quest',
  },
  baseUrl: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Base Url',
    label: 'BASE URL',
    rule: 'optional',
    objRef: 'pluginMeta.quest',
    objRefOutput: 'pluginMeta.quest',
  },
  token: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Token',
    label: 'TOKEN',
    rule: 'optional',
    objRef: 'pluginMeta.mtl',
    objRefOutput: 'pluginMeta.mtl',
  },
  mtlUserId: {
    value: '',
    error: undefined,
    placeHolder: 'Enter User Id',
    label: 'USER ID',
    rule: 'optional',
    objRef: 'pluginMeta.mtl',
    objRefOutput: 'pluginMeta.mtl',
  },
  physicianId: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Physician Id',
    label: 'PHYSICIAN ID',
    rule: 'optional',
    objRef: 'pluginMeta.mtl',
    objRefOutput: 'pluginMeta.mtl',
  },
}

const SFTP_PLUGIN_META = {
  sftpUsername: {
    value: '',
    error: undefined,
    placeHolder: 'Enter SFTP Username',
    label: 'USERNAME',
    rule: 'optional',
  },
  sftpPPK: {
    value: '',
    error: undefined,
    placeHolder: 'Enter SFTP PPK',
    label: 'Upload PPK',
    rule: 'optional',
  },
  sftpUrl: {
    value: '',
    error: undefined,
    placeHolder: 'Enter SFTP URL',
    label: 'SFTP URL',
    rule: 'optional',
  },
  sftpBrandPrefix: {
    value: '',
    error: undefined,
    placeHolder: 'Enter BRAND PREFIX',
    label: 'BRAND PREFIX',
    rule: 'optional',
  },
  sftpInputPath: {
    value: '',
    error: undefined,
    placeHolder: 'Enter INPUT DIRECTORY',
    label: 'INPUT DIRECTORY PATH',
    rule: 'optional',
  },
  sftpOutputPath: {
    value: '',
    error: undefined,
    placeHolder: 'Enter OUTPUT DIRECTORY',
    label: 'OUTPUT DIRECTORY PATH',
    rule: 'optional',
  },
  sftpPort: {
    value: '',
    error: undefined,
    placeHolder: 'Enter SFTP port',
    label: 'SFTP PORT',
    rule: 'optional',
  },
  sftpResultProcess: {
    values: [
      { key: 'REALTIME', value: 'REALTIME', text: 'REALTIME' },
      { key: 'BATCH', value: 'BATCH', text: 'BATCH' },
    ],
    error: undefined,
    label: 'RESULT PROCESS AS',
    placeHolder: 'select',
    rule: 'optional',
  }
}
const BILLING = {
  billingType: {
    values: [
      { key: 'MONTHLYFEE', value: 'MONTHLYFEE', text: 'Monthly Fee' },
      { key: 'BULKFEE', value: 'BULKFEE', text: 'Bulk Fee' },
    ],
    error: undefined,
    defaultValue: 'MONTHLYFEE',
    label: 'Billing Type',
    placeHolder: 'select',
    rule: 'optional',
  },
  monthlyFee: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Monthly Fee',
    label: 'Monthly Fee',
    rule: 'optional',
  },
  perOrderFee: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Per Order Fee',
    label: 'Per Order Fee',
    rule: 'optional',
  },
  includedOrders: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Included Orders',
    label: 'Included Orders',
    rule: 'optional',
  },
  portalFee: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Portal Fee',
    label: 'Portal Fee',
    rule: 'optional',
  },
  fulfillmentPerOrderFee: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Fulfillment Per Order Fee',
    label: 'Fulfillment Per Order Fee',
    rule: 'optional',
  },
  fulfillmentMonhtlyFee: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Fulfillment Monhtly Fee',
    label: 'Fulfillment Monthly Fee',
    rule: 'optional',
  },
  batchFee: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Batch Fee',
    label: 'Batch Fee',
    rule: 'optional',
  },
  batchSize: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Batch Size',
    label: 'Bill Batch Size',
    rule: 'optional',
  },
  batchReBilledAt: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Re Bill At',
    label: 'Re Bill At',
    rule: 'optional',
  },
  batchLastBilledDate: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Last Bill Date',
    label: 'Last Bill Date (MM/DD/YYYY)',
    rule: 'optional',
  },
  currentBatchSize: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Current Batch Size',
    label: 'Current Batch Size',
    rule: 'optional',
  } 
}
const BRANDS_META = {
  referralUrl: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Referral Url',
    label: 'REFERRAL URL',
    rule: 'optional',
  },
  validateOrderId: {
    value: false,
    error: undefined,
    label: 'STRICT ENFORCE REGISTERED ORDER ID',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    default: false
  },
  validateOrderIdLength: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Validate Number Order ID Length',
    label: 'VALIDATE NUMBER ORDER ID LENGTH',
    rule: 'optional|numeric',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  validateOrderIdPanel: {
    value: '',
    error: undefined,
    placeHolder: 'Select Panel',
    label: 'DEFAULT PANEL',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  headerColor: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Header Color Url',
    label: 'HEADER TOP BORDER COLOR',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  titleTextColor: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Title text color',
    label: 'TITLE TEXT COLOR',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  brandsTheme: {
    value: BRAND_THEMES[0].value,
    error: undefined,
    placeHolder: 'Choose Theme',
    values: BRAND_THEMES.map(g => ({ key: g.key, value: g.value, text: g.text })),
    label: 'THEME',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  previewPassword: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Preview Password',
    label: 'PREVIEW PASSWORD',
    rule: 'optional',
  },
  siteTitle: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Home Page Title',
    label: 'Home Page Title',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  siteDescription: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Home Page Description',
    label: 'Home Page Description',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  brandLogo: {
    value: '',
    error: undefined,
    placeHolder: 'Brand Logo',
    label: 'BRAND LOGO',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    ...FILE_UPLOAD,
  },
  logoPosition: {
    value: LOGO_POSITION[0].value,
    error: undefined,
    placeHolder: 'Choose Position',
    values: LOGO_POSITION,
    label: 'LOGO POSITION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  resultPage: {
    value: 'PDF_OR_TABLE',
    defaultValue: 'PDF_OR_TABLE',
    error: undefined,
    placeHolder: 'Choose Result Page',
    values: [
      {key: 'PDF_AND_TABLE',text: 'Show PDF AND Table',value: 'PDF_AND_TABLE'},
      {key: 'PDF_OR_TABLE',text: 'Show PDF if Exist, else Table',value: 'PDF_OR_TABLE'},
      {key: 'PDF_ONLY',text: 'Show PDF only',value: 'PDF_ONLY'},
      {key: 'TABLE_ONLY',text: 'Show Table only',value: 'TABLE_ONLY'},
      ],
    label: 'RESULT PAGE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  brandFavIcon: {
    value: '',
    error: undefined,
    placeHolder: 'Brand FavIcon',
    label: 'BRAND FAVICON',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    ...FILE_UPLOAD,
  },
  brandTitle: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Brand Title',
    label: 'BRAND TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  // brandUrl: {
  //   value: '',
  //   error: undefined,
  //   placeHolder: 'Enter Brand URL',
  //   label: 'BRAND URL',
  //   rule: 'optional|url',
  //   objRef: 'meta',
  //   objRefOutput: 'meta',
  // },
  dashboardTitle: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Dashboard Title',
    label: 'DASHBOARD TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  dashboardDesc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Dashboard Description',
    label: 'DASHBOARD DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  additionalKitTitle: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Additional Kit Title',
    label: 'ADDITIONAL KIT TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  additionalKitDesc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Additional Kit Description',
    label: 'ADDITIONAL KIT DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  addKitPersonalTitle: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Additional Kit Personal Info Title',
    label: 'ADDITIONAL KIT PERSONAL INFO TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  addKitPersonalDesc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Additional Kit Personal Info Description',
    label: 'ADDITIONAL KIT PERSONAL INFO DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  loginTitle: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Login Title',
    label: 'LOGIN TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  loginDesc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Login Description',
    label: 'LOGIN DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  signup1Title: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Signup Step 1 Title',
    label: 'SIGNUP STEP 1 TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  signup1Desc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Signup Step 1 Description',
    label: 'SIGNUP STEP 1 DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  signup2Title: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Signup Step 2 Title',
    label: 'SIGNUP STEP 2 TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  signup2Desc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Signup Step 2 Description',
    label: 'SIGNUP STEP 2 DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  signup3Title: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Signup Step 3 Title',
    label: 'SIGNUP STEP 3 TITLE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  signup3Desc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Signup Step 3 Description',
    label: 'SIGNUP STEP 3 DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  privateNav:{
    value: false,
    error: undefined,
    label: 'PRIVATE NAVIGATION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    default: false
  },
  publicNav:{
    value: false,
    error: undefined,
    label: 'PUBLIC NAVIGATION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    default: false
  },
  dontShowMarkerColors:{
    value: false,
    error: undefined,
    label: 'DO NOT SHOW MARKER COLORS',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    default: false
  },
  tocUrl:{
    value: false,
    error: undefined,
    label: 'Terms & Conditions URL',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    default: false
  },
  ...META_PRIVATE_NAV,
  ...META_PUBLIC_NAV,
  ...LOGO_URL,
}

export const SHIPMENT_META = {
  shopType: {
    value: '',
    error: undefined,
    label: 'SHOP TYPE',
    placeHolder: 'select',
    rule: 'optional',
    objRef: 'dropShipmentMeta',
    objRefOutput: 'dropShipmentMeta',
  },
  sUrl: {
    value: '',
    error: undefined,
    placeHolder: 'SHOP NAME',
    label: 'SHOP NAME',
    rule: 'optional',
    objRef: 'dropShipmentMeta.shopDetails',
    objRefOutput: 'dropShipmentMeta.shopDetails',
    keyAlias: 'url',
  },
  version: {
    value: '',
    error: undefined,
    placeHolder: 'SHOP API VERSION',
    label: 'SHOP API VERSION',
    rule: 'optional',
    objRef: 'dropShipmentMeta.shopDetails',
    objRefOutput: 'dropShipmentMeta.shopDetails',
  },
  password: {
    value: '',
    error: undefined,
    placeHolder: 'PASSWORD',
    label: 'PASSWORD',
    rule: 'optional',
    objRef: 'dropShipmentMeta.shopDetails',
    objRefOutput: 'dropShipmentMeta.shopDetails',
  },
  fulfillmentType: {
    value: '',
    error: undefined,
    label: 'FULFILLMENT TYPE',
    placeHolder: 'select',
    rule: 'optional',
    objRef: 'dropShipmentMeta',
    objRefOutput: 'dropShipmentMeta',
  },
  url: {
    value: '',
    error: undefined,
    placeHolder: 'URL',
    label: 'URL',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  identityCode: {
    value: '',
    error: undefined,
    placeHolder: 'IDENTITY CODE',
    label: 'IDENTITY CODE',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  host: {
    value: '',
    error: undefined,
    placeHolder: 'HOST',
    label: 'HOST',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  username: {
    value: '',
    error: undefined,
    placeHolder: 'User Name',
    label: 'User Name',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  port: {
    value: '',
    error: undefined,
    placeHolder: 'PORT',
    label: 'PORT',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  fullFillmentPassword: {
    value: '',
    error: undefined,
    placeHolder: 'PASSWORD',
    label: 'PASSWORD',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  passcode:{
    value: '',
    error: undefined,
    placeHolder: 'PASS CODE',
    label: 'PASS CODE',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  shippingMethod: {
    value: '',
    error: undefined,
    placeHolder: 'SHIPPING METHOD',
    label: 'SHIPPING METHOD',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  location: {
    value: '',
    error: undefined,
    placeHolder: 'LOCATION',
    label: 'LOCATION',
    rule: 'optional',
    objRef: 'dropShipmentMeta.fulfillmentDetails',
    objRefOutput: 'dropShipmentMeta.fulfillmentDetails',
  },
  inputPath: {
    value: '',
    error: undefined,
    placeHolder: 'Enter INPUT DIRECTORY',
    label: 'INPUT DIRECTORY PATH',
    rule: 'optional',
    objRef: 'dropShipmentMeta',
    objRefOutput: 'dropShipmentMeta',
  },
  outputPath: {
    value: '',
    error: undefined,
    placeHolder: 'Enter OUTPUT DIRECTORY',
    label: 'OUTPUT DIRECTORY PATH',
    rule: 'optional',
    objRef: 'dropShipmentMeta',
    objRefOutput: 'dropShipmentMeta',
  },
}

export const BRANDS_EMAILS = {
  hFrom: {
    value: '',
    error: undefined,
    placeHolder: 'Email From',
    label: 'FROM',
    rule: 'email',
    objRef: 'portal.email.header',
    objRefOutput: 'portal.email.header',
    keyAlias: 'from',
    customErrors: {
      email: 'Please enter valid email address.'
    }
  },
  hName: {
    value: '',
    error: undefined,
    placeHolder: 'Name',
    label: 'NAME',
    rule: 'optional',
    objRef: 'portal.email.name',
    objRefOutput: 'portal.email.name',
    keyAlias: 'name',
  },
  hReplyTo: {
    value: '',
    error: undefined,
    placeHolder: 'Reply To',
    label: 'REPLY TO',
    rule: 'email',
    objRef: 'portal.email.replyTo',
    objRefOutput: 'portal.email.replyTo',
    keyAlias: 'replyTo',
    customErrors: {
      email: 'Please enter valid email address.'
    }
  },
  urSubject: {
    value: '',
    defaultValue: 'Verify your email address',
    error: undefined,
    placeHolder: 'Email Subject',
    label: 'EMAIL SUBJECT',
    rule: 'optional',
    objRef: 'portal.email.inventory.userRegistration',
    objRefOutput: 'portal.email.inventory.userRegistration',
    keyAlias: 'subject',
  },
  urBody: {
    value: '',
    defaultValue: '<p>\nWe received your information to begin creating your account. To continue, please confirm your email address.\n<br><br>Enter the following six-digit code: {code}\n<br><br>Thank you,<br>\nThe Portal Team<br>\n</p>',
    tooltip: 'Placeholders: - `{code}`: Required - `{headerLogo}`: Optional, `{footerLogo}`: Optional',
    error: undefined,
    placeHolder: 'Email Body',
    label: 'EMAIL BODY',
    rule: 'optional',
    objRef: 'portal.email.inventory.userRegistration',
    objRefOutput: 'portal.email.inventory.userRegistration',
    keyAlias: 'body',
  },
  uwSend: {
    value: '',
    error: undefined,
    placeHolder: 'Email Send',
    label: 'EMAIL SEND',
    rule: 'optional',
    objRef: 'portal.email.inventory.userWelcome',
    objRefOutput: 'portal.email.inventory.userWelcome',
    keyAlias: 'send',
  },
  uwSubject: {
    value: '',
    defaultValue: 'Welcome to Portal',
    error: undefined,
    placeHolder: 'Email Subject',
    label: 'EMAIL SUBJECT',
    rule: 'optional',
    objRef: 'portal.email.inventory.userWelcome',
    objRefOutput: 'portal.email.inventory.userWelcome',
    keyAlias: 'subject',
  },
  uwBody: {
    value: '',
    defaultValue: '<p>\nThank you for creating an account with portal.<br><br>\nSincerely,<br>\nThe Portal Team<br>\n</p>',
    error: undefined,
    placeHolder: 'Email Body',
    tooltip: 'Placeholders: - `{headerLogo}`: Optional, `{footerLogo}`: Optional',
    label: 'EMAIL BODY',
    rule: 'optional',
    objRef: 'portal.email.inventory.userWelcome',
    objRefOutput: 'portal.email.inventory.userWelcome',
    keyAlias: 'body',
  },
  krSend: {
    value: '',
    error: undefined,
    placeHolder: 'Email Send',
    label: 'EMAIL SEND',
    rule: 'optional',
    objRef: 'portal.email.inventory.kitRegistration',
    objRefOutput: 'portal.email.inventory.kitRegistration',
    keyAlias: 'send',
  },
  krSubject: {
    value: '',
    defaultValue: 'You Just Registered Your Kit',
    tooltip: 'Placeholders: - {orderId}: Optional',
    error: undefined,
    placeHolder: 'Email Subject',
    label: 'EMAIL SUBJECT',
    rule: 'optional',
    objRef: 'portal.email.inventory.kitRegistration',
    objRefOutput: 'portal.email.inventory.kitRegistration',
    keyAlias: 'subject',
  },
  krBody: {
    value: '',
    defaultValue: '<p>\n{firstName} {lastName},<br><br>\nThank you for registering your kit. We will let you know once your results are in.<br><br>\nSincerely,<br>\nThe Portal Team<br>\n</p>',
    tooltip: 'Placeholders: - `{firstName}`: Optional - `{lastName}`: Optional - `{orderId}`: Optional - `{headerLogo}`: Optional, `{footerLogo}`: Optional',
    error: undefined,
    placeHolder: 'Email Body',
    label: 'EMAIL BODY',
    rule: 'optional',
    objRef: 'portal.email.inventory.kitRegistration',
    objRefOutput: 'portal.email.inventory.kitRegistration',
    keyAlias: 'body',
  },
  fpSubject: {
    value: '',
    defaultValue: 'Password Reset',
    error: undefined,
    placeHolder: 'Email Subject',
    label: 'EMAIL SUBJECT',
    rule: 'optional',
    objRef: 'portal.email.inventory.forgotPassword',
    objRefOutput: 'portal.email.inventory.forgotPassword',
    keyAlias: 'subject',
  },
  fpBody: {
    value: '',
    defaultValue: '<p>\nPlease use this verification code to reset your password.<br>\n{code}<br>\nIf you did not request a password reset. Ignore this email<br><br>\n</p>',
    tooltip: 'Placeholders: - `{code}`: Required - `{firstName}`: Optional - `{lastName}`: Optional - `{headerLogo}`: Optional, `{footerLogo}`: Optional',
    error: undefined,
    placeHolder: 'Email Body',
    label: 'EMAIL BODY',
    rule: 'optional',
    objRef: 'portal.email.inventory.forgotPassword',
    objRefOutput: 'portal.email.inventory.forgotPassword',
    keyAlias: 'body',
  },
  raSend: {
    value: '',
    error: undefined,
    placeHolder: 'Email Send',
    label: 'EMAIL SEND',
    rule: 'optional',
    objRef: 'portal.email.inventory.resultsAvailable',
    objRefOutput: 'portal.email.inventory.resultsAvailable',
    keyAlias: 'send',
  },
  raSubject: {
    value: '',
    defaultValue: 'Your Results For Test Kit ID: {orderId} Are In!',
    tooltip: 'Placeholders: - {orderId}: Optional',
    error: undefined,
    placeHolder: 'Email Subject',
    label: 'EMAIL SUBJECT',
    rule: 'optional',
    objRef: 'portal.email.inventory.resultsAvailable',
    objRefOutput: 'portal.email.inventory.resultsAvailable',
    keyAlias: 'subject',
  },
  raBody: {
    value: '',
    defaultValue: '<p>\n{firstName} {lastName},\n<br><br>Your Results are in! Visit your portal dashboard to view your results.\nTo view your dashboard, click <a target="_blank" href="https://dev-portal.ordrs.io/dashboard/order/{orderId}">here</a> to login. <br><br>\nSincerely,<br>\nThe Portal Team<br>\n</p>',
    tooltip: 'Placeholders: - `{firstName}`: Optional - `{lastName}`: Optional - `{orderId}`: Optional  - `{headerLogo}`: Optional, `{footerLogo}`: Optional',
    error: undefined,
    placeHolder: 'Email Body',
    label: 'EMAIL BODY',
    rule: 'optional',
    objRef: 'portal.email.inventory.resultsAvailable',
    objRefOutput: 'portal.email.inventory.resultsAvailable',
    keyAlias: 'body',
  },
}

export const NEW_BRAND = {
  name: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Brand Name',
    label: 'BRAND',
    rule: 'required',
  },
  ...ADDRESS,
  associatedUsersId: {
    value: [],
    error: undefined,
    label: 'ADD USER',
    placeHolder: 'Enter or select User',
    rule: 'required',
  },
  providerUsersId: {
    value: [],
    error: undefined,
    label: 'ADD PROVIDER',
    placeHolder: 'Enter or select Provider',
    rule: 'optional',
  },
  userId: {
    value: 'cjrt7wzqz044x0161kbeyirmy',
    error: undefined,
    label: 'BRAND CONTACT',
    placeHolder: 'Enter Full Name',
    rule: 'required',
    objRef: 'poc.user.id',
    objRefOutput: 'poc',
  },
  phone: {
    ...PHONE,
    label: 'BRAND CONTACT PHONE',
    objRef: 'poc',
    objRefOutput: 'poc',
  },
  email: {
    ...EMAIL,
    label: 'BRAND CONTACT EMAIL',
    objRef: 'poc',
    objRefOutput: 'poc',
  },
  dropShipment: {
    value: '',
    error: undefined,
    label: 'DROP SHIPMENT',
    placeHolder: 'select',
    rule: 'optional',
  },
  authType: {
    value: 'ORDRS',
    error: undefined,
    label: 'Auth Type',
    rule: 'required',
  },
  providers: {
    value: [],
    error: undefined,
    label: 'Providers',
    rule: 'required',
  },
  ...GDS,
  ...WEBHOOK_URL,
  ...NOTIFICATION_NAME_EMAIL,
  ...ADMIN_NOTE,
  ...PREFERENCE,
  ...BRANDS_META,
  ...PLUGIN_META,
  ...SFTP_PLUGIN_META,
  ...BILLING,
  ...BRANDS_EMAILS,
  ...SHIPMENT_META
};
