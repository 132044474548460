export const PRIVATE_NAV = [
  {
    icon: 'file alternate outline',
    title: 'Orders',
    to: 'orders',
    path: 'shared/orders',
    accessibleTo: ['ADMIN', 'USER', 'MD'],
    subPanel: 0,
  },
  {
    icon: 'user',
    title: 'Users',
    to: 'users',
    path: 'admin/users',
    accessibleTo: ['ADMIN'],
    subPanel: 0,
  },
  {
    icon: 'th',
    title: 'Panels',
    to: 'panels',
    path: 'shared/panels',
    accessibleTo: ['ADMIN', 'USER', 'MD'],
    subPanel: 0,
  },
  {
    icon: 'file',
    title: 'Uploads',
    to: 'uploads',
    path: 'shared/uploads',
    accessibleTo: ['ADMIN', 'LAB'],
    subPanel: 0,
  },
  {
    icon: 'bold',
    title: 'Brand',
    to: 'brands',
    path: 'admin/brands',
    accessibleTo: ['ADMIN'],
    subPanel: 0,
  },
  {
    icon: 'lab',
    title: 'Lab',
    to: 'labs',
    path: 'shared/labs',
    accessibleTo: ['ADMIN'],
    subPanel: 0,
  },
  {
    icon: 'box',
    title: 'Fulfilment',
    to: 'shipment',
    path: 'shared/shipment',
    accessibleTo: ['ADMIN'],
    subPanel: 0,
  },
  
  // {
  //   icon: 'pin',
  //   title: 'Portal User',
  //   to: 'portal',
  //   path: 'shared/portal',
  //   accessibleTo: ['ADMIN'],
  //   subPanel: 0
  // },
  {
    icon: 'money bill alternate',
    title: 'Billing',
    to: 'billing',
    path: 'shared/billing',
    accessibleTo: ['ADMIN'],
    subPanel: 0,
  },
  {
    icon: 'address card outline',
    title: 'Profile',
    to: 'profile',
    path: 'shared/profile',
    accessibleTo: [],
    subPanel: 0,
    // noNav: true
  },
];
