export const API_ROOT = process.env.REACT_APP_API_URL;
export const DEPLOY_ENV = process.env.REACT_APP_DEPLOY_ENV;
export const DEV_FEATURE_ONLY = ['localhost', 'dev', 'review'].includes(DEPLOY_ENV);
export const DATE_FORMAT = 'YYYY-MM-DD';

export const BEACON_CONFIG = {
  ADMIN: '5650b67d-c760-475e-ad95-34dd9909d185',
  BRAND: 'cc3125d5-393a-456c-babc-83e921fa91e4',
  LAB: '3f006ac6-eb1a-46dc-adeb-fa9a82a96a73',
  MD: 'a044731a-a9ad-4acc-88a8-bf158786c3d6'
}

export const US_STATES = [
  'ALABAMA',
  'ALASKA',
  'ARIZONA',
  'ARKANSAS',
  'CALIFORNIA',
  'COLORADO',
  'CONNECTICUT',
  'DELAWARE',
  'DISTRICT_OF_COLUMBIA',
  'FLORIDA',
  'GEORGIA',
  'HAWAII',
  'IDAHO',
  'ILLINOIS',
  'INDIANA',
  'IOWA',
  'KANSAS',
  'KENTUCKY',
  'LOUISIANA',
  'MAINE',
  'MARYLAND',
  'MASSACHUSETTS',
  'MICHIGAN',
  'MINNESOTA',
  'MISSISSIPPI',
  'MISSOURI',
  'MONTANA',
  'NEBRASKA',
  'NEVADA',
  'NEW_HAMPSHIRE',
  'NEW_JERSEY',
  'NEW_MEXICO',
  'NEW_YORK',
  'VIRGIN_ISLANDS',
  'NORTH_CAROLINA',
  'NORTH_DAKOTA',
  'OHIO',
  'OKLAHOMA',
  'OREGON',
  'PENNSYLVANIA',
  'RHODE_ISLAND',
  'SOUTH_CAROLINA',
  'SOUTH_DAKOTA',
  'TENNESSEE',
  'TEXAS',
  'UTAH',
  'VERMONT',
  'VIRGINIA',
  'WASHINGTON',
  'WEST_VIRGINIA',
  'WISCONSIN',
  'WYOMING',
];
