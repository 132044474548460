import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
// import './assets/semantic/semantic.min.css';
// import './assets/fomantic/src/theme.less';
import './assets/fomantic/dist/semantic.css';
import 'react-toastify/dist/ReactToastify.css';
import Private from './modules/private';
import Public from './modules/public';
import { Spinner } from './components/shared';

class App extends Component {
  constructor(props) {
    super(props);
    this.props.authStore.initLoad();
  }
  
  render() {
    const { appLoading } = this.props.uiStore;
    return (
      appLoading === true ? <Spinner loaderMessage="Loading..." /> : (
        <div className="App">
          <ToastContainer className="toast-message" />
          <Switch>
            <Route exact path="/app/*" component={Private} />
            <Route path="/" component={Public} />
          </Switch>
          <div className="custom-modal-wrapper" />
        </div>
      )
    );
  }
}

export default inject('authStore', 'uiStore')(withRouter(observer(App)));

