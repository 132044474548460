import { makeAutoObservable, flow } from 'mobx';
import Amplify, { Auth } from 'aws-amplify';
import awsExports from '../../../../constants/aws';
import { Logger } from '../../../../components/shared';
import { FormHandler as FormHandle, Utility as Utils } from '../../../../utils';
import {
  uiStore, usersStore, panelsStore, ordersStore, brandsStore, labsStore, markersStore
} from '../../index';
import { LOGIN, FORGOT_PASS, CONFIRM_PASS } from '../../../constants/auth';

export class AuthStore {
  constructor() {
    makeAutoObservable(this);
    Amplify.configure(awsExports);
    this.initLoad();
  }

  currentUser;
  currTime;
  status = false; // loading // error
  LOGIN_FRM = FormHandle.prepareForm(LOGIN);
  FORGOT_PASS_FRM = FormHandle.prepareForm(FORGOT_PASS);
  CONFIRM_PASS_FRM = FormHandle.prepareForm(CONFIRM_PASS);
  isUserLoggedIn = true;

  initLoad = async () => {
    uiStore.updateLoading(true);
    this.status = true;
    try {
      this.currentUser = await Auth.currentSession();
      await usersStore.getOne();
      this.status = false;
      return;
    } catch (e) {
      uiStore.updateLoading(false);
      this.status = false;
    }
  }

  login = flow(function* () {
    this.status = true;
    const params = FormHandle.evaluateFormData(this.LOGIN_FRM.fields);
    try {
      yield Auth.signIn({ username: params.email, password: params.password });
      this.status = false;
      yield this.initLoad();
      return true;
    } catch (err) {
      this.status = err.message;
      Logger.info(err);
    }
  });

  forgotPassword = flow(function* () {
    this.status = true;
    const params = FormHandle.evaluateFormData(this.FORGOT_PASS_FRM.fields);
    try {
      yield Auth.forgotPassword(params.email);
      this.status = false;
      return true;
    } catch (err) {
      this.status = err.message;
      Logger.info(err);
    }
  });

  forgotPasswordSubmit = flow(function* () {
    this.status = true;
    const { email } = FormHandle.evaluateFormData(this.FORGOT_PASS_FRM.fields);
    const params = FormHandle.evaluateFormData(this.CONFIRM_PASS_FRM.fields);
    try {
      yield Auth.forgotPasswordSubmit(email, params.code, params.password);
      this.status = false;
      Utils.toast('Password changed successfully, please login.', 'success');
      return true;
    } catch (err) {
      this.status = err.message;
      Logger.info(err);
    }
  });

  logout = flow(function* () {
    this.status = false;
    document.userMetaData = undefined;
    panelsStore.resetAll();
    ordersStore.resetAll();
    usersStore.resetAll();
    brandsStore.resetAll();
    labsStore.resetAll();
    markersStore.resetAll();
    uiStore.resetAll();
    return yield Auth.signOut();
  });

  get currentSession() {
    return this.currentUser && this.currentUser.idToken ?
      { ...this.currentUser.idToken.payload, ...{ jwtToken: this.currentUser.accessToken.jwtToken } } : false;
  }

  get forgotRequest() {
    return this.FORGOT_PASS_FRM ?
      FormHandle.evaluateFormData(this.FORGOT_PASS_FRM.fields) : {};
  }

  formChange = (e, result, form) => {
    this[form] = FormHandle.onChange(this[form], FormHandle.pullValues(e, result));
    this.currTime = + new Date();
  };

  reset = (form) => {
    if (form) {
      this[form] = FormHandle.resetFormData(this[form]);
    }
  }

  userRole() {
    let role = '';
    try {
      role = document.userMetaData && document.userMetaData.length > 1 ?
        document.userMetaData[1] : document.userMetaData[0];
    } catch (e) { }
    return role;
  }
}

// https://aws-amplify.github.io/docs/js/authentication

export default new AuthStore();
