import { toast } from 'react-toastify';
import moment from 'moment';
import { merge, includes, pickBy, intersection, get } from 'lodash';
import { Parser } from 'json2csv';
import { DATE_FORMAT } from '../../constants/common';
import { DEPLOY_ENV } from '../../constants/common';

class Utility {
  options = {
    autoClose: 4200,
    position: toast.POSITION.BOTTOM_RIGHT,
    pauseOnHover: true,
    className: 'info',
  };

  _MapAbbrFullName = { "AZ": "Arizona", "AL": "Alabama", "AK": "Alaska", "AR": "Arkansas", "CA": "California", "CO": "Colorado", "CT": "Connecticut", "DC": "District of Columbia", "DE": "Delaware", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NY": "New York", "VI": "Virgin Islands", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming", "AB": "Alberta", "BC": "British Columbia", "MB": "Manitoba", "NB": "New Brunswick", "NF": "Newfoundland", "NT": "Northwest Territory", "NS": "Nova Scotia", "NU": "Nunavut", "ON": "Ontario", "PE": "Prince Edward Island", "QC": "Quebec", "SK": "Saskatchewan", "YT": "Yukon" };

  _MapFullNameAbbr = { "arizona": "AZ", "alabama": "AL", "alaska": "AK", "arkansas": "AR", "california": "CA", "colorado": "CO", "connecticut": "CT", "districtofcolumbia": "DC", "delaware": "DE", "florida": "FL", "georgia": "GA", "hawaii": "HI", "idaho": "ID", "illinois": "IL", "indiana": "IN", "iowa": "IA", "kansas": "KS", "kentucky": "KY", "louisiana": "LA", "maine": "ME", "maryland": "MD", "massachusetts": "MA", "michigan": "MI", "minnesota": "MN", "mississippi": "MS", "missouri": "MO", "montana": "MT", "nebraska": "NE", "nevada": "NV", "newhampshire": "NH", "newjersey": "NJ", "newmexico": "NM", "newyork": "NY", "virginislands": "VI", "northcarolina": "NC", "northdakota": "ND", "ohio": "OH", "oklahoma": "OK", "oregon": "OR", "pennsylvania": "PA", "rhodeisland": "RI", "southcarolina": "SC", "southdakota": "SD", "tennessee": "TN", "texas": "TX", "utah": "UT", "vermont": "VT", "virginia": "VA", "washington": "WA", "westvirginia": "WV", "wisconsin": "WI", "wyoming": "WY", "alberta": "AB", "britishcolumbia": "BC", "manitoba": "MB", "newbrunswick": "NB", "newfoundland": "NF", "northwestterritory": "NT", "novascotia": "NS", "nunavut": "NU", "ontario": "ON", "princeedwardisland": "PE", "quebec": "QC", "saskatchewan": "SK", "yukon": "YT" }


  toast = (msg, alertType, optionsOverride, msgOverride) => {
    const message = msgOverride ? msgOverride.replace('GraphQL error: ', '') : msg;
    if (alertType && includes(['error', 'success', 'info', 'warning'], alertType)) {
      toast[alertType](message, merge({}, this.options, optionsOverride, { className: alertType }));
    } else {
      toast(message, merge({}, this.options, optionsOverride));
    }
  }

  NumFormat = (number, fraction = 2) => new Intl.NumberFormat('en-US', { maxFractionDigits: fraction }).format(number)

  guid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4()}${s4()}-${s4()}${s4()}`;
  }

  downloadCSV = (params) => {
    try {
      const parser = new Parser({ fields: params.fields, quote: '' });
      const csv = parser.parse(params.data);
      const uri = 'data:text/csv;charset=utf-8,' + escape(csv);
      const link = document.createElement("a");
      link.href = uri;
      link.style = 'visibility:hidden';
      link.download = `${params.fileName || 'download'}_${moment(new Date()).format('DD-MM-YYYY')}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  }
  downloadCSVForStringContaingComma = (params) => {
    try {
      const parser = new Parser({ fields: params.fields, quote: '"' });
      const csv = parser.parse(params.data);
      const uri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      const link = document.createElement("a");
      link.href = uri;
      link.style = 'visibility:hidden';
      link.download = `${params.fileName || 'download'}_${moment(new Date()).format('DD-MM-YYYY')}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  }

  filterTabs = (role, tabs) => {
    return pickBy(tabs, t => !t.accessTo || (role === 'MD' && t.label === 'RejXXected' ? false : t.accessTo[role]))
  }

  getValue = (list, key) => {
    let value = '';
    list.forEach(element => {
      if (element.key === key) value = element.value;
    });
    return value;
  }

  convertGenderToAbbr = input => {
    return input.toUpperCase().charAt(0);
  }
  convertStateToAbbr = (input) => {
    if (input === undefined) return input;
    var strInput = input.trim();
    if (strInput.length === 2) {
      // already abbr, check if it's valid
      var upStrInput = strInput.toUpperCase();
      return this._MapAbbrFullName[upStrInput] ? upStrInput : undefined;
    }
    var strStateToFind = strInput.toLowerCase().replace(/ /g, '');
    var foundAbbr = this._MapFullNameAbbr[strStateToFind];
    return foundAbbr;
  }

  convertStateToFullName = (input) => {
    if (input === undefined) return input;
    var strInput = input.trim();
    if (strInput.length !== 2) {
      // already full name, return formatted fullname
      return this._MapAbbrFullName[this.convertStateToAbbr(strInput)];
    }
    var strStateToFind = strInput.toLowerCase().replace(/ /g, '');
    var foundFullName = this._MapAbbrFullName[strStateToFind];
    return foundFullName;
  }

  convertObjectToArray = (obj, key) => {
    const returnArray = [];
    Object.keys(obj).forEach((innerObjKey) => {
      returnArray.push({ ...obj[innerObjKey], [`${key}`]: innerObjKey });
    });
    return returnArray;
  }

  generateUUID = () => {
    let dt = new Date().getTime();
    let UUID = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return UUID() + UUID() + '-' + UUID() + '-' + UUID() + '-' + UUID() + '-' + UUID() + UUID() + UUID() + dt;
  }

  convertArrayToObject = (arr, key) => {
    const returnArray = {};
    arr.map((inner) => (returnArray[inner[`${key}`].value] = { email: inner.email.value, webhook: inner.webhook.value }));
    return returnArray;
  }

  isSuperAdmin = (email) => email === 'alan@ordrs.io' || email === 'qa+admin@ordrs.io';

  checkSuperAdminAccess = (props) => {
    const { module, a, status, isFlagged, currentSession, editPanel } = props;
    let returnVal = false;
    const roles = document.userMetaData || [];
    const isSuperAdmin = this.isSuperAdmin(currentSession.email);
    let isValid = false;
    if (module === "Orders") {
      isValid = Array.isArray(a)
        ? ((Array.isArray(a[2]) ? a[2].includes(status) : a[2] === status) || a[2] === "ANY") && (a[0] === 'Edit Customer' || a[5] === isFlagged) &&
        (roles.includes("ADMIN") || a[1] === "ANY" || (Array.isArray(a[1]) ? intersection(a[1], roles).length > 0 : roles.includes(a[1])))
        : true;

      if (typeof a[7] === 'boolean') {
        returnVal = isSuperAdmin && isValid;
      } else {
        returnVal = isValid;
      }
    } else if (module === 'Panels') {
      if (editPanel && get(a, '[2].showOnEdit')) {
        isValid = true;
      } else if (!editPanel && !get(a, '[2].showOnEdit')) {
        isValid = true;
      } else {
        isValid = false;
      }
      if (get(a, '[2].superAdmin')) {
        returnVal = isSuperAdmin && isValid;
      } else {
        returnVal = isValid;
      }
    } else {
      returnVal = true;
    }
    return returnVal;
  }

  getStateList = () => {
    return this._MapAbbrFullName;
  }

  validateImageExtension = (ext) => {
    const obj = {
      isInvalid: ext ? !['jpeg', 'jpg', 'png'].includes(ext.toLowerCase()) : true,
      errorMsg: `Only ${['jpeg', 'jpg', 'png'].join(', ')} extensions are allowed.`,
    };
    return obj;
  };

  isBase64 = (data) => {
    try {
      const block = data.split(';');
      return block[1].split(',')[0] === 'base64';
    } catch (e) {
      return false;
    }
  }

  b64toBlob = (data, sliceSize = 512) => {
    const block = data.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    const b64Data = block[1].split(',')[1];
    const size = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += size) {
      const slice = byteCharacters.slice(offset, offset + size);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  sanitize = name => (name ? name.replace(/[^a-z0-9._-]+/gi, '_') : '');

  addOrSubtractBusinessDays = (params) => {
    const { date, days, action = 'add', returnAsObject = false } = params;
    const originalDate = typeof (date) === 'object' ? date : new Date(date);
    const dateCalculated = action === 'subtract' ? moment(originalDate, DATE_FORMAT).subtract(days, 'days') : moment(originalDate, DATE_FORMAT).add(days, 'days');
    return returnAsObject ? moment(dateCalculated).toDate() : moment(dateCalculated).format(DATE_FORMAT);
  }

  getCurrentDate = (returnAsObject = false) => (returnAsObject ? moment(new Date()).toDate() : moment(new Date()).format(DATE_FORMAT));

  formatDateInIso = (date) => (moment(new Date(date), `${DATE_FORMAT} HH:mm:ss`).toISOString());

  showDateInFormat = (params) => {
    const { date, dateFormat = DATE_FORMAT } = params;
    return moment(new Date(date)).format(dateFormat);
  };

  customModalWrapper = () => {
    const mountNode = document.getElementsByClassName('custom-modal-wrapper');
    if (mountNode) {
      return mountNode[0];
    }
    const node = document.createElement('div');
    node.className = 'custom-modal-wrapper';
    document.body.appendChild(node);
    return node;
  }

  isValidDate = (dateValue) => {
    const dateVal = moment(dateValue);
    return dateVal.isValid();
  }

  downloadStringAsFile = (text, fileType, fileName) => {
    var blob = new Blob([text], { type: fileType });
  
    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);

    // Example: downloadStringAsFile("a,b,c\n1,2,3", "text/csv", "myCSV.csv")
  }
  injectFreshChat = (role) => {
    console.log('User Role = ', role.toLowerCase());
    if(role !== 'ADMIN') {
      window.fcWidgetMessengerConfig = {
        siteId: DEPLOY_ENV,
        faqTags : {
          tags : [role.toLowerCase()],
          filterType: "article"
        },
        config: {
          content: {
            headers: {
              faq: "Knowledge Base",
            },
          },
        },
      }
    }
      
    const script = document.createElement("script");
    script.src = '//fw-cdn.com/1737158/2603647.js';
    script.setAttribute('chat', 'true');
    script.async = true;
    document.body.appendChild(script);
  }
}

export default new Utility();
