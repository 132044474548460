import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { InlineLoader } from '../../components/shared';

class Claim extends React.Component {
  constructor(props) {
    super(props);
    const parsedHash = queryString.parse(this.props.location.search);
    if (parsedHash.o && parsedHash.t) {
      this.props.ordersStore.claim(parsedHash).then(() => {
        this.props.history.push('/auth/login');
      });
    } else {
      this.props.history.push('/auth/login');
    }
  }

  render() {
    return (
      <InlineLoader />
    );
  }
}

// https://dev.ordrs.io/claim?o=cjted4chrzknp0714qxxcj7e6&t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTI5NzEwNTYsImRhdGEiOnsiaWQiOiJjanJ1cWpxZ2JhYXd6MDcxNGRiajZ5ZzVoIiwicm9sZSI6IlBVQkxJQyIsInVzZXJUeXBlIjpudWxsfSwiaWF0IjoxNTUyOTI3ODU2fQ.yRcmnX_kV67_uxcK_uApL5PoTf7cWLStAsH6u-E8ADY

// claim?o=cjted4chrzknp0714qxxcj7e6&t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NTI5NzEwNTYsImRhdGEiOnsiaWQiOiJjanJ1cWpxZ2JhYXd6MDcxNGRiajZ5ZzVoIiwicm9sZSI6IlBVQkxJQyIsInVzZXJUeXBlIjpudWxsfSwiaWF0IjoxNTUyOTI3ODU2fQ.yRcmnX_kV67_uxcK_uApL5PoTf7cWLStAsH6u-E8ADY

export default inject('ordersStore')(withRouter(observer(Claim)));
