import gql from 'graphql-tag';

export const uniqueMarkers = gql`
  query _uniquePanelMarkers{
    uniquePanelMarkers
  }
`;

export const downloadOrderMarkers = gql`
  query downloadOrderMarkers($markerName: String) {
    downloadOrderMarkers(markerName: $markerName) {
      panelPrefix
      orderOrderId
      id
      reading
      createdAt
    }
  }
`;

export const uploadOrderMarkers  = gql`
  mutation uploadOrderMarkers($force: Boolean, $markers: [UploadMarkerInputType]!) {
    uploadOrderMarkers(force: $force, markers: $markers) {
      summary {
        GOOD
        SKIP
        CONFLICTS
        ERROR
      }
      markers {
        name
        orderOrderId
        oldReading
        newReading
        status
      }
    }
  }
`;

export const createLabMarker = gql`
  mutation createLabMarker($labId: ID!, $name: String!, $markerRef: String, $markerDesc: String, $resultCode: String, $specimen: SpecimanEnum!, $units: String!, $ranges: [LabMarkerRangeInputType]) {
    createLabMarker(labId: $labId, name: $name, markerRef: $markerRef, markerDesc: $markerDesc, resultCode: $resultCode, specimen: $specimen, units: $units, ranges: $ranges) {
			id
		}
  }
`;

export const updateLabMarker = gql`
  mutation updateLabMarker($id: ID!, $labId: ID!, $name: String!, $markerRef: String, $markerDesc: String, $resultCode: String, $specimen: SpecimanEnum!, $units: String!, $ranges: [LabMarkerRangeInputType]) {
    updateLabMarker(id: $id, labId: $labId, name: $name, markerRef: $markerRef, markerDesc: $markerDesc, resultCode: $resultCode, specimen: $specimen, units: $units, ranges: $ranges) {
			id,
			markerRef
		}
  }
`;

export const createLabResult = gql`
  mutation createLabMarker($labId: ID!, $name: String!, $reflex: Boolean, $markerRef: String, $markerDesc: String, $resultCode: String, $specimen: SpecimanEnum!, $units: String!, $ranges: [LabMarkerRangeInputType]) {
    createLabMarker(labId: $labId, name: $name, reflex: $reflex, markerRef: $markerRef, markerDesc: $markerDesc, resultCode: $resultCode, specimen: $specimen, units: $units, ranges: $ranges) {
			id
		}
  }
`;

export const updateLabResult = gql`
  mutation updateLabMarker($id: ID!, $labId: ID!, $name: String!, $reflex: Boolean, $markerRef: String, $markerDesc: String, $resultCode: String, $specimen: SpecimanEnum!, $units: String!, $ranges: [LabMarkerRangeInputType]) {
    updateLabMarker(id: $id, labId: $labId, name: $name, reflex: $reflex, markerRef: $markerRef, markerDesc: $markerDesc, resultCode: $resultCode, specimen: $specimen, units: $units, ranges: $ranges) {
			id,
			markerRef
		}
  }
`;

export const uploadBulkResults = gql`
  mutation uploadBulkResults($labId: ID!, $results: [LabMarkerInputType]!) {
    uploadBulkResults(labId: $labId, results: $results) {
			error
      success
      message
		}
  }
`;

export const downloadLabTestResults = gql`
  query downloadLabTestResults($ids: [ID]) {
    downloadLabTestResults(ids: $ids) {
			id name markerRef markerDesc resultCode specimen units reflex ranges  { id name type value rangeType }
		}
  }
`;

export const deleteManyLabMarkers = gql`
  mutation deleteManyLabMarkers($ids: [ID]) {
    deleteManyLabMarkers(ids: $ids) {
			id
		}
  }
`;
